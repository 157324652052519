import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const PDF = ({ file }) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        className="d-flex align-items-center justify-content-center flex-column"
      >
        {[...Array(numPages)].map((_, i) => (
          <div
            key={i}
            className="mb-5"
            style={{ maxWidth: 595, maxHeight: 842 }}
          >
            <Page pageNumber={i + 1} />
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDF;
