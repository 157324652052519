import { useEffect } from "react"
import { getRedirect, removeValue } from "../../services/storage";
import { useLocation } from "react-router-dom";

const Logout = () => {
  const location = useLocation();
  const search = location.search;
  const query = new URLSearchParams(search);
  let redirect_to = query.get('r');

  function clearTokens() {
    removeValue('token')
    removeValue('web3authToken')
    if (!redirect_to) {
      redirect_to = getRedirect();
    }
    window.location.href = redirect_to;
  }

  useEffect(() => {
    clearTokens();
  }, [])


}

export default Logout