import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  top_news:{}
}

const homeSlice = createSlice({
  name:'top_news',
  initialState:initialState,
  reducers:{
    getTopNews(state, action){
      state.top_news = action.payload
    }
  }
})


  
export const { getTopNews } = homeSlice.actions;

export default homeSlice