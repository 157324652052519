// eslint-disable-next-line import/no-anonymous-default-export
export default {
    registration: [{
        title: 'Sign Up',
    },
    {
        title: 'profile name'
    },
    {
        title: 'profile icon'
    },
    ],
    walletsend: {
        headerTitle: 'My Wallet',
        mainTitle: 'Choose a token to send'
    },
    walletArcana: {
        headerTitle: 'My Wallet',
        mainTitle: 'My ARCANA',
        myPersona: 'My Persona'
    },
    walletSendAddress: {
        headerTitleAnima: 'My Wallet',
        mainTitleAnima: 'Send ANIMA',
        textBoxLabelAnima: 'ANIMA Amount',
        headerTitleArcana: 'send',
        mainTitleArcana: 'Send Token',
        textBoxLabelArcana: 'ARCANA Amount',
        headerTitlePersona: 'send Persona',
        mainTitlePersona: 'Send Token to',
        textBoxLabelPersona: 'PERSONA Amount',
        labelQR: 'read QR code',
        labelAddress: 'Address',
    },
    walletsendconfirmation: {
        mainTitleAnima: 'Send ANIMA',
        mainTitleArcana: 'Send ARCANA',
        mainTitlePersona: 'Send PERSONA',
        mainTitleToken: 'Send 〇〇',
        labelBlockchain: 'Blockchain',
        labelAnimaAmount: 'Amount',
        labelTokenNameSymbol: 'Symbol / Token name',
        labelTo: 'To',
        labelArcanaQuantity: 'Quantity',
        notifyParagraph: 'Are you sure you want to make a transaction with the details below?',
    },
    walletSupport: {
        headerTitle: 'support',
        notifyParagraph: 'If you have any inquiries please feel free to contact us.',
    },
    walletAbsorb: {
        headerTitle: 'My Wallet',
        mainTitle: 'Absorb',
        absorber: 'Absorber',
        descAbsorber: 'Select PERSONA to be the Absorber',
        target: 'target',
        descTarget: 'Select target to be absorbed',
        FOR: 'FOR',
        ABS: 'ABS',
        DFT: 'DFT',
        MND: 'MND',
        INT: 'INT',
        EXP: 'EXP',
        absorbSuccessMsg: 'The absorption has been successfully completed!',
        absorbFailMsg: 'The absorption has been failed!',
    },
    walletAbsorbList: {
        headerTitle: 'My Wallet',
        mainAbsorberTitle: 'Choose PERSONA',
        mainTargetTitle: 'Choose ARCANA',
    },
    walletReceive: {
        title: 'Receive',
        headerTitle: 'My Wallet',
        labelMyAddress: 'My Address',
        labelQrCode: 'My wallet’s QR code',
        notifyParagraph: 'When receiving tokens, you can either use your QR code shown left, or copy your wallet address above.'
    },
    connectWallet: {
        headerTitle: 'connect wallet',
        paragraph: 'For using and get access to  various services of this portal you have to connect your ANICANA Wallet.',
        question: 'Do not have ANICANA Wallet yet?',
        labelGet: 'Get One!',
    },
    wallet: {
        headerTitle: 'My Wallet',
        iconDashboard: 'Dashboard',
        iconSend: 'Send',
        iconReceive: 'Receive',
        iconTXHistory: 'TX History',
        iconSettings: 'Wallet Settings',
        iconManaAddress: 'Mana Address',
        iconSupport: 'Support',
        titleAddress: 'Address'
    },
    walletTransaction: {
        labelTxHash: 'Tx Hash',
        labelBlockChain: 'Blockchain',
        labelSymbol: 'Symbol',
        labelTokenName: 'Token Name',
        labelQuantity: 'Quantity',
        labelTimestamp: 'Timestamp',
        labelTo: 'To',
        labelFrom: 'From',
        labelSlash: '/',
        labelItem: 'Item',
        labelDate: 'Date',
        labelAmount: 'Amount',
        labelTokenID: 'Token ID',
        headerTitle: 'TX History',
    },
    walletDrawHistory: {
        title: 'Draw History',
        labelDate: 'Date',
        labelStatus: 'Status',
        labelSuccess: 'Success',
        labelPending: 'Pending',
        lableDrawChainName: 'Draw Chain Name',
        lableUsedPersona: 'Used Persona',
    },
    walletManaAddress: {
        title: 'MANA Address',
        labelTokenID: 'Token ID',
        labelAction: 'Action',
        labelDate: 'Date',
        labelStatus: 'Status',
        labelCompleted: 'Completed',
        labelIncomplete: 'Incomplete',
        labelFailed: 'Failed',
        labelRestore: 'Restore'
    },
    walletTableLabel: {
        labelAction: 'Action',
        labelTo: 'To',
        labelFrom: 'From',
        labelSlash: '/',
        labelItem: 'Item',
        labelDate: 'Date',
        labelAmount: 'Amount',
        labelTokenID: 'Token ID',
        labelTxID: 'TX ID',
    },
    acountDetail: {
        headerTitle: 'Account',
        noName: 'no name',
        labelEmailAddress: 'Email Address',
        labelMobileNumber: 'Mobile number',
        labelNoMobile: 'no mobile',
        labelSecurityCode: 'Security code',
        labelTwofactors: '2FA',
        labelPassword: 'Password',
        labelDeletAccount: 'Delete Account',
    },
    passwordResetForm: {
        mainTitle: 'password reset',
        paceholderEmail: 'email address',
        placeholderPhone: 'mobile number',
        notifyParagraph: 'An sms will be sent to your registered mobile number. Please access the "password reset" URL and register a new password.',
    },
    signupForm: {
        mainTitle: 'Create your ANICANA ID',
        notifyParagraph: 'Please agree to the Terms of Use and Privacy Policy before proceeding.',
        checkBoxTermofus: 'Term of Use',
        checkBoxPrivacyPolicy: 'Privacy Policy'
    },
    profileNameForm: {
        mainTitle: 'profile name',
        paceholderProfileName: 'profile name',
        notifyParagraph: 'Set your profile name, which will be shown in your profile. You can edit it in the “Profile” menu later.'
    },
    mobileVerificationForm: {
        mainTitle: 'forgot email',
        placeholderPhone: 'mobile number',
        notifyParagraph: 'Please enter the mobile phone number registered to your account. We will send your registered email address by SMS.',
        sendSmsBtn: 'send SMS',
    },
    privacy: {
        mainTitle: `<div><b>PRIVACY NOTICE (VERSION:2.0)</b></div>`,
        notifyParagraph: `
        <div>
        <div class="text-center"><b>DEAR USERS!</b></div>
        <div class="text-center">THANK YOU FOR LOOKING AT THIS PRIVACY NOTICE! READ ON TO LEARN WHY AND HOW WE PROCESS YOUR PERSONAL DATA.</div><br/>
        <div class="row">
            <div class="row-no">1.</div>
            <div class="row-item"><b class="m-l-lg-15">WHAT IS THIS?</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                This Privacy Notice informs you about how we process your Personal Data when you use the Anicana ΘΥΡΑ (THÝRA) (“Portal”). As we are located in the European Union, this Privacy Notice was prepared in line with the European Union’s General Data Protection Regulation (“GDPR”).</br>
                We tried to cover everything. However, if you have any questions, please let us know by sending us an email to info@anicana.org or using the “Contact Us” function on the Portal.
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">2.</div>
            <div class="row-item"><b class="m-l-lg-15">WHO PROCESSES MY PERSONAL DATA?</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                Personal Data is basically any information relating to you as a private person. Anything we do with this Personal Data is considered data processing, such as collecting it, making it part of our database, or even storing it. Data processing comes with a number of obligations on our side.<br/>
                <b>
                “We” refers to InfiCreo Zrt., the company operating the Portal. We are a company based in the European Union, in the Member State of Hungary.<br/>
                Our address: H-8200 Veszprém, Viola utca 2., Hungary<br/>
                Our registry number: 19-10-500392<br/>
                </b>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">3.</div>
            <div class="row-item"><b class="m-l-lg-15">HOW DO WE PROCESS YOUR PERSONAL DATA?</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                We act in line with the GDPR, one of the strictest privacy laws in the world. The protection of your Personal Data is not just another checkbox to us, we take it seriously. <br/>
                Here’s how:<br/>
                <ul>
                    <li>we process your Personal Data <b>in line with all applicable laws and in a transparent manner</b>. This means that we will always provide you with information on how and why your Personal Data is processed, where you can address your queries and what rights you may have. We will never process your Personal Data for non-disclosed or vague purposes.</li>
                    <li>We always <b>limit the processed Personal Data to what is really needed</b>. We do not collect or otherwise process excessive Personal Data, nor do we hoard it on a “nice to have” basis for the future.</li>
                    <li><b>We do not process your Personal Data indefinitely, only as long as it is necessary for a specific purpose</b>. We will always let you know of the storage periods applicable to your Personal Data. Once these periods are over, we’ll delete your Personal Data.</li>
                    <li>We will do our best to ensure that your Personal Data is always <b>kept accurate and up to date.</b></li>
                    <li>We <b>ensure the security of your Personal Data and minimize the risk of unauthorized access</b>, disclosure or any other event that could compromise it. This includes appropriate information technology, physical and organizational controls.</li>
                    <li><b>You decide what happens to your own Personal Data</b>. Therefore, we are always ready to answer your questions, and take the necessary steps to address your concerns.  </li>
                </ul>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">4.</div>
            <div class="row-item"><b class="m-l-lg-15">WHAT EXACTLY DO WE DO WITH YOUR DATA?</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                We are the operator of the Portal, a web portal for various contents. <br/>
                While we do not process the Personal Data you provide when accessing the Portal (i.e. your registration and login data), we might need to use certain Personal Data to respond to your questions and make sure you can exercise your privacy rights.<br/>
                Please read on for the details of our data processing.<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">4.1. </div>
            <div class="row-sub-item"><b>Answering your questions, exercising your rights</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                We’ll need certain Personal Data to address your queries or concerns, namely, your name, email address and the content of your message.<br/>
                We’ll use this data to<br/>
                <ul>
                    <li>answer your questions or queries, also including cases when you wish to exercise your privacy rights;</li>
                    <li>meet our obligations under the GDPR to allow for the exercising of rights and also our obligations under the applicable consumer protection laws.</li>
                </ul>
                </br>
                We’ll use your Personal Data based on our legal obligations to allow you to exercise your rights and have control over your Personal Data. We are also obligated to respond to and address your queries based on the applicable consumer protection laws. In cases when we have no explicit legal obligation to answer the given question or query, the legal basis for our data processing will be our legitimate interest in responding to you and thereby providing a user-friendly service.<br/>
                If you file a complaint with us, we’ll store your Personal Data included in your complaint and our answer for 3 years to be counted from the filing of your complaint. Questions or queries that cannot be considered a complaint and our answers thereto will be deleted once we have answered the given question or query, unless we have a justified reason to store such correspondence for the purpose specified in Section 4.2 (Legal claims) below. <br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">4.2. </div>
            <div class="row-sub-item"><b>Legal claims</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                We really hope we’ll never have legal debates between us. Nevertheless, in such cases, we might need to process certain Personal Data in order to prove our point, defend our position. This can include, among others, the content of communication between you and us in the event you have a question or query or the proof that we responded to your complaint and any other relevant information.<br/>
                We’ll use such Personal Data based on our legitimate interest in defending our legal position and exercising our legal claims in the event of a legal debate. <br/>
                For this purpose, we’ll store your Personal Data during the statutory limitation period starting from the date of the last relevant communication between us. <br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">5.</div>
            <div class="row-item"><b class="m-l-lg-15">WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                We store and process all your Personal Data on properly protected servers. All servers we use are located in the territory of the European Economic Area  or Japan, a country recognized by the laws of the European Union as providing an adequate level of protection to Personal Data. You can read more about how Japan ensures the protection of Personal Data here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/MEMO_19_422">https://ec.europa.eu</a> <br/>
                We will not transfer your Personal Data to any countries or recipients that do not provide an adequate level of protection to it. If such a transfer becomes absolutely necessary, we’ll apply guarantee mechanisms to protect the Personal Data, such as contractual clauses specifically designed for this purpose. If you request, we’ll let you know in more detail of these guarantees.<br/>    
                We treat your Personal Data confidentially and do not disclose it to third parties except as listed here.<br/>
                <ul>
                    <li>Amazon Web Services, the world’s most broadly accepted cloud platform (Web Services EMEA SARL, registered seat: 38 Avenue John F. Kennedy, L-1855, Luxembourg), providing cloud storage services to us;</li>
                    <li>Google, our email service provider (Google Ireland Limited, registered seat: Gordon House, Barrow Street, Dublin 4 Ireland);</li>
                    <li>in case of a legal debate or an official procedure, we might need to provide certain Personal Data to our legal advisors who are bound by confidentiality requirements, or to acting state authorities, such as the police or courts.</li>
                </ul> 
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no">6.</div>
            <div class="row-item"><b class="m-l-lg-15">WHAT RIGHTS DO YOU HAVE?</b></div><br/>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                Firstly, you can exercise any of your rights detailed here at any time, by contacting us. (See Section 1).<br/>
                When contacting us, please describe your question or request in your own words. We don’t expect you to phrase it like a lawyer, just let us know what your concern is about our Personal Data processing and what you would like us to do. <br/>
                Exercising your rights is free of charge. (We may charge a reasonable fee only if your request is manifestly unfounded or excessive, but, if this is the case, you will always be informed in advance of the amount of such fee.) We’ll respond to all queries as soon as we’re able but definitely within one month at the latest.<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.1. </div>
            <div class="row-sub-item"><b>Right of access</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                This means that you can ask us any time whether we process Personal Data about you and if yes, what Personal Data, why, for how long, who do we share it with and what rights you can exercise.<br/>
                You can also ask us to provide you with a copy of your Personal Data we have. The only case we can deny this is if that would harm someone else’s rights.<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.2. </div>
            <div class="row-sub-item"><b>Right to rectification</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                If you believe that the Personal Data we have about you is no longer correct you can ask us at any time to update it. <br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.3. </div>
            <div class="row-sub-item"><b>Right to erasure (“right to be forgotten”)</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                You have the right to be forgotten. It basically means that we must delete any Personal Data we have about you if <br/>
                <ul>
                    <li>we no longer need it;</li>
                    <li>you withdrew the consent you gave earlier to the data processing (if such consent was previously given);</li>
                    <li>you object to our processing of your Personal Data and we cannot prove that we have an interest overriding your objection or</li>
                    <li>the law requires us to do so.</li>
                </ul>  
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.4. </div>
            <div class="row-sub-item"><b>Right to restriction of Personal Data processing</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
            You can ask us to restrict the processing of Personal Data. This means that apart from storing it, we’ll not do anything else with it. This can be useful in situations when you don’t want us to delete your Personal Data but want us to stop actually using it for a time being.<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.5. </div>
            <div class="row-sub-item"><b>Right to object</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                You have the right to object against our processing of your Personal Data. In such cases, we have to prove that we have an overriding interest in processing it (such as the defence of our legal rights and position or fulfilment of our legal obligations). If we cannot prove this, we have to delete it.<br/>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no">6.6. </div>
            <div class="row-sub-item"><b>Right to lodge a complaint, right to go to court</b></br></div>
        </div>
        <div class="row">
            <div class="row-no"></div>
            <div class="row-sub-no"></div>
            <div class="row-sub-item">
                We’ll do our best not to give you a reason for any complaints.<br/><br/>
                Nevertheless, if you think that your Personal Data is processed unlawfully, you may file a complaint with the competent authority. We are company registered in Hungary, so in the present case, this authority is the Hungarian National Authority for Data Protection and Freedom of Information.<br/><br/>
                Website: <a href="https://www.naih.hu/about-the-authority">https://www.naih.hu/about-the-authority</a><br/>
                Address: H-1055 Budapest, Falk Miksa utca 9-11.<br/><br/>
                Postal address: 1374 Budapest, Pf.:603.<br/>
                Telephone: +36-1-391-1400<br/>
                Fax: +36-1-391-1410<br/>
                Email: ugyfelszolgalat@naih.hu<br/><br/>
                You also have the right to judicial remedy. More detailed information on Hungarian courts is available here: <a href="https://birosag.hu/en">https://birosag.hu/en</a><br/> <br/>
                <br/>
            </div>
        </div>
        <div class="text-center">* * *</div>
        Effective date: 24 January 2023<br/>
        </div>`,
        langClass: 'en_privacy',
    },
    termOfUse: {
        mainTitle: `<div><b>Terms of Use of Anicana θυρα</b></div>`,
        notifyParagraph: `
        <div>
        <b>Version: 2.0</b><br/>
        <b>Date: 24 January 2023</b><br/>
        <br/>
        PLEASE READ THESE TERMS OF USE OF THE PORTAL OF ANICANA ΘΥΡΑ (THÝRA) (HEREINAFTER REFERRED TO AS THE “<b>TERMS</b>”) CAREFULLY.<br/>
        THESE TERMS INCLUDE (1) YOUR AGREEMENT THAT WE MAKE NO REPRESENTATION OR WARRANTY REGARDING THE ANICANA ΘΥΡΑ AND THE SERVICES (AS DEFINED HEREUNDER) AND THAT THE ANICANA ΘΥΡΑ AND THE SERVICES ARE PROVIDED “AS IS” AND WITHOUT WARRANTY (ARTICLE X); (2) THE LIMITATIONS ON OUR LIABILITY AND YOUR CONSENT TO RELEASE US FROM LIABILITY BASED ON CLAIMS BETWEEN USERS AND THIRD PARTIES (ARTICLE X).<br/>
        <br/>
        The Terms state the terms and conditions of the Services of the “Anicana ΘΥΡΑ” website (as defined hereunder) operated by <b>InfiCreo Zrt</b>. (a Hungarian company limited by shares; registered address at 8200 Veszprém, Viola utca 2., Hungary; company registration no: 19-10-500392; registered by the Company Registry Court of Veszprém Regional Court, Hungary; hereinafter referred to as the “Company”). Those who use the Service as a User (as defined hereunder) are deemed to have confirmed and agreed to the Terms, so please be sure to check the followings.<br/>
        <br/>
        <b>ARTICLE I. （Application）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>The purpose of the Terms is to stipulate the conditions for the provision of the Services (as defined in ARTICLE II.), the rights, and obligations and all relationships between the Company and the User (as defined in ARTICLE II.) regarding the use of the Services.</li>
          <li>The rules (hereinafter referred to as "Individual Rules") regarding the use of the Services posted by the Company shall also apply to the use of the Services as an integral part of the Terms and if the content differs between the Individual Rules and the Terms, the Terms shall be applied with priority.</li>
          <li>In case of changes to the Terms, the changes shall be applied after sending a notification the content of the changed Terms to the users. If the user continues using the Service after changing the Terms, it is deemed that the user has explicitly agreed to those changes.</li>
        </ol>
        <br/>
        <b>ARTICLE II. (Terminologies)</b><br/>
        The following terminologies used in the Terms shall have the respective meanings set forth below.<br/>
        <ol style="margin-left: 1.5rem">
          <li>“Terms of Use of Anicana ΘΥΡΑ” refers to the Terms and the contract for use of the Services concluded between the Company and the User.</li>
          <li>“Website” refers to the website of the "Anicana ΘΥΡΑ" operated by the Company to provide the Services.</li>
          <li>“Service” refers to the distribution of various contents via the Internet and other networks provided by the Company and companies affiliated or entrusted by the Company (hereinafter referred to as “Affiliates”), applications related to Anicana Wallet, and all other services provided on the Webste.</li>
          <li>"User Application" means that a person who wishes to use this Service agrees to the Terms and applies for the provision of the Service by the authentication information provided by a third prty,</li>
          <li>“User” means any person who uses the Service provided by the Company.</li>
          <li>“User Information” means all information registered by users via third-party services that the Company refers to. However, User Information is not acquired or managed by the Company.</li>
          <li>“Personal Information” refers to any information that can identify a specific individual, such as an e-mail address, mobile number, etc. among user information.</li>
          <li>“Authentication Information” means the information, that is necessary for the Company to authenticate the access from the User including LEVIAS ID and passwords.</li>
          <li>"LEVIAS ID" is an ID owned, operated, and managed by LEVIAS INC. In this Service, only the ID number and a password are referred to identify users.</li>
          <li>“Anicana Wallet” means a Web3.0 e-wallet provided to a User by a third-party service provider for storing tokens including NFTs on the blockchain called “ANICANA Chain”. Tokens can be sent and received ad libitum and are necessary for using the Service.</li>
        </ol>
        <br/>
        <b>ARTICLE III. (Service)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>The name of this service is: ANICANA ΘΥΡΑ (THÝRA) .</li>
          <li>The Service refers to a platform for distributing various contents operated by the Company or its Affiliates, providing a user interface for applications running on ANICANA Chain, and distributing other media contents such as news, etc.</li>
          <li>The Company may, as necessary, add new services to the Service or change the content of the Service without obtaining the User's prior consent. User shall preliminarily acknowledge this.</li>
          <li>Items, rights to play, electronic money, digital assets representing value or right, etc. can be used and are provided via the contents by third-party operating companies, and these services and contents are not included in the Service provided by the Company.</li>
          <li>Items, rights to play, in-game purchases of electronic money and other payments, etc. that can be used in contents, or games operated by third-party companies are subject to the terms of use established by those operating companies. The Company does not provide any financial or non-financial services that involve the custody, exchange, transfer, trading or administration of digital assets representing value or right, or services which enable these activities and does not provide any advice on such assets or activities.</li>
          <li>Information related to tokens such as NFTs, including User's Anicana Wallet information, is displayed within the Service and refers to information provided by blockchain wallets or third parties. The Company does not own or control such information.</li>
        </ol>
        <br/>
        <b>ARTICLE IV. (Application for Use)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>Users (hereinafter referred to as “Applicant”) who wish to use the Service agree to comply with the Terms and shall apply for use of the Service by referring to the Authentication Information provided by a third party.</li>
          <li>User Registration shall be made by Users who use the Service themselves. User Registration for the Service shall be performed by a Third Party and referring the authentication information by the Company is considered an application for use of the Service. If the User is successfully authenticated, the Service can be used.</li>
          <li>Regarding the Application for Use of the Service stipulated in the preceding paragraph, the Company only refers to information provided by a Third Party and will not receive nor has access to any information that corresponds to the User's Personal Information.</li>
          <li>Applicants shall register true, accurate and up-to-date information in the Third Party’s User Information to use the Service.</li>
          <li>At its sole discretion, the Company may not approve the Application of Use if the person who has applied for the Use of the Service under Paragraph 1. falls under any of the following items apply.
            <ol class="round-bracket" style="margin-left: 1.5rem">
              <li>When the Company determines that there is a risk of violating the Terms;</li>
              <li>If there is a falsehood, error or omission in all or part of the User Information referenced to the Company;</li>
              <li>In the case of a User whose Use of the Service was cancelled in the past;</li>
              <li>If the User is a minor, an adult ward, a person under curatorship, or a person under assistance, and has not obtained the consent of a legal representative, guardian, curator, or assistant;</li>
              <li>Being an anti-social force, etc. (meaning organized crime groups, Users of an organized crime group, right-wing organizations, or equivalent thereto), or cooperating in the maintenance, operation, or management of an anti-social force, etc. through funding or other means or if the Company determines that there is interaction or involvement with anti-social forces, etc.;</li>
              <li>If it is determined that the Application for Use is from an area where access is restricted due to national laws, ordinances, notifications, etc.</li>
              <li>when the Company determines that the Application for Use is not appropriate.</li>
            </ol>
          </li>
          <li>The Company will determine whether the Application for Use can be approved in accordance with the preceding paragraph and other standards of the Company. When the Application for Use is completed, a contract (hereinafter referred to as "Usage Contract") concerning the use of the Service in accordance with the provisions of the Terms has been established between the User and the Company.</li>
          <li>If the Application for Use is not accepted in the judgment of the preceding paragraph, the Company shall not be obligated to clarify the reason.</li>
          <li>Users are deemed to have agreed to receive the Service when the Company accepts their Application for Use.</li>
          <li>The User may not transfer, lend, change the name of, provide collateral, or otherwise make available the right to receive the provision of the Service to a third party.</li>
          <li>The Service includes services that require the setting up of registration of unique information operated by third parties. When a User uses these individual services, the necessary information shall be accurately registered and used in accordance with the guidelines specified by each service or third party.</li>
          <li>Users shall manage Authentication Information at their own responsibility and shall be responsible for the expense, and damages due to leakage of information due to insufficient management of Authentication Information, errors in use, use by third parties, unauthorized access, losses, etc. The Company shall not take any responsibility for those.</li>
        </ol>
        <br/>
        <b>ARTICLE V. (Change User Information and Manage Authentication Information)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>Users shall manage their Authentication Information at their own responsibility and expense and shall not allow a Third Party to use, lend, transfer, sell, pledge, or disclose, etc. their Authentication Information.</li>
          <li>Users shall be responsible for damages caused by leakage of information due to insufficient management of Authentication Information, errors in use, use by third parties, unauthorized access, etc., and the Company shall not be held responsible. In the unlikely event that the Company suffers damage due to unauthorized use of the Authentication Information, the User shall compensate for those damages.</li>
          <li>In the event that a User Information is changed, or if Authentication Information is known to a Third Party, or if there is a suspicion that the Authentication Information is being used by a Third Party, the User shall immediately notify the Third Party that manages User Information to that effect in accordance with the Terms and the method specified by the Third Party and shall follow the instructions from the Third Party.</li>
          <li>The Company shall not be held responsible for any disadvantages such as being unable to use the Services due to the failure of the User to make the notification set forth in the preceding paragraph.</li>
        </ol>
        <br/>
        <b>ARTICLE VI. (LEVIAS ID)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>LEVIAS ID registration operated and managed by LEVIAS Inc. is required to use the Service.</li>
          <li>The Company only refers to the LEVIAS ID, and the Company does not hold, maintain, or manage user-specific information related to the LEVIAS ID, and is not responsible for it.</li>
        </ol>
        <br/>
        <b>ARTICLE VII. (User Information/Personal Information)</b><br/>
        <span style="margin-left: 1.5rem">See the Company’s Privacy Policy.</span><br/>
        <br/>
        <b>ARTICLE VIII.（Termination of Usage Contract）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>If the User falls under any of the following items, or if the Company determines that there is a risk of those, the Company may immediately stop the usage of the Service against that User at its discretion without prior notice or demand to the User and without obtaining the User's consent.
            <ol class="round-bracket" style="margin-left: 1.5rem">
              <li>In case of violation of the Terms;</li>
              <li>User Information is false or incorrect;</li>
              <li>When using or attempting to use the Service for purposes or methods that may cause damage to the Company, Affiliates, other Users, game companies or other third parties;</li>
              <li>When the operation of the Service is obstructed by any means;</li>
              <li>If a User impersonates a Third Party and registers as a User;</li>
              <li>If there is a duplicate Application for Use;</li>
              <li>In the case of performing the prohibited acts stipulated in Article XI.</li>
              <li>When the User dies.</li>
              <li>If the Service has not been used for 6 months or more and there is no response to contact from the Company;</li>
              <li>Cases falling under each item of Article IV. Paragraph 4.</li>
              <li>If the Company deems it inappropriate as a User.</li>
            </ol>
          </li>
          <li>In the case of the preceding paragraph, if the Company suffers any damage, the User shall compensate the Company.</li>
          <li>The Company shall not be obligated to disclose the reason for stopping the usage of the Service against that User as set forth in Paragraph 1 to the User.</li>
          <li>Upon stopping the usage of the Service as stipulated in this article, the Usage Contract concluded between the User and the Company shall be terminated with immediate effect, and the User shall lose all rights to use the Service. </li>
        </ol>
        <br/>
        <b>ARTICLE IX. (Disclaimer)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>The Company does not guarantee the accuracy, usefulness, legality, non-existence of defects, security, suitability for a particular purpose, non-existence of infringement of rights, or any other matter with respect to the Service.</li>
          <li>The Company is not responsible for any suspension, termination, unavailability or change of provision of the Service by the Company, deletion or loss of information, cancellation of User registration, loss of data due to use of the Service, failure or damage of equipment, other damages suffered by the User in connection with the Service.</li>
          <li>If a User causes damage to a Third Party by using this Service, the User shall compensate for this at the User’s own expense and responsibility.</li>
          <li>Where the Company is not a party to an agreement between a User and a Third Party or to any other dealings between a User and a Third Party, the User agrees that the Company has no control over such agreement or other dealings and therefore the User releases the Company from any claim, demand, damages and liabilities arising out of or in connection with a dispute with a Third Party.</li>
          <li>Transactions, communications, disputes, etc. that arise between Users, Affiliates, distribution content companies and other third parties in connection with the Service shall be handled and resolved at the Users' responsibility. The Company shall not take any responsibility for such matters.</li>
          <li>The Company shall not be responsible for any damages caused by the inability to use the Service due to defects, errors, or failures due to excessive access to the Service.</li>
          <li>The Company shall not be obligated to compensate for damages to computers, networks, software, etc. caused by downloads from third-party websites including advertisers and distribution content companies, and computer virus infections.</li>
          <li>The Service and the contents and information on the Anicana ΘΥΡΑ are available at the time.<br/>The Anicana ΘΥΡΑ and the Services are provided “as is” and the Company shall not make any kind of warranty regarding the Anicana ΘΥΡΑ, the Services and especially regarding the completeness, accuracy, applicability, usefulness, usability, safety, certainty, etc. of information provided, texts and other contents registered, written and transmitted by Users.</li>
          <li>The Company may provide Users with information and advice as appropriate but shall not be held responsible for the results.</li>
          <li>The Company shall not be held responsible for any data corruption, etc. caused by the Service due to some external factor.</li>
          <li>The Company shall not be liable for any transactions (exchange of items in content, etc.) between Users and third parties, including content providers, conducted through the Service. All transactions are the responsibility of third parties and the Users.</li>
          <li>The Company’s Terms fall under the Consumer Contract category under Article 2, Paragraph 3 of the Consumer Contract Act. If the provisions that completely exempt the Company from liability for damages in the Terms do not apply, the Company shall be responsible only for direct damages caused to the User due to the Company's default or illegal act. However, it is necessary that there is intentional or gross negligence on the Company’s part.</li>
          <li>Where the Anicana ΘΥΡΑ contains links to other websites and resources provided by third parties, these links are provided for information purposes only. Such websites and resources may be subject to different terms and conditions and privacy policies. The Company has no control over the content of those websites or resources and the Company is not responsible or liable for them in anyway.</li>
        </ol>
        <br/>
        <b>ARTICLE X. (Prohibited Matters)</b><br/>
        <ol>
          Users shall not engage in any of the following acts when using the Service. If a User commits any of the following acts, measures such as suspension of the use of the Service may be taken immediately.
          <ol class="round-bracket" style="margin-left: 1.5rem">
            <li>Acts that infringe or may violate intellectual property rights such as copyrights and trademark rights of the Company, other Users, Affiliates or other third parties (hereinafter referred to as "Others");</li>
            <li>Acts related to criminal acts or act contrary to public order and morals;</li>
            <li>Acts that infringe or may violate the property, privacy, portrait rights, or other rights of Others;</li>
            <li>Provision of personal information of specific individuals;</li>
            <li>Acts that discriminate against or slander others or damage the honors or credibility of Others; </li>
            <li>Acts that are judged to be aimed at excessively or inappropriately directing users to specific external websites;</li>
            <li>Acts that are judged to have an adverse effect on children and minors;</li>
            <li>Acts of falsifying or erasing accessible contents or information of the Service or contents or information of Others;</li>
            <li>Acts of impersonating the Company or Others (including acts of manipulating email headers, etc., in order to impersonate Others);</li>
            <li>Sending harmful computer programs, etc., or making them available for Others to receive;</li>
            <li>Acts of sending advertisements, solicitations, etc., or hateful emails (including emails that may do so) to Others; acts of interfering with Others' receipt of emails; acts of requesting chain mail forwarding or acts of forwarding in response to such request;</li>
            <li>Acts that increase the load on the server beyond the normal use of the Service; or acts that encourage it; other acts that interfere with the operation and provision of the Service or the use of the Service by other Users or interfere with them;</li>
            <li>Acts of distributing information, devices, software, etc. for canceling or circumventing access control functions of servers, etc.;</li>
            <li>Acts of duplicating, modifying, reprinting, altering, changing, reverse engineering, disassembling, decompiling, translating or analyzing functions provided by the Service;</li>
            <li>Acts of acquiring User Information of Others without obtaining the consent of the individual or by fraudulent means (including so-called phishing and similar means);</li>
            <li>Acts of using all or part of the Service for commercial purposes regardless of the method of use (including acts aimed at preparing for such use);</li>
            <li>Acts of using the Service without following the required procedures, such as notification to regulatory authorities, acquisition of licenses, etc., based on laws and regulations; or other acts that violate or may violate applicable laws and regulations;</li>
            <li>Acts that interfere with the operation of the Service; acts that interfere with the exchange or sharing of information led by Others, and acts that cause damage to the Company or Others, such as damage to credibility or infringement of property rights;</li>
            <li>Sending e-mails, repeating similar inquiries excessively, or forcing the Company to do something without obligation or reason, causing significant hindrance to the Company's business;</li>
            <li>In addition to the above items, acts that violate laws and regulations or the Terms; acts that violate public order and morals (acts that encourage or induce violence, sending or displaying cruel images, or recruiting Others for suicide act, etc.)</li>
            <li>Acts of linking to data, etc. in which acts corresponding to any of the above items are observed for the purpose of promoting such acts (including cases where such acts are performed by Others);</li>
            <li>Other acts that the Company deems inappropriate as a User.</li>
          </ol>
        </ol>
        <br/>
        <b>ARTICLE XI. (Attribution of Rights)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>Intellectual property rights such as copyrights and other rights related to the Service belong to the Company or its licensors.</li>
          <li>Intellectual property rights such as copyrights and other rights related to various contents distributed in the Service belong to the business companies that distribute various contents or their licensors.</li>3.	By making available any User Information other than Personal Information on or through the Anicana ΘΥΡΑ, the User grants the Company a limited, non-exclusive, royalty-free, worldwide, irrevocable, sub-licensable and transferable license to use such User Information in the Company's provision, development and enhancement of the Anicana ΘΥΡΑ and the Services. 
          <li>The User shall guarantee and represent to the Company that the User has the legal rights to post or otherwise transmit the posted User Information or other data, and that the posted User Information or other data does not infringe the rights of any Third Party. </li>
          <li>User agrees not to exercise the author's moral rights against the Company and those who have inherited or have been granted rights from the Company.</li>
          <li>The license to use the Service based on the Usage Contract does not mean the license to use intellectual property rights such as copyrights or other rights related to the Service of the Company or its licensors.</li>
        </ol>
        <br/>
        <b>ARTICLE XII. (Suspension, Termination of Service)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>The Company may suspend or terminate all or part of the Service without prior notice to the User if any of the following apply.
          <ol class="round-bracket" style="margin-left: 1.5rem">
            <li>When conducting regular or emergency inspections or maintenance of computer systems related to the Service;</li>
            <li>When the operation of the Service becomes impossible due to force majeure such as fire, power failure, natural disaster, etc.;</li>
            <li>When the operation of the Service becomes impossible due to war, civil war, riot, disturbance, labor dispute, etc.;</li>
            <li>If the Service cannot be provided due to computer system failure, unauthorized access from a Third Party, computer virus infection, etc.;</li>
            <li>When the Service cannot be provided due to measures based on laws, regulations, etc.;</li>
            <li>When the Company determines that suspension or interruption is necessary; </li>
          </ol>
          </li>
          <li>The Company may terminate the provision of the Service at its own convenience. In this case, the Company shall notify the User in advance. However, this does not apply in case of emergency.</li>
          <li>The Company may freely change the content or name of the Service without notifying Users and third parties in advance by e-mail, etc.</li>
          <li>The Company shall not be liable for any damage caused to the User based on the measures taken by the Company under this Article.</li>
        </ol>
        <br/>
        <b>ARTICLE XIII. （Equipment Burden, Usage Environment）</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>The preparation and maintenance of computers, software and other equipment, communication lines and other communication environments necessary for receiving the provision of the Service shall be performed at the User's expense and responsibility.</li>
          <li>Users shall take security measures such as prevention of computer virus infection, unauthorized access and information leakage according to their usage environment of the Service at their own expense and responsibility. </li>
          <li>When starting to use the Service or while using the Service, if the User installs software, etc. on the User's computer or other devices, etc. by downloading from the Anicana ΘΥΡΑ or by other methods, the information held by the User may disappear or be altered. Due care shall be taken to prevent equipment failure, damage, etc., and the Company shall not be liable for any such damages incurred by the User.</li>
        </ol>
        <br/>
        <b>ARTICLE XIV. (Valid Period)</b><br/>
        The User Agreement shall become effective on the date the User begins to use the Service under Article IV. and shall remain effective between the Company and the User until the date on which the User's registration operated by the Third Party is cancelled or the provision of the User Service ends, whichever is earlier.<br/>
        <br/>
        <b>ARTICLE XV. (Revision of Terms)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>The Company reserves the right to revise the Terms at any time.</li>
          <li>If the Company intends to revise the Terms, the Company shall notify the User at any time by using e-mail or the Website related to the Service. </li>
          <li>Based on the preceding paragraph, if the User does not stop using the Service within the period (if there is no specified period, one week from the date of notification) specified by the Company from the date of notification of the revision of the Terms, the User will be deemed to have agreed to the revision of the Terms, and the revised Terms will be exchanged between the User and the Company and shall come into effect.</li>
          <li>The User may not claim ignorance or non-acceptance of the content after the time of entry into force specified in the preceding paragraph.</li>
        </ol>
        <br/>
        <b>ARTICLE XVI. (Governing Law)</b><br/>
        The Terms shall be interpreted in accordance with Japanese law.<br/>
        <br/>
        <b>ARTICLE XVII. (Agreement, Jurisdictional Court)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>If any doubt or problem arises between the User, the Company, and any Third Party in relation to the Company, the Service, the Company shall consult in good faith to resolve the issue.</li>
          <li>If the dispute or problem is not resolved even after the discussion in the preceding paragraph, the Courts of Japan shall be the exclusive agreed jurisdictional court of the first instance.</li>
        </ol>
        <br/>
        <b>ARTICLE XVIII. (Contact)</b><br/>
        If User has any comments, questions, complaints, or other inquiries regarding the handling of user information, or the Service, shall contact the following:　<br/>
        InfiCreo Zrt.<br/>
        address: H-8200 Veszprém, Viola utca 2., Hungary<br/>
        registry number: 19-10-500392<br/>
        email: info@anicana.org<br/>
        <br/>
        <b>ARTICLE XIX. (General)</b><br/>
        <ol style="margin-left: 1.5rem">
          <li>These Terms shall not be regarded as made in writing, and these shall be filed and stored through the Anicana ΘΥΡΑ. The User may access each previous version (if any) of these Terms via the Anicana ΘΥΡΑ.</li>
          <li>These Terms, any Individual Rules or other terms used by the Company in respect of the Services and the Anicana ΘΥΡΑ shall be in the Japanese language.</li>
          <li>The Company explicitly excludes the application of any custom or practice to any contractual arrangement with Users in respect of the Services or the Anicana ΘΥΡΑ. </li>
        </ol>
        <br/>
        <div class="text-center">* * *</div>
        </div>`
    },
    cookiePolicy: {
        mainTitle: `<div><b>Cookies Policy</b></div>`,
        notifyParagraph: `
        <div>
          <p>Last updated: January 24, 2023</p>
          <p>This Cookie Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of Cookies We use, what information We collect using Cookies and how that information is used. </p>
          <p>Cookies do not typically contain any information that personally identifies a user. However, there might be some information associated with cookies (e.g. an IP address) that could qualify as personal information.</p>
          <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</p>
          <br/>
          <h4><strong>Definitions</strong></h4>
          <b>Definitions</b>
          <p>For the purposes of this Cookies Policy:</p>
          <ul>
              <li><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to InfiCreo Zrt., 8200 Veszprém Viola utca 2. Hungary; company registration number: 19-10-500392.</li>
              <li><b>Cookies</b> means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among other functions.</li>
              <li><b>Website</b> refers to ANICANA ΘΥΡΑ, accessible from <a>https://anicana.org/</a></li>
              <li>You means the individual accessing or using the Website, either as a private person or acting on behalf of a company or other legal entity.</li>
              <li><b>You</b> means the individual accessing or using the Website, either as a private person or acting on behalf of a company or other legal entity. </li>
              <li><b>Session Cookie</b>: A session cookie is a simple text file that a website installs on its visitor's device for temporary use. It helps track real-time changes in a user's activity while on a website.</li>
              <li><b>Functionality Cookie</b>: cookies that allow websites to remember the user's site preferences and choices they make on the site including username, region, and language. This allows the website to provide personalized features.</li>
              <li><b>First-party Cookie</b>: cookies that are directly stored by the website (or domain) the user visits</li>
          </ul>
          <br/>

          <h4><strong>The use of Cookies</strong></h4>
          <b>Types of Cookies We Use</b>
          <p>We use both session and functionality Cookies for the purposes set out below:</p>
          <div class="overflow-auto">
            {0}
          </div>
          <br/>
          <h4><strong>The legal basis of our use of Cookies</strong></h4>
          <p>To the extent the use of Cookies entails the processing of any personal information (e.g. an IP address), our legal basis of the data processing is as follows.</p>
          <p>For Cookies that are strictly necessary for the Website to function properly, the legal basis is Our legitimate interest in operating and making the Website available to Our users.</p>
          <p>For Cookies that are not strictly necessary, but optional, the legal basis is your explicit consent given, by choosing to accept such Cookies.</p>
          <br/>
          <h4><strong>Your Choices Regarding Cookies</strong></h4>
          <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this Website. You may use this option for preventing the use of Cookies at any time.</p>
          <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.</p>
          <p>If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.</p>
          <p class="ms-5 text-start">
            For the Chrome web browser, please visit this page from Google: <a>https://support.google.com/accounts/answer/32050</a> <br/> <br/>
            For the Internet Explorer web browser, please visit this page from Microsoft: <a>http://support.microsoft.com/kb/278835</a> <br/> <br/>
            For the Microsoft Edge web browser, please visit this page from Microsoft: 
            <a>https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Select%20Settings%20%3E%20Privacy%2C%20search%2C,and%20then%20select%20Clear%20now.</a> <br/> <br/>
            For the Firefox web browser, please visit this page from Mozilla: <a>https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a> <br/> <br/>
            For the Safari web browser, please visit this page from Apple: <a>https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
          </p>
          <p>For any other web browser, please visit your web browser's official web pages.</p>
          <br/>

          <h4><strong>Contact Us</strong></h4>
          <p>If you have any questions about this Cookies Policy, You can contact us at:</p>
          <ul>
            <li>By email: info@anicana.org</li>
          </ul>
        </div>`,
        policyTablePC: `
        <table class="table table-bordered text-white cookies-table">
            <thead>
                <tr style="background-color: #4f81bd;">
                    <th>Type of cookie</th>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>Expiration Time</th>
                    <th>Provider</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1st party cookie　(functionality cookie)</td>
                    <td>XSRF-TOKEN</td>
                    <td>used to protect against cross-site request forgery (CSRF) attacks and to prevent fraudulent use of user accounts.</td>
                    <td>100 days</td>
                    <td>InfiCreo Zrt.</td>
                </tr>
                <tr>
                    <td>1st party cookie (session cookie)</td>
                    <td>anicana_session</td>
                    <td>used to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website and to authenticate users.</td>
                    <td>100 days</td>
                    <td>InfiCreo Zrt.</td>
                </tr>
            </tbody>
        </table>`,
        policyTableSP: `
        <table class="table table-bordered text-white cookies-table-sp m-b-40">
              <tr>
                <td>Type of cookie</td>
                <td>1st party cookie　(functionality cookie)	</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>XSRF-TOKEN</td>
              </tr>
              <tr>
                <td>Purpose</td>
                <td>used to protect against cross-site request forgery (CSRF) attacks and to prevent fraudulent use of user accounts.</td>
              </tr>
              <tr>
                <td>Expiration Time</td>
                <td>100 days</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>InfiCreo Zrt.</td>
              </tr>
            </table>
            <table class="table table-bordered text-white cookies-table-sp">
              <tr>
                <td>Type of cookie</td>
                <td>1st party cookie (session cookie)	</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>anicana_session</td>
              </tr>
              <tr>
                <td>Purpose</td>
                <td>used to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website and to authenticate users.</td>
              </tr>
              <tr>
                <td>Expiration Time</td>
                <td>100 days</td>
              </tr>
              <tr>
                <td>Provider</td>
                <td>InfiCreo Zrt.</td>
              </tr>
            </table>
        `
    },
    cookiePolicyPopup: {
        title: 'We use cookies',
        body: 'We use certain cookies to function and to improve your personal experience. These cookies are technically necessary. If you do not accept our cookies, you may experience some inconvenience in your use of the website and some features may not function properly.'
    },
    profileIcon: {
        notifyParagraph: 'Set a profile image. If you do not want to set it now, you can do it later in the “Profile” menu. ',
    },
    delectAccount: {
        mainTitle: 'delete account',
        checkboxCondition: 'Check conditions',
        condition1: 'Upon withdrawal from membership, the member shall lose the right to receive information and all rights as a member acquired within the Service. The member shall consent to the fact that due to the service system it may take a period of time specified by the Company until the withdrawal procedure is reflected in the system.',
        condition2: 'If a member starts the withdrawal procedure while ongoing procedures in the Service, the member may not be able to check the result of those. Therefore, the member shall perform the withdrawal after completing every ongoing procedure.',
        condition3: 'The Usage Contract shall be terminated when the user withdraws membership.',
        checkboxPoints: 'Important points',
        point1: 'As soon as you decide to withdraw, your data will be erased permanently. Once your data is erased, it cannot be recovered under any circumstances.',
        point2: 'You cannot cancel your withdrawal.',
        point3: 'By withdrawing membership, you will not be able to refer to ANICANA Wallet via the ANICANA Portal.',
        labelReason: 'Reason for withdrawal'
    },
    walletSetting: {
        mainTitle: 'Wallet Setting',
        labelStatus: 'Status',
        connectStatus: 'Connected',
        unconnectStatus: 'Not connected',
    },
    setting: {
        mainTitle: 'setting',
        labelVersion: 'Version',
        labelLanguage: 'Language',
        labelTimeZone: 'Time Zone',
        messageNotify: 'Are you sure you want to change settings?',
    },
    changeMobileForm: {
        headerTitle: 'profile',
        mainTitle: 'Change Mobile Number',
        labelTitle: 'Register your new phone number.',
        labelNewMobileNumber: 'new mobile number',
        labelConfirmMobileNumber: 'confirm mobile number',
    },
    mobileVerifyForm: {
        headerTitle: 'profile',
        mainTitle: 'Change Mobile Number',
        labelTitle: 'Verification Code',
        lableNotify: 'Enter the verification code sent to your new mobile number. ',
        labelNotify2: 'Have not received any verification SMS?',
    },
    contactUs: {
        headerTitle: 'contact',
        title: 'Contact Us',
        lableNotify: 'If you have an account, please sign in so we will be able to response faster.',
        labelName: 'Name',
        labelEmail: 'Email',
        labelQuestion: 'Question',
        already_account: 'If you have an account, please sign in so we will be able to response faster.',
    },
    changePassword: {
        headerTitle: 'password',
        mainTitle: 'Change Password',
        labelNewPassword: 'New Password',
        labelConfirmPassword: 'Confirm Password',
    },
    createArcanaGen: {
        headerTitle: 'generate ARCANA',
        labelSetArcanaName: 'Set ARCANA’s name',
        labelCreatorName: 'Set Creator’s name',
        labelSetImages: 'Set main image',
        labelUploadRestriction: 'Check upload restrictions!',
        descUploadRestriction: 'You can upload JPG, GIF, or PNG images.\nEach image file can be up to 25MB.',
        labelWalletAddress: 'Wallet Address',
        labelSetPersona: 'Set PERSONA',
        labelSetDescription: 'Set description',
        labelSetMana: 'Set MANA',
        labelManaAddress: 'MANA Address',
        descManaAddress: '*Please copy the MANA Address. It can be used for recovering ARCANA in case of some error occurs during the ARCANA Generation.',
        labelSymbol: 'Symbol',
        descSymbol: '*Symbols are automatically assigned by the MANA Address. ',
        labelSeed: 'Mana Value',
        labelMana: 'Mana Value',
        labelManaInformation: 'MANA Information',
        labelMatrixCode: 'Set MATRIX code',
        descArcanaGen: 'ARCANA can be generated automatically without entering all the setting items by pressing the button below.'
    },
    createManaGen: {
        headerTitle: 'Recover ARCANA',
        mainTitle: 'MANA Address',
        messageManaAddress: 'Please be careful when editing the MANA address. If there is an error in the address, ARCANA cannot be generated.',
    },
    message: {
        messageSignOut: 'You have been successfully signed out',
        confirmSignOut: 'Are you sure you want to sign out?',
        messageChangeMobile: 'Your mobile number has been successfully changed.',
        messageContactUs: 'Your message has been sent. A member of our staff will get back to you as soon as possible',
        messageChangeProfileName: 'Change profile name',
        messageDelete: 'Your account has been successfully deleted.'
    },
    buttonlist: {
        cancelBtn: 'cancel',
        submitBtn: 'submit',
        nextBtn: 'next',
        connectBtn: 'connect',
        sendSmsBtn: 'send SMS',
        sendCodeBtn: 'send code',
        acceptBtn: 'accept',
        disconnectBtn: 'disconnect',
        backBtn: 'back',
        saveBtn: 'save',
        resendCodeBtn: 'Resend code',
        okBtn: 'OK',
        sendBtn: 'send',
        signinBtn: 'Sign in',
        createArcanaBtn: 'generate ARCANA',
        enterBtn: 'enter',
        confirmBtn: 'comfirm',
        goToPayBtn: 'pay',
        sellBtn: 'sell',
        chooseBtn: 'choose',
        makeBidBtn: 'make BID',
        duringTrading: 'Trading',
        yesBtn: 'yes',
        noBtn: 'no',
        createOctillionBtn: 'Create Octillion Account',
        proceedWithApp: 'Proceed with App',
        proceedWithWebsite: 'Proceed with Website',
        setManaAddressBtn: 'set MANA address',
        inputBtn: 'Input',
        selectBtn: 'Select',
        closeBtn: 'close',
        seeWalletBtn: 'see wallet',
        nextpaginationBtn: 'Next >>',
        previouspaginationBtn: '<< Prev.',
        seeDetailBtn: 'see details',
        more: 'More',
        clearBtn: 'clear',
        applyBtn: 'apply',
        search: 'Search',
        seeCookiePolicyBtn: 'see Cookie Policy',
        sortConfirmBtn: 'done',
        leviasNextBtn: 'NEXT',
        selectArcanaBtn: 'Select ARCANA',
        selectPersonaBtn: 'Select PERSONA',
        absorbBtn: 'absorb',
        drawChainBtn: 'Draw Chain',
        drawBtn: 'Draw',
        goToRegisterBtn: 'Go to register',
    },
    offer: {
        makeAskOffer: 'Make Ask Offer',
        makeBidOffer: 'Make Bid Offer',
        selectPaymentCurrency: 'Select Payment Currency',
        animaPayment: 'Anima',
        japaneseYenPayment: 'Japanese Yen',
        currentBidOffer: 'Current BID Offer',
        myAnimaBalance: 'My ANIMA',
        AskOffer: 'Ask Offer',
        BidOffer: 'Bid Offer',
        setAskPrice: 'Set Ask Price',
        setBidPrice: 'Set Bid Price',
        confirmAskOffer: 'Confirm Ask Offer',
        confirmBidOffer: 'Confirm Bid Offer',
        itemCategory: 'Item Category',
        symbolAndTokenName: 'Symbol / Token Name',
        tokenId: 'Token ID',
        txCategory: 'Tx Category',
        price: 'Price',
        providerCharge: 'Provider Charge',
        notifyAskParagraph: 'An ASK Offer was successfully recorded on the ANICANA Chain. \nThe ASK ID is below. \nFor checking your offers and trade history please visit Marketplace Octillion.',
        notifyBidParagraph: 'A BID offer was successfully recorded on the ANICANA Chain. \nThe BID ID is below. \nFor checking your offers and trade history please visit Marketplace Octillion.',
        cancelAskConfirm: 'Are you sure you want to cancel ASK offer?',
        cancelBidConfirm: 'Are you sure you want to cancel BID offer?',
        biddingPaymentInfo: 'The payment will be processed via the LEVIAS application. If you do not have LEVIAS yet , please use the icon below to download. If you have LEVIAS already, please proceed the payment.',
        createOctillionAccount: 'Create Account',
        createOctillionParagraph: 'It seems you do not have an Octillion account yet. \nBID offers will be processed via the Marketplace Octillion, therefore if you do not have an account, please use the button below to register. ',
        nonOctillionText: 'Do not have an Octillion account?',
        selectThirdPartyProvider: 'Select Third Party Provider',
        tapToOpenPaymentPage: 'Tap to open\npayment page',
    },
    common: {
        yen: 'JPY',
        yenSymbol: 'JPY',
        formattedYen: '¥ {0}',
        animaSymbol: 'ANM',
        formattedAnima: '{0} ANM',
        noSearchResult: 'No search result',
        copy_text: "Copied",
        langClass: "en_lang",
    },
    // Seperately By Page Name
    accountPage: {
        Account: 'Account',
        Payment: 'Payment',
        Settings: 'Settings',
        Signout: 'Sign out',
        Contact: 'Contact',
        Terms_Of_Use: 'Terms Of Use',
        Privacy_Policy: 'Privacy Policy',
        Company: '@ 2023 InfiCreo ZRT',
    },
    footerPage: {
        Contact: 'Contact',
        Terms_Of_Use: 'Terms Of Use',
        Privacy_Policy: 'Privacy Policy',
        Cookie_Policy: 'Cookie Policy',
        Company: '@2023InfiCreo ZRT',
        Technical_Paper: 'Technical Paper',
        White_Paper: 'White Paper',
    },
    accountDetailPage: {
        Email_Address: 'Email Address',
        Mobile_Number: 'Mobile Number',
        Security_Code: 'Security Code',
        MFA: '2FA',
        Password: 'Password',
        Delete_Account: 'Delete Account',
        changeProfileLabel: 'Choose an image from your gallery.',
        GALLERY: 'GALLERY',
        TAKE_PHOTO: 'TAKE PHOTO',
        infoSuccess: 'User info updated successfully!'
    },
    changeEmailPage: {
        change_Email_Address: 'Change Email Address',
        Register_your_new_email_address: 'Register your new email address.',
        new_email_address: 'new email address',
        confirm_email_address: 'confirm email address',
    },
    changeEmailVerificationPage: {
        change_Email_Address: 'change Email Address',
        Verification_Code: 'Verification Code',
        Enter_the_verification_code_sent_to_your_new_email_address: 'Enter the verification code sent to your new email address.',
        Have_not_received_any_verification_Email: 'Have not received any verification Email?',
        Resend_code: 'Resend code',
        Your_email_address_has_been_successfully_changed: 'Your email address has been successfully changed.',
        Cancel_message:'Are you sure you want to cancel? \nAll changes will be lost.'
    },
    changeMobilePage: {
        change_mobile_number: 'Change Mobile Number',
        Register_your_new_phone_number: 'Register your new phone number.',
        new_mobile_number: 'new mobile number',
        confirm_mobile_number: 'confirm mobile number',
    },
    changeMobileVerificationPage: {
        change_mobile_number: 'Change Mobile Number',
        Verification_Code: 'Verification Code',
        Enter_the_verification_code_sent_to_your_new_mobile_number: 'Enter the verification code sent to your new mobile number.',
        Have_not_received_any_verification_SMS: 'Have not received any verification SMS?',
        Resend_code: 'Resend code',
        Your_mobile_number_has_been_successfully_changed: 'Your mobile number has been successfully changed.',
    },
    changeNamePage: {
        Change_profile_name: 'Change profile name',
    },
    changeMFAPage: {
        You_have_enable_2FA: 'You have enable 2FA',
        You_have_disable_2FA: 'You have disable 2FA',
    },
    changePasswordPage: {
        change_Password: 'Change Password',
        Old_Password: 'Old Password',
        New_Password: 'New Password',
        Confirm_Password: 'Confirm Password',
        Your_password_has_been_successfully_changed: 'Your password has been successfully changed.',
        password_had_been_reset: 'Your password has been reset. PLease sign in again.',
    },
    deleteAccountpage: {
        Check_conditions: 'Check conditions',
        Reason_for_withdrawal: 'Reason for withdrawal',
        Important_points: 'Important points',
        confirm_Modal_Title: `Are you sure you want to delete your account?\n Your account will be permanently deleted and cannot be recovered.`,
        complete_Modal_Text: 'Your account has been successfully deleted.',
    },
    userWalletPage: {
        Address: 'Address',
        Status: 'Status',
        Connected: 'Connected',
        Not_connected: 'Not connected',
        confirm_Modal_Title: 'Are you sure you want to disconnect your wallet? \nYou will not be able to use the services of the portal.',
        complete_Modal_Text: 'Your wallet has been successfully disconnected.',
    },
    settingsPage: {
        Version: 'Version',
        Language: 'Language',
        Time_Zone: 'Time Zone',
        confirm_Modal_Text: 'Are you sure you want to change settings?',
        BGM: 'BGM',
    },
    contactPage: {
        Name: 'Name',
        Email: 'Email',
        Question: 'Question',
        complete_Modal_Text: 'Your message has been sent. A member of our staff will get back to you as soon as possible',
    },
    sidebarPage: {
        Home: 'Home',
        Topics: 'Topics',
        Trend: 'Trend',
        Arcanas: 'ARCANAS',
        Personas: 'PERSONAS',
        NewArcana: 'NEW ARCANAS',
        NewPersona: 'NEW PERSONAS',
        Games: 'Products',
        News: 'News',
        New: 'New',
        CodeD: 'Code-D',
        Top10: 'Top 10',
        Setting: 'Setting',
        Sign_Out: 'Sign Out',
        Sign_In: 'Sign In',
        Contact: 'Contact',
        Technical_Paper: 'Technical Paper',
        White_Paper: 'White Paper',
        Terms_Of_Use: 'Terms Of Use',
        Privacy_Policy: 'Privacy Policy',
        Cookie_Policy: 'Cookie Policy',
        Company: '@ 2023 InfiCreo ZRT',
        Square:'SQUARES'
    },
    menuPage: {
        Home: 'Home',
        Games: 'Products',
        CodeD: 'Code-D',
        Arcana : 'ARCANAS',
        Persona : 'PERSONA',
        Support: 'Support',
        Settings: 'Settings',
        Topics: 'Topics',
        Trend: 'Trend',
        Square:'SQUARES'
    },
    walletDashboardPage: {
        Address: 'Address',
        Send: 'Send',
        Receive: 'Receive',
        TX_History: 'TX History',
        Wallet_Settings: 'Settings',
        Mana_Address: 'Mana Address',
        Absorb: 'Absorb',
        Draw_History: 'Draw History',
        Support: 'Support',
        ARCANA: 'MY ARCANA',
        PERSONA: 'MY PERSONA',
        Coming_Soon: 'Coming soon',
        ANIMA: 'ANIMA',
        OTHERS: 'OTHERS',
        LEVICA: 'LEVICA',
        securityToken: 'Security Token',
    },
    sendAnimaPage: {
        Send_ANIMA: 'Send ANIMA',
        Address: 'Address',
        ANIMA_Amount: 'ANIMA Amount',
        Available: 'Available:',
    },
    sendAnimaConfirmPage: {
        Confirmation: 'Confirmation',
        Are_you_sure_you_want_to_make_a_transaction_with_the_details_below: 'Are you sure you want to make a transaction with the details below?',
        Blockchain: 'Blockchain',
        Amount: 'Amount',
        To: 'To',
        complete_Modal_Text: 'The transaction has been successfully made. Please check it in the transaction history.',
        nft_successful_sent: 'The NFT has been successfully sent. Please check it in the transaction history.'
    },
    loginPage: {
        Sign_in_text: 'Sign in',
        email: 'email',
        forgot_email: 'forgot email',
        password: 'password',
        forgot_password: 'forgot password',
        Sign_in_btn: 'SIGN IN',
        Do_not_have_LEVIAS_ID_yet: 'Do not have LEVIAS ID yet?',
        Create_account: 'CREATE ACCOUNT',
        errorText: 'Error',
        user_not_found: 'User not found',
        Code_has_been_successfully_send: 'Code has been successfully send',
    },
    cancelRegPage: {
        cancelModalTitle: 'Your LEVIAS ID has not been created  yet!',
        cancelModalText: 'Are you sure you want to cancel? All data you have registered will be discarded.',
        signIn: 'Sign In'
    },
    idIssuePage: {
        Create_your_LEVIAS_ID: 'Create your LEVIAS ID',
        LEVIAS_ID_is_needed_for_using_the_services_of_ANICANA_θupa_Please_check_and_agree_to_the_Terms_of_Use_and_Privacy_Policy_of_LEVIAS_below: 'LEVIAS ID is needed for using the services of ANICANA θupa. Please check and agree to the Terms of Use and Privacy Policy of LEVIAS below.',
        Term_of_Use: 'Term of Use',
        Privacy_Policy: 'Privacy Policy',
    },
    userNamingPage: {
        Profile_Name: 'Profile Name',
        Set_your_profile_name_which_will_be_shown_to_the_public: 'Set your profile name, which will be shown to the public. ',
        Please_be_careful_when_entering_a_real_name_or_a_name_that_can_identify_a_specific_individual: 'Please be careful when entering a real name or a name that can identify a specific individual.',
        You_can_edit_it_in_the_Profile_menu_later: 'You can edit it in the “Profile” menu later.',
    },
    setProfileIconPage: {
        Profile_Icon: 'Profile Icon',
        Set_a_profile_image_If_you_do_not_want_to_set_it_now_you_can_do_it_later_in_the_Profile_menu: 'Set a profile image. If you do not want to set it now, you can do it later in the “Profile” menu. ',
        chooseImgModalText: 'Choose an image from your gallery or take a photo.',
    },
    emailAddressSettingsPage: {
        Set_Email_Address: 'Set Email Address',
        Enter_an_email_address_for_your_account_It_will_be_used_as_your_contact_address: 'Enter an email address for your account. It will be used as your contact address.',
    },
    enterVerificationCodePage: {
        verification_code: 'verification code',
        Enter_the_verification_code_sent_to_your_registered_email_address: 'Enter the verification code sent to your registered email address.',
        Have_not_received_any_verification_email: 'Have not received any verification email?',
        Resend_code: 'Resend code',
    },
    twoFactorVerificationPage: {
        two_factor_authentication: 'Two-factor Verification',
        new_mobile_number: 'new mobile number',
        confirm_mobile_number: 'confirm mobile number',
        Register_your_phone_number_for_two_factor_authentication: 'Register your phone number for two-factor authentication.',
    },
    mobileVerificationCodePage: {
        verification_code: 'verification code',
        Enter_the_verification_code_sent_to_your_registered_mobile_number: 'Enter the verification code sent to your registered mobile number.',
        Have_not_received_any_verification_SMS: 'Have not received any verification SMS?',
        Resend_code: 'Resend code',
    },
    passwordSetPage: {
        set_password: 'set password',
        new_password: 'new password',
        confirm_password: 'confirm password',
        Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters_at_least_one_digit_and_one_special_character: 'Your password must be at least 8 characters long including upper and lower case letters at least one digit.',
        Your_LEVIAS_ID_has_been_successfully_created: 'Your LEVIAS ID has been successfully created.',
        Please_sign_in: 'Please sign in.',
        SIGN_IN: 'SIGN IN',
        CONFIRM: 'CONFIRM',
    },
    passwordResetPage: {
        Password_Reset: 'Password Reset',
        email: 'email',
        mobile_number: 'mobile number',
        Please_enter_you_registered_email_address_and_phone_number_An_SMS_will_be_sent_with_the_verification_number: 'Please enter you registered email address and phone number. An SMS will be sent with the verification number.',
        SEND_SMS: 'SEND SMS',
    },
    passwordResetOTPPage: {
        Verification_Code: 'Verification Code',
        Enter_the_verification_code_sent_to_your_registered_mobile_number: 'Enter the verification code sent to your registered mobile number. ',
        Have_not_received_any_verification_SMS: 'Have not received any verification SMS?',
        Resend_code: 'Resend code',
    },
    passwordResetFormPage: {
        Password_reset: 'Password reset',
        new_password: 'new password',
        confirm_new_password: 'confirm new password',
        Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters: 'Your password must be at least 8 characters long including upper and lower case letters.',
        CONFIRM: 'CONFIRM',
    },
    forgotEmailPage: {
        Forgot_Email: 'Forgot Email',
        mobile_number: 'mobile number',
        password: 'password',
        Please_enter_you_registered_phone_number_and_password_An_SMS_will_be_sent_with_with_your_registered_email_address: 'Please enter you registered phone number and password. An SMS will be sent with with your registered email address.',
        SEND_SMS: 'SEND SMS',
        confirm_Modal_Title: 'An SMS has been sent to the registered mobile phone.',
        complete_Modal_Text: 'Please check the email address in the SMS and sign in.',
    },
    authContext: {
        walletConnectError: "Cannot get wallet address. Please reload and try again.",
        otpCodeMessage: "メールに送信されたワンタイムパスワードを確認して下さい。"
    },
    leviasFooter: {
        LEVIAS_Inc_All_rights_reserved: '© LEVIAS Inc. All rights reserved.',
    },
    tokenList: {
        headerTitle: 'ARCANAs',
        Persona: 'PERSONAs',
        filter: 'Filters',
        sort: 'Sort'
    },
    topicList: {
        headerTitle: 'Topics',
    },
    newsList: {
        headerTitle: 'news',
        all: 'All'
    },
    trendList: {
        headerTitle: 'Trend',
    },
    productList: {
        headerTitle: 'Products',
        new:'New Arrivals',
        rating: 'Ratings',
        age: 'Age',
        lang: 'Language',
        category: 'Category',
        provider: 'Provider'
    },
    anicana: {
        headerTitle: 'anicana'
    },
    drawChainList:{
        headerTitle:'Draw Chain',
        persona:'Persona'
    },
    drawChainComparison:{
        drawSuccessMsg: 'Draw Chain has been successfully completed!',
        drawFailMsg: 'Ooops...\nSomething went wrong.\nPlease check the PERSONA condition and try again.',
        necessaryPersona: 'Necessary Persona',
        yourPersona: 'Your Persona',
        reselectPersona:'reselect PERSONA'
    },
    squareList:{
        headerTitle:'Square',
        subscribed: 'Subscribed',
        unsubscribed:'Unsubscribed',
        subscribedSuccess: 'Successfully subscribe to the square',
        unsubscribeSuccess:'Successfully unsubscribe to the square',
        newSquare:'New Square'
    },
    squareDetail:{
        subscribe:'Subscribe',
        unsubscribe:'Unsubscribe',
        subscribers:'subscribers',
        drawChain:'Draw Chain',
        all:'All',
        games:'Games',
        options:'Options',
        news:"News",
        popularGames:"Popular Games",
        drawChains:"Draw Chains",
        personas:"PERSONAS",
        adSquare:"Ad Square",
        createArcana:"Create Arcana",
        createArcanaDialog:{
            title:"Create Arcana",
            okButton:"Generate Arcana",
            cancelButton:"Cancel",
        },
        squarePoints:"ADEX",
        squarePointAlert:"All the {0}'s ADEX will be consumed to generate ARCANA. Do you proceed?",
        registerSquare:"Square registration is required to use the draw chain, so please register with Square.",
        confirmUnsubscribe:"Are you sure you want to unsubscribe from Square?",
        footer:{
            contact:'Contact',
            termsOfUse:'Terms Of Use',
            privacyPolicy:'Privacy Policy',
            contactDescription:'Thank you for visiting our Square. If you have any inquiries please contact us via the email below.',
            lawNotation:'特定商取引法表記'
        }
    },
    filter: {
        headerTitle: 'filter',
        category: 'Category',
        arcana: 'ARCANA',
        persona: 'PERSONA',
        basics: 'Basics',
        scores: 'Scores',
        saleStatus: 'ASK Status',
        underSale: 'ASK (日本円）',
        scoreMaxWarning: 'Score can be set up to {0}',
    },
    sort: {
        headerTitle: 'Sort by',
        Newest: 'Newest',
        Oldest: 'Oldest',
        done: 'done',
    },
    tokenPage: {
        My_Wallet: 'My Wallet',
        My_Tokens: 'My Tokens',
        ADD: 'ADD',
        Are_you_sure_you_want_to_delete_〇〇_Token: 'Are you sure you want to delete',
        〇〇_Token_has_been_successfully_deleted: 'Token has been successfully deleted.',
    },
    tokenAddPage: {
        My_Wallet: 'My Wallet',
        Add_Token: 'Add Token',
        Token_Name: 'Token Name',
        Token_Address: 'Token Address',
        Token_Unit: 'Token Unit',
        Token_Price: 'Token Price',
        cancel: 'cancel',
        add: 'add',
        Send: 'Send',
        Are_you_sure_you_want_to_cancel: 'Are you sure you want to cancel?',
        〇〇_Token_has_been_successfully_added_to_your_wallet: 'Token has been successfully added to your wallet. ',
    },
    tokenDetailPage: {
        My_Wallet: 'My Wallet',
        TKN: 'TKN',
        Send: 'Send',
        Receive: 'Receive',
        Delete: 'Delete',
        Received: 'Received',
        Sent: 'Sent',
        Pending: 'Pending',
        transaction: 'Transactions',
        TX_ID: 'TX ID',
        From: 'From',
        Are_you_sure_you_want_to_delete_〇〇_Token: 'Are you sure you want to delete',
        〇〇_Token_has_been_successfully_deleted: 'Token has been successfully deleted.',
    },
    tokenSendPage: {
        My_Wallet: 'My Wallet',
        Send_〇〇: 'Send 〇〇',
        Address: 'Address',
        long_press_to_paste: 'long press to paste',
        read_QR_code: 'read QR code',
        Amount: 'Amount',
        enter_amount: 'enter amount',
        cancel: 'cancel',
        next: 'next',
        Are_you_sure_you_want_to_cancel_sending: 'Are you sure you want to cancel sending?',
    },
    tokenReceivePage: {
        My_Wallet: 'My Wallet',
        My_Address: 'My Address',
        My_wallet_QR_code: 'My wallet\'s QR code',
    },
    tokenSendConfirmationPage: {
        My_Walet: 'My Wallet',
        Confirmation: 'Confirmation',
        Please_confirm_the_transaction_details_below: 'Please confirm the transaction details below.',
        Token_Name: 'Token Name',
        Blockchain: 'Blockchain',
        Amount: 'Amount',
        To: 'To',
        cancel: 'cancel',
        submit: 'submit',
        Are_you_sure_you_want_to_cancel_sending: 'Are you sure you want to cancel sending?',
        The_Transaction_has_been_successfully_requested: 'The Transaction has been successfully requested.',
    },
    loginLeviasIdMsgModal: {
        LEVIAS_ID: 'LEVIAS ID',
        For_using_the_services_of_ANICANA_θupa_you_need_your_LEVIAS_ID_provided_by_LEVIAS_INC: 'For using the services of ANICANA θupa, you need your LEVIAS ID provided by LEVIAS INC.',
        Do_not_show_this_again: 'Do not show this again.',
        NEXT: 'NEXT',
    },
    leviasBottomModal: {
        CANCEL: 'CANCEL',
        BACK: 'BACK',
        GALLERY: 'GALLERY',
        TAKE_PHOTO: 'TAKE PHOTO',
        ENTER_EMAIL: 'ENTER EMAIL',
        ENTER_CODE: 'ENTER CODE',
        ENTER_MOBILE: 'ENTER MOBILE',
        code_resend: 'code resend',
    },
    walletArcanaDetailPage: {
        Name: 'Name',
        Element: 'Element',
        Green_Star: 'Green Star',
        Bloodline: 'Bloodline',
        Birthday: 'Birthday',
        FOR_Force: 'FOR (Force)',
        ABS_Abyss: 'ABS (Abyss)',
        DFT_Determination: 'DFT (Determination)',
        MND_Mind: 'MND (Mind)',
        INT_Intelligence: 'INT (Intelligence)',
        EXP_Experience: 'EXP (Experience)',
        Peerless_Steel: 'Peerless Steel',
        Golden_Light: 'Golden Light',
        Unfathomable_Abyss: 'Unfathomable Abyss',
        Adamantine_Rock: 'Adamantine Rock',
        Crimson_Flame: 'Crimson Flame',
        Eternal_Tree: 'Eternal Tree',
        Heavenly_Dew: 'Heavenly Dew',
        ASK: 'ASK',
        BID: 'BID',
        Token_Id: 'Token Id',
        Created_By: 'Created By',
        Contract_Address: 'Contract Address',
        Blockchain: 'Blockchain',
        Token_Standard: 'Token Standard',
        MX_Creator_Bonus: 'MX Creator Bonus',
        anima: 'Anima'
    },
    bottomNav: {
        home: 'Home',
        square: 'Square',
        games: 'Product',
        menu: 'Search',
        news: 'News',
    },
    review: {
        feedbackTitle: 'Thank you for your feedback.'
    },
    codeD: {
        message: "Code-D (Code Dimension) is a product of Anidrive Inc. If you have any inquiries please contact via the email below.",
        whatsCodeD: "What is Code-D?",
        codeDInput: 'Code-D Input',
        aboutTitle: "About Code-D",
        about: "Code Dimension is an interface that allows you to activate the ARCANA Generator by entering a valid 8-digit alphanumeric code and create your own ARCANA. \n \n If you have any questions about Code- D, please contact us at the email address below.",
        popupText: "LEVIAS ID でサインイン(ウォレット認証)してください。まだアニカナウォレットをお待ちでない方やアニカナをはじめてご利用になられる方は、新規LEVIAS ID を作成してください。※Web3Auth でプライベートキーを生成します。",
        aleradyUsed: "Coupon not found or already used.",
    },
    chainDetailPage: {
        FOR_Force: 'FOR',
        ABS_Abyss: 'ABS',
        DFT_Determination: 'DFT',
        MND_Mind: 'MND',
        INT_Intelligence: 'INT',
        EXP_Experience: 'EXP',
    }          
}
