import React, { useContext, useEffect, useState } from 'react'
import ScrollToTop from '../common/ScrollToTop'
import { getToken, setDoNotShowAgain, getDoNotShowAgain } from '../../services/storage'
import { LeviasFooter } from '../common/Footer'
import { strings, translations } from "../../services/localization";
import { useMediaQuery } from 'react-responsive'
import { LeviasSwalIDMsg } from "../common/Swal";
import { ReactComponent as GradientSVG } from '../../assets/icons/gradient.svg';
import { ReactComponent as Gradient2SVG } from '../../assets/icons/gradient2.svg';
import { appContext } from '../../context/appContext'
import { Helmet } from "react-helmet"
import { REACT_APP_ENV } from "../../config";
import { isChrome } from 'react-device-detect'
import Header from './Header';

const AppWrapper = ({ children, disableHeader, offMainbody, offPaddingBot, className, additionalBottom, noLayout = false, isForm, isCenter, isLeft, disableFooter, disabled, isLeviasLayout, scrollableHeader, mainbodyClassName, leviasIDMsg, isLeviasTeamAndPrivacy, leviasPcBlockClass, metaTitle, metaDescription, isSpecCodeD }) => {

    const [onShow, setOnShow] = useState(false)
    const [notSignIn, setNotSignIn] = useState(false)
    const [scrollPoint, setScrollPoint] = useState(0)

    const loginLeviasIdMsgModal = translations.loginLeviasIdMsgModal;
    const commonlist = translations.common;
    const [onLoad, setOnload] = useState(false)
    const { hasInput, showRecentSearch, language } = useContext(appContext);

    const onGetToken = async () => {
        var token = await getToken()
        if (token) {
            setOnShow(true)
            setNotSignIn(false)
        } else {
            setNotSignIn(true)
        }
    }

    useEffect(() => {
        onGetToken()

        // If has result token, don't show showLeviasIDMsg modal bos
        let url_string = `${window.location}`
        let url = new URL(url_string);
        url_string = url_string.replace(url?.hash?.substring()[0], '?')
        url = new URL(url_string)
        const hasResultToken = url.searchParams.get("result") ? false : true
        if (hasResultToken && leviasIDMsg) {
            showLeviasIDMsg()
        }
    }, [])

    useEffect(() => {
        if (language) {
            const langClass = translations.formatString(translations.common.langClass);
            if (document.body && langClass) {
                document.body.className = langClass;
            }
        }
    }, [language])

    const showLeviasIDMsg = async () => {
        const loginNoShowAgain = getDoNotShowAgain()
        if (loginNoShowAgain === 'true') {
            return
        }
        LeviasSwalIDMsg.fire({
            html: `
            <h3>${strings.formatString(loginLeviasIdMsgModal.LEVIAS_ID)}</h3>
            <p style="text-align: center;">${strings.formatString(loginLeviasIdMsgModal.For_using_the_services_of_ANICANA_θupa_you_need_your_LEVIAS_ID_provided_by_LEVIAS_INC)}</P>
           `,
            confirmButtonText: strings.formatString(loginLeviasIdMsgModal.NEXT),

        }).then(() => {
            setDoNotShowAgain('true')
        })
    }

    const isPh = useMediaQuery({ maxWidth: 576 })
    const isPC = useMediaQuery({ minWidth: 992 })

    const browser_type_css = isChrome ? 'isChrome' : '';

    const titleSEO = metaTitle || 'ANICANAテュラー'
    const descriptionSEO = metaDescription || 'ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています'

    return (
        <ScrollToTop>
            <Helmet>
                <title>{titleSEO}</title>
                <meta name="title" content={titleSEO} />
                <meta name="description" content={descriptionSEO} />
                <meta property="og:title" content={titleSEO} />
                <meta property="og:description" content={descriptionSEO} />
                {REACT_APP_ENV !== 'production' && <meta name="robots" content="noindex, nofollow" />}
            </Helmet>
            <div id={isLeviasLayout ? (isPh ? 'levias' : 'levias-pc') : isSpecCodeD ? 'code-wrapper' : 'anicana-portal'} className={`layout ${hasInput && !scrollableHeader && !disableHeader ? 'hasInput' : ''} ${translations.formatString(commonlist.langClass)} ${browser_type_css}`}>
                <div className={`app-wrapper levias-app-wrapper ${className || ''} ${scrollableHeader ? 'scrollable-app-wrapper' : ''}`} id="app-wrapper" style={disabled ? { pointerEvent: 'none' } : {}}>
                    <div className=''>
                        <div className={showRecentSearch ? 'body-overlay' : ''}>
                            <Header />
                            <div id="mainbody" style={{ paddingBottom: offPaddingBot ? offPaddingBot : noLayout ? '80' : additionalBottom ? 220 : 80 }} className={`${mainbodyClassName || ''} ${offMainbody ? 'offMainbody' : 'mainbody'} ${isLeviasTeamAndPrivacy ? 'mainbody-term-and-pripacy' : ''}`}>
                                <div className={isCenter ? "flex-center" : isLeft ? "flex-center-left" : ""}>
                                    <div className={`levias-block levias-pc-block ${leviasPcBlockClass || ''} ${isLeviasTeamAndPrivacy ? 'levias-pc-block-term-and-privacy' : ''} ${isForm ? "form-wrapper" : ""}`}>
                                        <div className='levias-pc-blur'></div>
                                        <div id="main-content">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                {additionalBottom && isPC && (
                                    <div className="card d-none d-md-block py-2 bottom-card">
                                        <div className="pb-2 pt-3">{additionalBottom}</div>
                                    </div>
                                )}
                                {!noLayout && !disableFooter
                                    ? isLeviasLayout
                                        ? <LeviasFooter />
                                        : <></>
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', top: -500 }}>
                    <GradientSVG />
                    <Gradient2SVG />
                </div>
            </div>
        </ScrollToTop>
    )
}

export default AppWrapper
