import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../services/fetch";
import { setValue } from "../services/storage";

const initialState = {
  auth:1
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.auth = action.payload
    },
    signOut(state) {
      state.auth = {};
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      // Add user to the state array
      state.auth = action.payload;
      setValue('user_info', action.payload.user)
    })
  },
});

export const authAction = authSlice.actions;

export default authSlice;

const fetchUserInfo = createAsyncThunk(
  'auth/fetchUserInfo',
  async (thunkAPI) => {
    const response = await getData(`user/info`);
    return response.data
  }
)