import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SwalCorrect,
  LeviasSwalCorrect,
  LeviasSwalWrong,
  SwalWrong,
} from "../../components/common/Swal";

import { LeviasForgotEmailForm } from "../../components/auth/AuthForm";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { fetchData } from "../../services/fetch";
import { strings, translations } from "../../services/localization";

function ForgotEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailValidated, setEmailValidated] = useState(true);
  const [loading, setLoading] = useState(false);

  const language = useSelector((state) => state.language.language);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mobileNumber, setPhoneNumber] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [isLeviasLayout, setIsLeviasLayout] = useState(false);
  const forgotEmailPage = translations.forgotEmailPage;
  const loginPage = translations.loginPage;

  const textChangeHandler = (type, value) => {
    if (type === "phone") {
      setPhoneNumber(value);
    } else if (type === "password") {
      console.log("set pw");
      setPassword(value);
    }
  };

  const nextStepHandler = () => {
    setLoading(true);
    fetchData(
      `forgot-email`,
      {
        mobile_number: mobileNumber,
        password,
      },
      "post",
      true
    ).then((response) => {
      if (response.ok) {
        LeviasSwalCorrect.fire({
          title: strings.formatString(forgotEmailPage.confirm_Modal_Title),
          text: strings.formatString(forgotEmailPage.complete_Modal_Text),
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login/idms");
            setLoading(false);
          }
        });
      } else {
        setLoading(false);
        LeviasSwalWrong.fire({
          title: loginPage.errorText,
          text: loginPage.user_not_found,
        });
      }
    });
  };

  const phoneValidator = (value, result) => {
    if (value === "phone") {
      if (result && result.length > 7) {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }
  };

  const onShowHandler = (type, status) => {
    console.log(type, status);
    if (type === "password") {
      setShowPassword(status);
    }
  };

  const passwordValidator = (value, result) => {
    if (value === "password") {
      if (result && result.length > 7) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    }
  };

  const leviasLayout = () => {
    setIsLeviasLayout(true);
  };

  useEffect(() => {
    phoneValidator("phone", mobileNumber);
    passwordValidator("password", password);
  }, [password, mobileNumber]);

  return (
    <AppWrapper
      isLeviasLayout
      disableSidebar
      className="auth-container"
      isForm
      isCenter
      isLeviasHandler={"forgot"}
    >
      <LeviasForgotEmailForm
        textChangeHandler={textChangeHandler}
        nextStepHandler={nextStepHandler}
        phoneNumber={mobileNumber}
        phoneValid={phoneValid}
        password={password}
        showPassword={showPassword}
        passwordValid={passwordValid}
        onShowHandler={onShowHandler}
        loading={loading}
        leviasLayout={leviasLayout}
      />
    </AppWrapper>
  );
}

export default ForgotEmail;
