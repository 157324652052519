import React from 'react'
import '../../style/app/loadingNew.scss';

export const OnLoading = ({ pRelative, marginAuto, noLabel, inline, className, style }) => {
    const marginWrapper = marginAuto ? { margin: 'auto' } : {}
    const wrapper = {
        style: pRelative ? {
            position: 'relative',
            height: 'fit-content',
            top: 0,
            ...marginWrapper,
            ...style
        } : { ...marginWrapper, ...style }
    }
    return (
        // <AppWrapper className="auth-container" title='dashboard'>
        <div className={`${noLabel ? 'w-100' : ''} l-wrapper flex-column ${inline ? 'inline-loading' : ''} ${className || ''}`} {...wrapper}>
            {/* <LoadingSVG /> */}
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3 className='mt-3 fw-500 f-expended' style={{ fontSize: 20, width: '200px' }}>{noLabel ? null : "Loading...."}</h3>
        </div>
        // </AppWrapper>
    )
}

export const LeviasOnLoading = () => {
    return (
        <div className="levias-pc-blur l-wrapper flex-column" style={{ margin: 'auto' }}>
            <div className="levias-lds-default lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3 className='mt-3 fw-500 f-expended' style={{ fontSize: 20 }}>Loading....</h3>
        </div>
    )
}
