import { clearLocalStorage, getToken } from './storage';
import axios from 'axios';
import { API_DOMAIN } from '../config';
import { LeviasSwalWrong } from '../components/common/Swal';
// import Swal from '../components/Swal';
// import base64 from 'base-64';

const api_url = API_DOMAIN;

const axiosClient = axios.create({
  baseURL: api_url,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

const axiosCustomClient = axios.create({
  baseURL: '',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

axiosClient.interceptors.response.use(
  function (response) {
    response.ok = true;
    return response;
  },
  function (error) {
    let res = error.response;
    console.log('fetch error', error);
    if (res.status == 401) {
      logout(() => window.location.href = "/login/idms");
      return false;
    } else {
      console.error(`エラーが発生しました [${res.status}]`, res);
      if (res && res.data) {
        LeviasSwalWrong.fire({
          icon: 'error',
          title: 'エラーが発生しました',
          text: res.data.message || error.message,
        });
      }
      res.ok = false;
      return res;
    }
  }
);

// Store AbortControllers to cancel requests by URL
const abortControllerMapFetchData = {};
const abortControllerMapFetchCustomData = {};

export async function getData(url = '', withCredentials = true) {
  var token = getToken();
  return await axiosClient({
    method: 'get',
    url,
    withCredentials,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
}

export async function postData(url = '', data = {}) {
  return await fetchData(url, data);
}

export async function putData(url = '', data = {}) {
  return await fetchData(url, data, 'put');
}

export async function deleteData(url = '', data = {}) {
  return await fetchData(url, data, 'delete');
}

export async function postFormData(url = '', data = {}, method = 'post') {
  var token = await getToken();

  return await fetch(api_url + url, {
    method: method,
    headers: {
      'authorization': 'Bearer' + token,
      'Content-Type': 'multipart/form-data',
      'Process-data': false
    },
    body: data
  }).then(async (response) => {
    if (response.ok) {
      // you can call response.json() here too if you want to return json
      var data = [];
      if (response.status !== 204) {
        var promise = response.json();
        await promise.then(x => data = x);
      }
      return {
        ok: response.ok,
        status: response.status,
        data: data,
      }
    } else {
      if (response.status === 401) {
        var message = await response.json();
      } else {
        //handle errors in the way you want to
        var message = await response.json();
        if (!message) {
          message = await response.text();
        }
        return {
          ok: response.ok,
          status: response.status,
          data: `エラーが発生しました [${message.message}]`,
        }
      }
    }
  }).then(json => json);
}

export async function fetchData(
  url = '',
  data = {},
  method = 'post',
  withCredentials = false,
  customToken = undefined,
  cancelPreviousRequests = false
) {
  const token = getToken();

  // Cancel the previous request if the parameter is true and there is an existing controller
  if (cancelPreviousRequests && abortControllerMapFetchData[url]) {
    abortControllerMapFetchData[url].abort();
  }

  // Create a new AbortController for the current request
  const controller = new AbortController();
  abortControllerMapFetchData[url] = controller;

  try {
    const response = await axiosClient({
      method,
      url,
      withCredentials,
      headers: {
        'Authorization': 'Bearer ' + (customToken === undefined ? token : customToken),
      },
      data,
      signal: controller.signal // Use the signal property for cancellation
    });

    // Clear the AbortController after a successful request
    delete abortControllerMapFetchData[url];

    return response;
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log(`Request to ${url} was canceled`);
    } else {
      console.error('Request failed:', error);
    }
  }
}

export async function fetchCustomData(
  url = '',
  data = {},
  method = 'post',
  withCredentials = false,
  customToken = undefined,
  cancelPreviousRequests = false
) {
  const token = getToken();

  // Cancel the previous request if the parameter is true and there is an existing controller
  if (cancelPreviousRequests && abortControllerMapFetchCustomData[url]) {
    abortControllerMapFetchCustomData[url].abort();
  }

  // Create a new AbortController for the current request
  const controller = new AbortController();
  abortControllerMapFetchCustomData[url] = controller;

  try {
    const response = await axiosCustomClient({
      method,
      url,
      withCredentials,
      headers: {
        'Authorization': 'Bearer ' + (customToken === undefined ? token : customToken),
      },
      data,
      signal: controller.signal // Use the signal property for cancellation
    });

    // Clear the AbortController after a successful request
    delete abortControllerMapFetchCustomData[url];

    return response;
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log(`Request to ${url} was canceled`);
    } else {
      console.error('Request failed:', error);
    }
  }
}

export const logout = async (fnc = undefined) => {
  clearLocalStorage();

  if (fnc) {
    fnc();
  }
}

export const toFormData = (item) => {
  var form_data = new FormData();
  // form_data.append('debug_mode', 'true');
  for (var key in item) {
    var value = item[key];
    console.log(key, value)
    form_data.append(`${key}`, value);
  }
  console.log(form_data)
  return form_data;
}

export const toJson = (formData) => {
  var object = {};
  formData.forEach(function (value, key) {
    object[key] = value;
  });
  return object;
}
