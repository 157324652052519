import { toFormData } from "./fetch";
import Cookie from 'js-cookie'

export async function setToken(value) {
    await localStorage.setItem('token', value);
}

export function getToken() {
    return localStorage.getItem('token');
}

export async function setTempToken(value) {
    await localStorage.setItem('temp_token', value);
}

export function getTempToken() {
    return localStorage.getItem('temp_token');
}

// export async function setIframeLogin(value) {
//     return localStorage.setItem('isIframeLogin',value);
// }
// export function getIframeLogin() {
//     return localStorage.getItem('isIframeLogin');
// }

export async function setWalletAddress(value) {
    if (value != null && value !== 'null' && value) {
        console.log("ADDRESS:" + value)
        await localStorage.setItem('wallet_address', value);
    }

}

export function getWalletAddress() {
    return localStorage.getItem('wallet_address');
}

export async function setIsDoneIDMS(value) {
    await localStorage.setItem('is_idms_done', value);
}

export function getIsDoneIDMS() {
    return localStorage.getItem('is_idms_done');
}
export function removeValue(key) {
    return localStorage.removeItem(key)
}

export async function setIsDoneMetamask(value) {
    await localStorage.setItem('is_metamask_done', value);
}

export async function setRedirect(redirect_uri) {
    await localStorage.setItem('redirect_uri', redirect_uri);
}
export function getRedirect() {
    return localStorage.getItem('redirect_uri');
}

export async function setLoginRedirectTo(value) {
    console.log("SET IFRAME PARAMS:" + value)
    await localStorage.setItem('iframe_redirect_params', value);
}

export async function setLoginParams(value) {
    await localStorage.setItem('login_params', JSON.stringify(value));
}
export function getLoginParams() {
    let p = localStorage.getItem('login_params')
    if (p) {
        return JSON.parse(p);
    }
    return p
}

export async function setWeb3authToken(value) {
    await localStorage.setItem('web3authToken', value);
}
export function getWeb3authToken() {
    return localStorage.getItem('web3authToken');
}

export function getIsDoneMetamask() {
    return localStorage.getItem('is_metamask_done');
}

export async function setDoNotShowAgain(value) {
    await localStorage.setItem('do_not_show_again', value);
}
export function getDoNotShowAgain() {
    return localStorage.getItem('do_not_show_again');
}


export async function setValue(key, value) {
    await localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
}

export async function getValue(key) {
    const value = await localStorage.getItem(key);
    return typeof value === 'string' ? value : JSON.parse(value);
}

export function clearLocalStorage() {
    console.log("CLEAR")
    // let iframe_params = getLoginRedirectTo()
    let redirect = getRedirect()
    let donot_show = getDoNotShowAgain()
    let login_params = getLoginParams()

    const res = localStorage.clear();
    // if(iframe_params){
    //     setLoginRedirectTo(iframe_params)
    // }
    if (redirect) {
        setRedirect(redirect)
    }
    if (donot_show) {
        setDoNotShowAgain(donot_show);
    }
    if (login_params) {
        setLoginParams(login_params)
    }
    // if(isIframeLogin){
    //     setIframeLogin(isIframeLogin)
    // }
    return res;
}

export async function setCookie(key, value) {
    await Cookie.set(
        key,
        JSON.stringify(value),
        { expires: 365 * 100 } // 100 year cookie expire time
    )
}

export async function getCookie(key) {
    return await Cookie.get(key);
}

export function removeCookie(key) {
    return Cookie.remove(key)
}

export async function setLastTxId(value) {
    await localStorage.setItem('LastTxId', value);
}

export function getLastTxId() {
    return localStorage.getItem('LastTxId');
}

export function clearAllStorage() {
    sessionStorage.clear()
    const a = document.cookie.split(';');
    const b = a.map(cookie => cookie.trim().split('=')[0]);
    (b || []).forEach((key) => {
        removeCookie(key)
    });
}

export function setRecentSearch(value) {
    localStorage.setItem('recent_search', JSON.stringify(value));
}

export function getRecentSearch() {
    let p = localStorage.getItem('recent_search')
    if (p) {
        return JSON.parse(p);
    }
    return p || []
}

export function addRecentSearch(value) {
    var recent_search = getRecentSearch() || [];
    recent_search = recent_search.filter(x => x !== value);
    recent_search.unshift(value);
    setRecentSearch(recent_search.slice(0, 5));
}