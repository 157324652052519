import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profile:{}
}

const profileSlice = createSlice({
    name:'profile',
    initialState:initialState,
    reducers:{
      add(state, action){
        state.profile = action.payload
      }
    }
  })


  
export const profileAction = profileSlice.actions;

export default profileSlice