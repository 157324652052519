import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_DOMAIN } from "../../config";
import Container from "react-bootstrap/Container";
import axios from "axios";
import Swal from "sweetalert2";

import { AuthBackground } from "../../components/auth/AuthForm";
import { LoginForm } from "../../components/auth/AuthForm";
import { authAction } from "../../store/Auth";
import { MemberRegistrationStep } from "../../components/auth/AuthForm";
import { getValue } from "../../services/storage";

function ChooseRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [completeIdms, setCompleteIdms] = useState(false);
  const [completeWeb3Auth, setCompleteWeb3Auth] = useState(false);

  useEffect(() => {
    getValue("openlogin_store").then((response) => {
      if (response) {
        setCompleteWeb3Auth(true);
      }
    });
  }, []);

  const language = useSelector((state) => state.language.language);

  const selectMemberHandler = useCallback((registerType) => {
    if (registerType === "meta") {
      // setCurrentForm("IDMS-registration");
    } else if (registerType === "idms") {
      navigate("/register/idms");
    }
  }, []);

  const body = useMemo(() => {
    return (
      <>
        <MemberRegistrationStep
          selectMemberHandler={selectMemberHandler}
          completeWeb3Auth={completeWeb3Auth}
          completeIdms={completeIdms}
          setCompleteWeb3Auth={setCompleteWeb3Auth}
          setCompleteIdms={setCompleteIdms}
          language={language}
        />
      </>
    );
  }, [selectMemberHandler, completeWeb3Auth, completeIdms, language]);

  return (
    <Container fluid className="d-flex flex-row auth-container">
      {body}
    </Container>
  );
}

export default ChooseRegister;
