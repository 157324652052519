import React, { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, DropdownIcon, ExportIcon, WarningIcon } from './Icon'
import RPhoneNumberInput from 'react-phone-number-input'
import { formatPhoneNumberForDisplay, getUniqueId } from './commonFnc'
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';
import '../../style/app/form.scss'

export const TextBox = props => {
    const { font_notosan, labelStyle, className, containerClassName, containerStyle, inputStyle, label, additionalComponent, disabled, placeholder, icon, labelClassName, onClickIcon, currency, ...rest } = props;
    return (
        <div className={containerClassName} style={containerStyle}>
            <FormLabel label={label} className={labelClassName} style={labelStyle} font_notosan={font_notosan} />
            <div className='d-flex flex-row'>
                <div className='position-relative w-100'>
                    <input
                        {...rest} placeholder={placeholder}
                        type={props.number ? "number" : "text"}
                        className={`form-control auth-form-input-absolute jp-fw-400 ${font_notosan} ${disabled ? 'pe-none' : ''} ${icon ? 'pe-5' : currency ? 'p-r-75' : ''} ${className || ''}`}
                        style={inputStyle || {}}
                        disabled={!!disabled}
                        inputMode={props.type ? props.type == "numeric" ? "numeric" : "decimal" : "text"}
                    />
                    {
                        icon &&
                        <div className='position-absolute flex-center h-100 pointer' style={{ top: 0, right: 15 }} onClick={onClickIcon ? onClickIcon : () => { }}>
                            {icon}
                        </div>
                    }
                    {
                        currency &&
                        <div className='position-absolute text-center flex-center border-left-purple' style={{ top: 1, right: 0, width: 75, height: 'calc(100% - 3px)' }}>
                            <span className='attribute-value' style={{ maxWidth: '100%' }}>{currency}</span>
                        </div>
                    }
                </div>

                {additionalComponent}
            </div>
        </div>
    )
}

export const TextArea = props => {
    const { labelStyle, className, containerClassName, containerStyle, inputStyle, label, additionalComponent, disabled, placeholder, icon, labelClassName, ...rest } = props;
    return (
        <div className={containerClassName} style={containerStyle}>
            <FormLabel label={label} className={labelClassName} style={labelStyle} />
            <div className='d-flex flex-row'>
                <div className='position-relative w-100'>
                    <textarea
                        rows="3"
                        placeholder={placeholder}
                        {...rest}
                        className={`auth-form-input-absolute text-white form-control border-box rounded border border-secondary ${disabled ? 'pe-none' : ''} ${className || ''}`}
                        disabled={!!disabled}
                        style={Object.assign({ minHeight: 170, paddingRight: 30 }, inputStyle)}
                    ></textarea>
                    {
                        icon &&
                        <div className='position-absolute flex-start h-100' style={{ top: 10, right: 15 }}>
                            {icon}
                        </div>
                    }
                </div>

                {additionalComponent}
            </div>
        </div>
    )
}

export const Select = (props) => {
    return (
        <>
            <FormLabel label={props.label} className={props.labelClassName} />
            <div className={`select mt-4 ${props.ms_0 ? '' : 'ms-3'}`}>
                <select {...props} className={`form-select ${props.className || ''}`}
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>
                    {
                        props.options && props.options.map((option, index) =>
                            <option key={index} value={option.value} className="form-select-option">{option.text}</option>)
                    }
                </select>
                <DropdownIcon className={`${props.iconClass} dropdown-icon`} />
            </div>
        </>
    )
}

export const FileInput = (props) => {
    const uniq_id = 'id' + (new Date()).getTime();
    useEffect(() => {
        setName(props.value.name)
    }, [props])
    const [name, setName] = useState(props.value.name || props.value)
    return (
        <div className={props.className || ''}>
            <FormLabel label={props.label} className={props.labelClassName} />
            <div className='fileinput'>
                <input type='file' className='form-control textbox' id={uniq_id}
                    onChange={e => {
                        setName(e.target.files[0].name);
                        props.onChange(e.target.files[0]);
                    }} hidden />
                <label className='form-control auth-form-input-absolute textbox mb-0 fs-18 pointer' htmlFor={uniq_id}>
                    {
                        name ? name : <span className='label-placeholder fs-18'>{props.placeholder}</span>
                    }
                </label>
                <ExportIcon className={`dropdown-icon ${props.iconClass} `} onClick={() => document.getElementById(uniq_id).click()} />
                {
                    props.warningText &&
                    <div className={"d-flex flex-row align-items-center justify-content-end"} onClick={props.onClickWarning}>
                        {/* File is up to 20MB. Accept filetypes are png,jpg,gif. */}
                        <WarningIcon className={props.warningIconClass} /> <span style={{ textTransform: 'inherit', maxWidth: 'unset' }} className='fw-400 ms-1 attribute-value input-desc-text'>{props.warningText}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export const FormLabel = props => {
    return (
        <>
            {
                props.label &&
                <h5
                    className={`jp-fw-400 fw-500 custom-label ${props.className || ''} ${props.font_notosan}`}
                    style={props.style || {}}
                >
                    {props.label}
                </h5>
            }
        </>
    )
}

export const PhoneInput = (props) => {
    const { label, value, onChange, className, containerClass, inputClass, placeholder, labelClassName } = props;
    const [id] = useState(getUniqueId('phoneInputId'));
    const [autoFocus, setAutoFocus] = useState(false);
    const phoneInputRef = useRef();

    const formatValue = () => {
        if (phoneInputRef.current.value) {
            setTimeout(() => {
                let raw = formatPhoneNumberForDisplay(phoneInputRef.current.value);
                document.getElementById(id).value = raw;
            }, 250)
        }
    }

    const handleOnChange = (value) => {
        onChange(value);
    }

    useEffect(() => {
        if (value) {
            formatValue()
        }
    }, [value])

    const onFocus = () => setAutoFocus(true);
    const onBlur = () => setAutoFocus(false);

    const InputComponent = React.forwardRef((props, ref) => <input {...props} autoFocus={autoFocus} onFocus={onFocus} onBlur={onBlur} ref={ref} className={`${inputClass || ''} w-100`} style={{ border: 'none', height: 48, padding: '0 15px 3px' }} autoComplete="off" />);

    return (
        <>
            <FormLabel label={label} className={labelClassName} style={{ opacity: '0.5' }} />
            <RPhoneNumberInput
                ref={phoneInputRef}
                international={false}
                countryCallingCodeEditable={false}
                defaultCountry="JP"
                placeholder={placeholder}
                className={`phoneInput-2 ${containerClass || ''}`}
                inputComponent={InputComponent}
                countrySelectProps={{ arrowComponent: () => <ChevronDownIcon className="ms-2" /> }}
                id={id}
                value={value}
                onChange={handleOnChange}
            // autoComplete={false}
            // onBlur={() => formatValue(value, phoneInputRef.current.value)}
            // inputComponent={MyInputComponent}
            // onCountryChange={val => setCountryCode(val)}
            />
        </>
    )
}

export const DatePicker = ({ date, onChange, ...rest }) => {
    registerLocale('ja', ja)
    return (
        <ReactDatePicker selected={date} dateFormat="yyyy-MM-dd" onChange={onChange} locale={'ja'} {...rest} />
    )
}

export const SelectBox = ({ labelClassName, label, onChange, value, className, options, ...rest }) => {
    return (
        <>
            <FormLabel label={label} className={labelClassName} />
            <div className={`selectbox`}>
                <div {...rest} className={`${className || ''}`}
                    style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>
                    {
                        options && options.map((option, index) => (
                            <div key={`selectbox_${index}`} onClick={() => onChange(option.value)} className={`selectbox-option pointer ${option.value === value ? 'selected' : ''}`}>
                                {
                                    option.component
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}