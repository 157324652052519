import Web3 from "web3";
import {
    REACT_APP_CHAIN_ID,
    REACT_APP_INCUBATOR_CONTRACT_ADDRESS,
    REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS,
    REACT_APP_MP_WALLET_ADDRESS,
    REACT_APP_NULL_ADDRESS,
    REACT_APP_RPC_TARGET,
    REACT_APP_WALLET_CLIENT_ID,
    REACT_APP_WEB3AUTH_VERIFIER_NAME,
    REACT_APP_WEB3AUTH_NETWORK
} from "../../config";
import Incubator from "../../abi/Incubator.json";
import AecanaGenInfo from "../../abi/ArcanaGeneratorInfo.json";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal"
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { TokenType } from "./enum";
import { calculateBN, getDecimalPlaces, toBigNumber } from "./commonFnc";
import { SwalWrong } from "./Swal";

const maxPriorityFeePerGas = "50000000000"; // Max priority fee per gas
const maxFeePerGas = "150000000000"; // Max fee per gas
// const maxPriorityFeePerGas = "3000000000"; // Max priority fee per gas
// const maxFeePerGas = "12000000000"; // Max fee per gas

export default class EthereumRpc {
    provider;

    constructor(provider) {
        if (!provider) {
            provider = new Web3.providers.HttpProvider(
                REACT_APP_RPC_TARGET
            )
        }
        this.provider = provider;
    }

    async getChainId() {
        try {
            const web3 = new Web3(this.provider);

            // Get the connected Chain's ID
            const chainId = await web3.eth.getChainId();

            return chainId.toString();
        } catch (error) {
            return error;
        }
    }

    async getAccounts() {
        try {
            const web3 = new Web3(this.provider);

            // Get user's Ethereum public address
            const address = (await web3.eth.getAccounts())[0];

            return address;
        } catch (error) {
            return error;
        }
    }

    async isEggExist(egg_id, address = null) {
        try {
            const web3 = new Web3(this.provider);

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Egg), TokenType.getContractAddress(TokenType.Egg));
            console.log({ contract, abi: TokenType.getABI(TokenType.Egg) })
            // Read message from smart contract
            const egg_owner_address = await contract.methods.ownerOf(egg_id).call();
            return !!egg_owner_address.match(/^0x\w+$/);
        } catch (error) {
            console.log('isEggOwner', error);
            return false;
        }
    }

    async getBalance() {
        try {
            const web3 = new Web3(this.provider);

            // Get user's Ethereum public address
            const address = (await web3.eth.getAccounts())[0];

            // Get user's balance in ether
            const balance = web3.utils.fromWei(
                await web3.eth.getBalance(address) // Balance is in wei
            );

            return balance;
        } catch (error) {
            return error;
        }
    }

    async getName(contract_address) {
        try {
            const web3 = new Web3(this.provider);

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Anima), contract_address);
            const name = await contract.methods.name().call();
            return name;

        } catch (error) {
            return '';
        }
    }

    async getAnimaDecimal() {
        try {
            const web3 = new Web3(this.provider);
            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Anima), TokenType.getContractAddress(TokenType.Anima));

            // Read message from smart contract
            return await contract.methods.decimals().call();
        } catch (error) {
            return error;
        }
    }

    async getAnimaBalance(address = null, decimal_places = 0) {
        try {
            const web3 = new Web3(this.provider);
            if (!address) {
                // Get user's Ethereum public address
                address = (await web3.eth.getAccounts())[0];
            }

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Anima), TokenType.getContractAddress(TokenType.Anima));

            // Read message from smart contract
            const decimals = await contract.methods.decimals().call();

            // Read message from smart contract
            const balance = await contract.methods.balanceOf(address).call();

            console.log({ balance, decimals, decimal_places })
            return calculateBN(balance, decimals, decimal_places)
        } catch (error) {
            return error;
        }
    }

    async getPersonaBalance(address = null) {
        try {
            const web3 = new Web3(this.provider);
            if (!address) {
                // Get user's Ethereum public address
                address = (await web3.eth.getAccounts())[0];
            }

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Persona), TokenType.getContractAddress(TokenType.Persona));
            console.log({ contract, address, abi: TokenType.getABI(TokenType.Persona), contract_address: TokenType.getContractAddress(TokenType.Persona) })
            // Read message from smart contract
            const balance = await contract.methods.balanceOf(address).call();
            return balance;
        } catch (error) {
            console.log(error)
            return 0;
        }
    }

    async getArcanaBalance(address = null) {
        try {
            const web3 = new Web3(this.provider);
            if (!address) {
                // Get user's Ethereum public address
                address = (await web3.eth.getAccounts())[0];
            }

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Arcana), TokenType.getContractAddress(TokenType.Arcana));
            console.log({ contract, address, abi: TokenType.getABI(TokenType.Arcana), contract_address: TokenType.getContractAddress(TokenType.Arcana) })
            // Read message from smart contract
            const balance = await contract.methods.balanceOf(address).call();
            return balance;
        } catch (error) {
            console.log(error)
            return 0;
        }
    }

    async getCustomTokenBalance(contract_address, address = null, decimal_places = 0, getFullBalance = false) {
        try {
            const web3 = new Web3(this.provider);
            if (!address) {
                // Get user's Ethereum public address
                address = (await web3.eth.getAccounts())[0];
            }

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Anima), contract_address);
            const decimals = await contract.methods.decimals().call();

            // Read message from smart contract
            const balance = await contract.methods.balanceOf(address).call();
            console.log(balance)

            return calculateBN(balance, decimals, decimal_places, getFullBalance);
        } catch (error) {
            return null;
        }
    }

    async generateArkana(eggTokenId, to, seed, metadataHash, sig, debugMode, onTransactionHash, onError, debug = false) {
        if (debug) {
            onTransactionHash('0x31dbf675e0cdec71edc2d12274dcb8c84fd6a5f78db38f85d6498202e95c7140');
            return;
        }
        const web3 = new Web3(this.provider);
        console.log("send Generate Arcana Transaction")
        const incubatorContract = new web3.eth.Contract(Incubator, REACT_APP_INCUBATOR_CONTRACT_ADDRESS);
        const address = (await web3.eth.getAccounts())[0];
        const gasPrice = 0;
        const options = {
            from: address,
            gasPrice
        };
        console.log(incubatorContract)
        return incubatorContract.methods.incubate(eggTokenId, to, seed, metadataHash, sig).send(options).on('transactionHash', onTransactionHash).on('error', onError)
    }
    // async registerMana(beneficiary,manaAddress,eggId,seed,signature,onTransactionHash, onError) {
    //
    //     const web3 = new Web3(this.provider);
    //     console.log("send Generate Arcana Transaction")
    //     const arcanaGenerationInfoContract = new web3.eth.Contract(AecanaGenInfo, REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS);
    //     const address = (await web3.eth.getAccounts())[0];
    //     const gasPrice = 0;
    //     const options = {
    //         from: address,
    //         gasPrice
    //     };
    //     return arcanaGenerationInfoContract.methods.register(beneficiary,manaAddress,eggId,seed,signature).send(options).on('transactionHash', onTransactionHash).on('error', onError)
    // }

    async getInfoByBeneficiary(beneficiary, startIndex, limit) {
        try {
            const web3 = new Web3(this.provider);


            const arcanaGenerationInfoContract = new web3.eth.Contract(AecanaGenInfo, REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS);

            // Read message from smart contract
            console.log({ beneficiary, startIndex, limit })
            const res = await arcanaGenerationInfoContract.methods.getInfoByBeneficiary(beneficiary, startIndex, limit).call();

            return res;
        } catch (error) {
            return null;
        }
    }

    async getInfoByManaAddress(manaAddress) {

        try {
            const web3 = new Web3(this.provider);

            const arcanaGenerationInfoContract = new web3.eth.Contract(AecanaGenInfo, REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS);

            // Read message from smart contract
            const res = await arcanaGenerationInfoContract.methods.getInfoByManaAddress(manaAddress).call();

            return res;
        } catch (error) {
            return 0;
        }
    }

    async getManaTotalInfo(beneficiary) {

        try {
            const web3 = new Web3(this.provider);

            const arcanaGenerationInfoContract = new web3.eth.Contract(AecanaGenInfo, REACT_APP_ARCANA_GENERATION_INFO_CONTRACT_ADDRESS);

            // Read message from smart contract
            const res = await arcanaGenerationInfoContract.methods.getInfoCountByBeneficiary(beneficiary).call();

            return res;
        } catch (error) {
            return 0;
        }
    }


    async decodeError(error) {
        if (error.receipt) {
            console.log(error.receipt.revertReason)
            const web3 = new Web3(this.provider);
            console.log(web3.utils.hexToAscii(error.receipt.revertReason))
            return web3.utils.hexToAscii(error.receipt.revertReason);
        }
        return null;

    }
    async getTransaction(txHash) {

        const web3 = new Web3(this.provider);
        return await web3.eth.getTransactionReceipt(txHash)
    }

    async sendTransaction() {
        try {
            const web3 = new Web3(this.provider);

            // Get user's Ethereum public address
            const fromAddress = (await web3.eth.getAccounts())[0];

            const destination = fromAddress;

            const amount = web3.utils.toWei("0.001"); // Convert 1 ether to wei

            // Submit transaction to the blockchain and wait for it to be mined
            const receipt = await web3.eth.sendTransaction({
                from: fromAddress,
                to: destination,
                value: amount,
                maxPriorityFeePerGas: "5000000000", // Max priority fee per gas
                maxFeePerGas: "6000000000000", // Max fee per gas
            });

            return receipt;
        } catch (error) {
            return error;
        }
    }

    async signMessage(message = "YOUR_MESSAGE") {
        try {
            const web3 = new Web3(this.provider);

            // Get user's Ethereum public address
            const fromAddress = (await web3.eth.getAccounts())[0];

            const originalMessage = message;

            // Sign the message
            const pkey = await this.getPrivateKey()
            const signedMessage = await web3.eth.accounts.sign(
                originalMessage,
                pkey
            );

            return signedMessage.signature;
        } catch (error) {
            return error;
        }
    }

    async getPrivateKey() {
        try {
            const privateKey = await this.provider.request({
                method: "eth_private_key",
            });

            return privateKey;
        } catch (error) {
            return error;
        }
    }

    async approveContract(token_type_id, token_id, isCancelled = false) {
        const web3 = new Web3(this.provider);

        // Get user's Ethereum public address
        const fromAddress = (await web3.eth.getAccounts())[0];

        const contract = new web3.eth.Contract(TokenType.getABI(token_type_id), TokenType.getContractAddress(token_type_id));
        console.log({ address: isCancelled ? REACT_APP_NULL_ADDRESS : REACT_APP_MP_WALLET_ADDRESS, token_id })
        // Send transaction to smart contract to update message and wait to finish
        return new Promise((respond, reject) => {
            contract.methods.approve(isCancelled ? REACT_APP_NULL_ADDRESS : REACT_APP_MP_WALLET_ADDRESS, token_id).send({
                from: fromAddress,
                maxPriorityFeePerGas,
                maxFeePerGas
            }).on('transactionHash', function (hash) {
                respond(hash);
            }).catch(error => {
                console.log('error', error?.data?.message ? error?.data?.message : error?.data ? error?.data : error?.message ? error?.message : '')
                reject(error?.data?.message ? error?.data?.message : error?.data ? error?.data : error?.message ? error?.message : error);
                // if (error.data.code != -32000) {
                //     SwalWrong.fire({
                //         title: 'エラーが発生しました',
                //         text: error.data.message ? error.data.message : error.data ? error.data : error.message ? error.message : '',
                //     });
                // }
            });
        })
    }

    async newApproveContract(token_type_id, token_id, isCancelled = false) {
        const web3 = new Web3(this.provider);

        // Get user's Ethereum public address
        const fromAddress = (await web3.eth.getAccounts())[0];

        const contract = new web3.eth.Contract(TokenType.getABI(token_type_id), TokenType.getContractAddress(token_type_id));
        console.log({ address: isCancelled ? REACT_APP_NULL_ADDRESS : REACT_APP_MP_WALLET_ADDRESS, token_id })

        return new Promise((respond, reject) => {
            web3.eth.getTransactionCount(fromAddress, 'pending', (error, nonce) => {
                if (error) {
                    console.error('Failed to retrieve nonce:', error);
                    reject(error);
                    return;
                }

                web3.eth.getGasPrice((error, gasPrice) => {
                    if (error) {
                        console.error('Failed to retrieve gas price:', error);
                        reject(error);
                        return;
                    }

                    const gasPriceWei = web3.utils.toWei(gasPrice, 'gwei');
                    const gasLimit = 0; // Set your desired gas limit here

                    const transactionData = contract.methods.approve(isCancelled ? REACT_APP_NULL_ADDRESS : REACT_APP_MP_WALLET_ADDRESS, token_id).encodeABI();

                    const transactionObject = {
                        from: fromAddress,
                        gasPrice: gasPriceWei,
                        gas: gasLimit,
                        nonce: nonce,
                        data: transactionData,
                    };

                    web3.eth.sendTransaction(transactionObject)
                        .on('transactionHash', function (hash) {
                            respond(hash);
                        })
                        .on('error', function (error) {
                            console.error('Failed to send replacement transaction:', error);
                            reject(error);
                        });
                });
            });
        });
    }

    // for arcana & persona, amount is token_id. For anima, amount is anima's amount.
    async transferContract(token_type_id, to, amount, contract_address = null, showError = false) {
        const web3 = new Web3(this.provider);

        // Get user's Ethereum public address
        const fromAddress = (await web3.eth.getAccounts())[0];

        const contract = new web3.eth.Contract(TokenType.getABI(token_type_id), contract_address ?? TokenType.getContractAddress(token_type_id));
        // const txCount = await web3.eth.getTransactionCount(fromAddress);
        // await web3.eth.getPendingTransactions((error, result) => console.log("Pending Transactions", { error, result }))
        const options = {
            from: fromAddress,
            maxPriorityFeePerGas,
            maxFeePerGas,
            // nonce: txCount + 1
        };
        console.log({ options })
        // Send transaction to smart contract to update message and wait to finish
        if ((Number(token_type_id) === Number(TokenType.Anima)) || (Number(token_type_id) === Number(TokenType.Token))) {
            const decimals = await contract.methods.decimals().call();
            amount = toBigNumber(amount, decimals);
            /* fix: can't transfer anima above 1000 (The below can't transfer decimal value) commitID: 62375518dfbd8e4c1e45061a8c5b76e2251c4b0c */
            // const decimalsBN = new web3.utils.BN('10').pow(new web3.utils.BN(decimals));
            // amount = window.BigInt(amount ?? 0).toString() || 0;
            // amount = new web3.utils.BN(amount).mul(decimalsBN);

            // console.log({ amount }, amount.toString(), amount.toString().length)

            return new Promise((respond, reject) => {
                contract.methods.transfer(to, amount).send(options).once('transactionHash', function (hash) {
                    respond(hash);
                }).catch(error => {
                    // console.log(error.data.message);
                    console.log('error', error.data.message ? error.data.message : error.data ? error.data : error.message ? error.message : '')
                    if (showError) {
                        SwalWrong.fire({
                            title: 'エラーが発生しました',
                            text: error.data?.message ? error.data?.message : error.data ? error.data : error.message ? error.message : '',
                        });
                    }
                });
            })
        } else {
            return new Promise((respond, reject) => {
                const tx_hash_listener = contract.methods.transferFrom(fromAddress, to, amount).send(options)
                    .once('transactionHash', function (hash) {
                        console.log('once transactionHash', hash);
                        tx_hash_listener.off('receipt')
                        respond(hash);
                    })
                    .once('error', function (error) {
                        console.log({ error })
                        tx_hash_listener.off('error')
                        if (showError) {
                            SwalWrong.fire({
                                title: 'エラーが発生しました',
                                text: error.data?.message ? error.data?.message : error.data ? error.data : error.message ? error.message : '',
                            });
                        }
                    })
                // .catch(error => {
                // });
            })
        }
    }

    async absorbContract(predetor, prey) { // predetor = absorber, prey = target (both are token_id)
        const web3 = new Web3(this.provider);

        // Get user's Ethereum public address
        const fromAddress = (await web3.eth.getAccounts())[0];

        const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Persona), TokenType.getContractAddress(TokenType.Persona));
        // Send transaction to smart contract to update message and wait to finish
        return new Promise((respond, reject) => {
            contract.methods.absorb(predetor, prey).send({
                from: fromAddress,
                maxPriorityFeePerGas,
                maxFeePerGas
            }, async function (err, hash) {
                if (!err) {
                    const receiptMined = await getTransactionReceiptMined(web3, hash, 2000);
                    respond(receiptMined);
                } else {
                    console.log({ err })
                    respond(null)
                }
            });
        })
    }

    async drawContract(drawChainId, personaId) { // predetor = absorber, prey = target (both are token_id)
        const web3 = new Web3(this.provider);

        // Get user's Ethereum public address
        const fromAddress = (await web3.eth.getAccounts())[0];

        const contract = new web3.eth.Contract(TokenType.getABI(TokenType.DrawChain), TokenType.getContractAddress(TokenType.DrawChain));
        // Send transaction to smart contract to update message and wait to finish
        return new Promise((respond, reject) => {
            contract.methods.draw(drawChainId, personaId).send({
                from: fromAddress,
                maxPriorityFeePerGas,
                maxFeePerGas
            }, async function (err, hash) {
                if (!err) {
                    const receiptMined = await getTransactionReceiptMined(web3, hash, 2000);
                    respond(receiptMined);
                } else {
                    console.log({ err })
                    respond(null)
                }
            });
        })
    }

    async squareContract(method, SquareKey, address = null) { // predetor = absorber, prey = target (both are token_id)
        try {
            const web3 = new Web3(this.provider);

            // Get user's Ethereum public address
            const fromAddress = address ? address : (await web3.eth.getAccounts())[0];

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Square), TokenType.getContractAddress(TokenType.Square));
            console.log("contract", { method, SquareKey, fromAddress, contract_address: TokenType.getContractAddress(TokenType.Square) })
            switch (method) {
                case "follow":
                case "unfollow":
                    // Send transaction to smart contract to update message and wait to finish
                    return new Promise((respond, reject) => {
                        contract.methods[method](SquareKey, fromAddress).send({
                            from: fromAddress,
                            maxPriorityFeePerGas,
                            maxFeePerGas
                        }, async function (err, hash) {
                            if (!err) {
                                const receiptMined = await getTransactionReceiptMined(web3, hash, 2000);
                                respond(receiptMined);
                            } else {
                                console.log({ err })
                                respond({ status: false, error: err.message })
                            }
                        });
                    });
                case "isFollower":
                    const isFollower = await contract.methods[method](SquareKey, fromAddress).call();
                    console.log({ isFollower })
                    return isFollower;
                case "numFollowers":
                    const numFollowers = await contract.methods[method](SquareKey).call();
                    console.log({ numFollowers })
                    return numFollowers;
                default: throw new Error("Contract method is not valid.");
            }
        } catch (e) {
            console.log(e, e.data);
            switch (method) {
                case "numFollowers": return 0;
                default: return { status: false, error: e.message };
            }
        }
    }

    async getSquareNumFollowers(SquareKey) {
        try {
            const web3 = new Web3(this.provider);

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Square), TokenType.getContractAddress(TokenType.Square));
            // Read message from smart contract
            const numFollowers = await contract.methods.numFollowers(SquareKey).call();
            return numFollowers;
        } catch (error) {
            console.log(error)
            return 0;
        }
    }

    async getSquareIsFollower(SquareKey, address) {
        try {
            const web3 = new Web3(this.provider);

            const contract = new web3.eth.Contract(TokenType.getABI(TokenType.Square), TokenType.getContractAddress(TokenType.Square));
            // Read message from smart contract
            const isFollower = await contract.methods.isFollower(SquareKey, address).call();
            return isFollower;
        } catch (error) {
            console.log(error)
            return false;
        }
    }
}

export function getTransactionReceiptMined(web3, txHash, interval) {
    const transactionReceiptAsync = function (resolve, reject) {
        web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
            if (error) {
                console.log("getTransactionReceiptMined error", error)
                reject(error);
            } else if (receipt == null) {
                setTimeout(
                    () => transactionReceiptAsync(resolve, reject),
                    interval ? interval : 500);
            } else {
                resolve(receipt);
            }
        });
    };

    if (Array.isArray(txHash)) {
        return Promise.all(txHash.map(
            oneTxHash => getTransactionReceiptMined(web3, oneTxHash, interval)));
    } else if (typeof txHash === "string") {
        return new Promise(transactionReceiptAsync);
    } else {
        throw new Error("Invalid Type: " + txHash);
    }
};

export const initWeb3 = () => {
    return new Web3AuthNoModal({
        clientId: REACT_APP_WALLET_CLIENT_ID,
        chainConfig: { // this is ethereum chain config, change if other chain(Solana, Polygon)
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: REACT_APP_CHAIN_ID,
            rpcTarget: REACT_APP_RPC_TARGET
        },
    });

}

const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: REACT_APP_CHAIN_ID,
    rpcTarget: REACT_APP_RPC_TARGET,
    // displayName: "Ethereum Mainnet",
    // blockExplorer: "https://etherscan.io",
    // ticker: "ETH",
    // tickerName: "Ethereum",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig },
});


export const openloginAdapter = () => {
    console.log("VERIFIER:" + REACT_APP_WEB3AUTH_VERIFIER_NAME)
    return new OpenloginAdapter({
        privateKeyProvider,
        adapterSettings: {
            clientId: REACT_APP_WALLET_CLIENT_ID,
            network: REACT_APP_WEB3AUTH_NETWORK,
            // chainConfig: { // this is ethereum chain config, change if other chain(Solana, Polygon)
            //     chainNamespace: CHAIN_NAMESPACES.EIP155,
            //     chainId: REACT_APP_CHAIN_ID,
            //     rpcTarget: REACT_APP_RPC_TARGET
            // },
            uxMode: "redirect",
            whiteLabel: {
                name: " ",
                logoLight: "https://anicana.org/levica.svg",
                logoDark: "https://anicana.org/levica.svg",
                defaultLanguage: "en",
                dark: true, // whether to enable dark mode. defaultValue: false
            },
            loginConfig: {
                jwt: {
                    name: "ANICANA　θυρα",
                    verifier: REACT_APP_WEB3AUTH_VERIFIER_NAME,
                    typeOfLogin: "jwt",
                    clientId: "1",
                },
            },
        }
    });
}

const loginMethods = {
    google: { showOnModal: false },
    facebook: { showOnModal: false },
    twitter: { showOnModal: false },
    reddit: { showOnModal: false },
    discord: { showOnModal: false },
    twitch: { showOnModal: false },
    apple: { showOnModal: false },
    line: { showOnModal: false },
    github: { showOnModal: false },
    kakao: { showOnModal: false },
    linkedin: { showOnModal: false },
    weibo: { showOnModal: false },
    wechat: { showOnModal: false },
    email_passwordless: { showOnModal: true }
}

export const web3authModalParams = {
    modalConfig: {
        [WALLET_ADAPTERS.OPENLOGIN]: {
            //loginMethods,
            label: "openlogin",
            showOnModal: true,
        },
    },
};
