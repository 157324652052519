import React from 'react'
import { getRedirect } from '../../services/storage'

const Header = () => {
    const handleClose = () => {
        window.location.href = getRedirect()
    }

    return (
        <div id="toolbarWrap">
            <div className="mb-0" id="toolbar" style={{ padding: '10px 16px 10px 0px' }}>
                <div className="d-flex justify-content-between w-100">
                    {/* <div className="d-flex anikana-logo-box w-100">
                        <div className="d-flex align-items-center sidebar-flex pointer" style={{ paddingLeft: 16, minHeight: 45 }}>
                            <div className="pe-2"></div>
                        </div>
                    </div>
                    <div className="d-flex levias-logo-box" onClick={handleClose}>
                        閉じる
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Header