import { configureStore } from '@reduxjs/toolkit'
import authSlice from './Auth'
import languageSlice from './Language'
import profileSlice from './Profile'
import homeSlice from './Home'
import positionSlice from './Position'

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        language: languageSlice.reducer,
        profile: profileSlice.reducer,
        top_news: homeSlice.reducer,
        position: positionSlice.reducer
    },
})