import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getValue, setValue } from "../services/storage";
import { Web3authContext } from "./web3authContext";
import { onSetLanguageToEnglish, onSetLanguageToJapanese } from "../services/localization";
import { isObject } from "../components/common/commonFnc";

export const appContext = createContext(); // could have a default value

export const defaultFilter = { token_type_id: 1 }

export const AppProvider = ({ children }) => {
    const navigate = useNavigate();
    const web3auth = useContext(Web3authContext);
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState()
    const [timeZone, setTimeZone] = useState()
    const [globalSearch, setGlobalSearch] = useState('');
    const [showRecentSearch, setShowRecentSearch] = useState(false);
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState("DESC");
    const [filter, setFilter] = useState(defaultFilter);
    const [filterTotal, setFilterTotal] = useState(0);
    const [filterCount, setFilterCount] = useState(0);
    const [arcanaGenTempData, setArcanaGenTempData] = useState(undefined);
    const [hasInput, setHasInput] = useState(false);
    // const forceUpdate = useForceUpdate();

    useEffect(() => {
        if (filter) {
            let total = Object.keys(filter).length - 1;
            if (total > 0) {
                setFilterTotal(total);
            } else {
                setFilterTotal(0);
            }
        }
    }, [filter])

    const getLang = () => {
        if (/^en\b/.test(navigator.language)) {
            const deviceLanguage = window.navigator.language
            if (deviceLanguage === 'en-us' || deviceLanguage === 'en-US' || deviceLanguage === 'en') {
                setLanguage('English')
                // onSetLanguageToEnglish()
                // setValue('language', 'English')
            } else {
                setLanguage('English')
            }
        }
        else if (/^ja\b/.test(navigator.language)) {
            const deviceLanguage = window.navigator.language
            if (deviceLanguage === 'ja') {
                setLanguage('Japanese')
                // onSetLanguageToJapanese()
                // setValue('language', 'Japanese')
            } else {
                setLanguage('English')
            }
        }
    }

    const loadLangTimeZone = async () => {
        const timeZone = await getValue('time_zone');
        const language = await getValue('language')
        if (timeZone) {
            setTimeZone(timeZone)
        } else {
            setValue('time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone)
            setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
        if (language) {
            setLanguage(language)
        }
        else {
            // getLang()
            setLanguage("Japanese");
        }
    }

    useEffect(() => {
        loadLangTimeZone();
    }, [])

    useEffect(() => {
        if (language === 'English') {
            setValue('language', 'English')
            onSetLanguageToEnglish();
            // forceUpdate();
        }
        else if (language === "Japanese") {
            setValue('language', 'Japanese')
            onSetLanguageToJapanese();
            // forceUpdate();
        }
    }, [language])

    const updateLang = (lang) => {
        setLanguage(lang)
    }

    const updateTimeZone = (time) => {
        setTimeZone(time)
        setValue('time_zone', time)
    }

    const updateFilter = (key, value) => {
        if (Array.isArray(value) && value.length == 0) {
            delete filter[key];
        } else if (isObject(value)) {
            let valid = false;
            for (let properties in value) {
                if (value[properties]) {
                    valid = true;
                }
            }
            if (valid) {
                filter[key] = value;
            } else {
                delete filter[key];
            }
        } else if ((typeof value === 'string' || value instanceof String) && !value) {
            delete filter[key];
        } else {
            filter[key] = value;
        }
        setFilter(JSON.parse(JSON.stringify(filter)));
    }

    const resetFilter = () => {
        setFilter(JSON.parse(JSON.stringify(defaultFilter)));
        increaseFilterCount();
    }

    const increaseFilterCount = () => {
        setFilterCount(count => count + 1);
    }

    const value = useMemo(() => ({
        language, timeZone, updateTimeZone, updateLang, search, setSearch, filter, setFilter, updateFilter, resetFilter, filterCount, increaseFilterCount, arcanaGenTempData, setArcanaGenTempData, hasInput, setHasInput, filterTotal, sort, setSort, globalSearch, setGlobalSearch, showRecentSearch, setShowRecentSearch
    }), [language, timeZone, search, filter, filterCount, arcanaGenTempData, hasInput, filterTotal, sort, globalSearch, showRecentSearch]);

    return (
        <appContext.Provider value={value}>
            {children}
        </appContext.Provider>
    )
}
