import React from "react";
import AppWrapper from "../components/wrappers/AppWrapper";
import PDF from '../components/common/pdf'

const LeviasTerms = () => {
  return (
    <AppWrapper
      isLeviasLayout
      disableSidebar
      isLeviasTeamAndPrivacy
      disableFooter
      isLeviasHandler={"forgot"}
      className="bg-grey"
      mainbodyClassName="mainbody-pdf"
      leviasPcBlockClass="levias-pc-block-pdf"
    >
      <PDF file="LEVIAS-Account-Terms-of-Use.pdf"/>
    </AppWrapper>
  );
};

export default LeviasTerms;
