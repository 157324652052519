import React, { useContext, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import ChooseRegister from '../pages/auth/ChooseRegister'
import PasswordReset from '../pages/auth/PasswordReset'
import ForgotEmail from '../pages/auth/ForgotEmail'
import LeviasPrivacy from '../pages/LeviasPrivacy'
import LeviasTerms from '../pages/LeviasTerms'
import { appContext } from '../context/appContext';
import { useForceUpdate } from '../services/hook';
import Logout from '../pages/auth/Logout';

const RoutePath = () => {
  const id = new URL(`${window.location}`)?.href.split('/')?.pop()
  const forceUpdate = useForceUpdate();
  const { language } = useContext(appContext);
  useEffect(() => {
    forceUpdate();
  }, [language])
  return (
    <div className="route">
      <Routes>
        <Route exact element={<CustomRoute isLeviasLayout />}>
          <Route exact path="authorize" element={<Login />} />
          <Route exact path="login/idms/:callId/:signText" element={<Login />} />
          <Route exact path="logout/idms/:callId/:signText" element={<Logout />} />
          <Route exact path="login/idms" element={<Login />} />
          <Route exact path="register" element={<ChooseRegister />} />
          <Route exact path="register/idms" element={<Register />} />
          <Route exact path="register/idms/:callId/:signText" element={<Register />} />
          <Route exact path='password-reset' element={<PasswordReset />} />
          <Route exact path='change-email' element={<ForgotEmail />} />
          <Route exact path="levias-privacy" element={<LeviasPrivacy />} />
          <Route exact path="levias-terms" element={<LeviasTerms />} />
        </Route>
      </Routes>
    </div>
  )
}

export default RoutePath

const CustomRoute = (props) => {
  return (
    // <Suspense fallback={<Preloader {...props} isPh={isMobile}></Preloader>}>
    <Outlet />
    // </Suspense>
  )
}


