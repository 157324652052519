import LocalizedStrings from 'react-localization'
import initialLanguageStore from './localizationmain'

export let translations = new LocalizedStrings(initialLanguageStore);

export let strings = new LocalizedStrings({
  initialLanguageStore
});

export const onSetLanguageToEnglish = () => {
  translations.setLanguage('en');
}
export const onSetLanguageToJapanese = () => {
  translations.setLanguage('jp');
}