import { createSlice } from "@reduxjs/toolkit";
import { onSetLanguageToEnglish, onSetLanguageToJapanese } from "../services/localization";
import { setValue, getValue } from "../services/storage";

let initialLanguage
// let initialTimeZone

const setStroageZone =()=>{
    getValue('time_zone').then((response) => {
        if(response === null){
            setValue('time_zone',Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
        else{
            return Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    })
}

const setStroageLang =(lang)=>{
    getValue('language').then((response) => {
        if(response === null){
            setValue('language',lang)
        }
    })
}


const initialState = {
    // smth: getTimeZone(),
    language : null,
    timeZone: null
}

const languageSlice = createSlice({
    name:'language',
    initialState,
    reducers:{
        japanese(state,action){
            state.language='Japanese'
            state.timeZone=action.payload
        },
        english(state, action){
            state.language='English'
            state.timeZone=action.payload
        }
    },
})

export const languageAction = languageSlice.actions

export default languageSlice