import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  position:{
    pageNum: null,
    scrollPositionY: null,
    data: []
  }
}

const positionSlice = createSlice({
  name:'position',
  initialState:initialState,
  reducers:{
    setPosition(state, action){
      state.position = action.payload
    }
  }
})


  
export const { setPosition } = positionSlice.actions;

export default positionSlice