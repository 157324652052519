import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { memo, useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { W3authLoginButton } from "../common/W3authLoginButton";
import { useRef } from "react";
import PinInput from "react-pin-input";

import ANICANA from "../../assets/images/ANICANA.png";
import {
  CameraIcon,
  LeviasCameraIcon,
  ProfileIcon,
  LeviasProfileIcon,
  ShowPasswordIcon,
  LeviasShowPasswordIcon,
} from "../common/Icon";
import { HidePasswordIcon, LeviasHidePasswordIcon } from "../common/Icon";
import { useForceUpdate } from "../../services/hook";
import { appContext } from "../../context/appContext";
import { CheckboxIcon } from "../common/Icon";
import { LeviasCheckboxIcon } from "../common/Icon";
import { CheckboxOnclickIcon } from "../common/Icon";
import { LeviasCheckboxOnclickIcon } from "../common/Icon";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../store/Profile";
import { BottomModal, LeviasBottomModal } from "../common/BottomModal";
import { strings, translations } from "../../services/localization";
import { OnLoading, LeviasOnLoading } from "../common/OnLoading";
import { PhoneInput } from "../common/Form";
import { useMediaQuery } from "react-responsive";
import { LeviasSwalProfileIcon } from "../common/Swal";
import { fetchData } from "../../services/fetch";
import Linkify from "../common/Linkify";
import { isMobile } from "react-device-detect";

const stepVariant = {
  initial: {
    backgroundColor: "#D9D9D9",
  },
  current: {
    backgroundColor: "#495376",
  },
  complete: {
    backgroundColor: "#61AD89",
  },
};

const stepTransition = {
  backgroundColor: {
    duration: 0.5,
  },
};

export const AuthBackground = () => {
  return (
    <>
      <Row className="col-6">
        <Col></Col>
      </Row>
      <Row className="col-6" style={{ backgroundColor: "#ECF3FF" }}>
        <Col></Col>
      </Row>
    </>
  );
};

export const ChangeMobileForm = ({
  emailValidated,
  phoneValid,
  confirmPhoneNumber,
  onEmailVerifyHandler,
  textChangeHandler,
  phoneNumber,
  email,
  nextStepHandler,
}) => {
  const content = translations.changeMobileForm;
  const buttonlist = translations.buttonlist;
  const changeMobilePage = translations.changeMobilePage; // Usage: strings.formatString(changeMobilePage.change_mobile_number)
  const navigate = useNavigate();

  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "0" }}>
        <div>
          <div className="my-2 mt-3 text-center">
            <span className="ff-Kardust fs-25">
              {strings.formatString(changeMobilePage.change_mobile_number)}
            </span>
          </div>
          <div className="divider mb-3 mt-3 w-100 mx-auto"></div>
          {/*<div className="pb-1 pt-2 text-center">
              <span>Verification Code</span>
          </div>*/}
          <div>
            <span className="kardust-light-font" style={{ fontSize: "18px" }}>
              {strings.formatString(
                changeMobilePage.Register_your_new_phone_number
              )}
            </span>
          </div>
          {
            <div className="py-3 min-height-40">
              <div className="py-3">
                <PhoneInput
                  label={strings.formatString(
                    changeMobilePage.new_mobile_number
                  )}
                  inputClass="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) => textChangeHandler("phone", e)}
                  value={phoneNumber}
                  placeholder={strings.formatString(
                    content.labelNewMobileNumber
                  )}
                />
              </div>
              <div className="py-3">
                <PhoneInput
                  label={strings.formatString(
                    changeMobilePage.confirm_mobile_number
                  )}
                  inputClass="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) => textChangeHandler("confirm-phone", e)}
                  value={confirmPhoneNumber}
                  placeholder={strings.formatString(
                    content.labelConfirmMobileNumber
                  )}
                />
              </div>
            </div>
          }

          <div className="text-center py-4 d-flex w-100 justify-content-evenly">
            <button
              style={{ opacity: 0.5 }}
              onClick={() => navigate("/account_detail")}
              className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
            >
              {strings.formatString(buttonlist.backBtn)}
            </button>
            {phoneValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn btn-custom-active my-3 py-1 col-5"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-custom-success my-3 py-1 col-5"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileVerifyForm = ({
  setIdmsRegistrationStep,
  textChangeHandler,
  phoneCodeValid,
  smsVerificationCode,
  nextStepHandler,
  onShowModalHandler,
}) => {
  const [showImageCapModal, setShowImageCapModal] = useState();
  const content = translations.mobileVerifyForm;
  const buttonlist = translations.buttonlist;
  const changeMobileVerificationPage =
    translations.changeMobileVerificationPage; // Usage: strings.formatString(changeMobileVerificationPage.change_mobile_number)
  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  const pinRef = useRef();
  return (
    <>
      {
        <BottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          phoneVerify
          label={strings.formatString(
            changeMobileVerificationPage.Have_not_received_any_verification_SMS
          )}
        />
      }
      <div className="w-100 auth-form-container">
        <div style={{ padding: "0" }}>
          <div>
            <div className="my-2 mt-3 text-center">
              <span>
                {strings.formatString(
                  changeMobileVerificationPage.change_mobile_number
                )}
              </span>
            </div>
            <div className="divider mb-3 mt-3 w-100 mx-auto"></div>
            <div className="pb-1 pt-2 text-center">
              <span>
                {strings.formatString(
                  changeMobileVerificationPage.Verification_Code
                )}
              </span>
            </div>
            <div className="pb-3 min-height-40">
              <div className="py-3 pt-0 text-center">
                <PinInput
                  focus
                  length={6}
                  initialValue={smsVerificationCode}
                  secret
                  onChange={(e) => {
                    textChangeHandler("verify-phone-code", e);
                  }}
                  type="password"
                  inputMode="numeric"
                  style={{ padding: "10px" }}
                  inputStyle={{
                    borderColor: "#FFF",
                    margin: "0 5px",
                    color: "#FFF",
                    width: "33px",
                    borderRadius: "10px",
                  }}
                  inputFocusStyle={{ borderColor: "#FFF" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "18px" }}
                >
                  {strings.formatString(
                    changeMobileVerificationPage.Enter_the_verification_code_sent_to_your_new_mobile_number
                  )}
                </p>
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "14px" }}
                >
                  {strings.formatString(
                    changeMobileVerificationPage.Have_not_received_any_verification_SMS
                  )}
                </p>
                <div className="text-center">
                  <span
                    className="kardust-light-font anikana-label"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowImageCapModal(true)}
                  >
                    {strings.formatString(
                      changeMobileVerificationPage.Resend_code
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="text-center py-2 d-flex w-100 justify-content-evenly">
              <button
                style={{ opacity: 0.5 }}
                className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                onClick={onShowModalHandler}
              >
                <span className="sign-in-label aniBtn">
                  {strings.formatString(buttonlist.cancelBtn)}
                </span>
              </button>
              {phoneCodeValid === true ? (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              ) : (
                <button
                  disabled
                  className="btn btn-custom-success my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ChangeEmailForm = ({
  phoneValid,
  textChangeHandler,
  email,
  nextStepHandler,
  emailValid,
  confirmEmail,
}) => {
  const content = translations.changeMobileForm;
  const buttonlist = translations.buttonlist;
  const changeEmailPage = translations.changeEmailPage; // Usage: strings.formatString(changeEmailPage.change_Email_Address)
  const navigate = useNavigate();
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "0" }}>
        <div>
          <div className="my-2 mt-3 text-center">
            <span className="ff-Kardust fs-25">
              {strings.formatString(changeEmailPage.change_Email_Address)}
            </span>
          </div>
          <div className="divider mb-3 mt-3 w-100 mx-auto"></div>
          <div className="text-center">
            {/* <span className="kardust-light-font" style={{ fontSize: '18px' }}>{strings.formatString(content.labelTitle)}</span> */}
            <span className="kardust-light-font" style={{ fontSize: "18px" }}>
              {strings.formatString(
                changeEmailPage.Register_your_new_email_address
              )}
            </span>
          </div>
          {
            <div className="py-3 min-height-40">
              <div className="py-3">
                <input
                  className="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) => textChangeHandler("email", e.target.value)}
                  value={email}
                  // placeholder={strings.formatString(content.paceholderEmail)}
                  placeholder={strings.formatString(
                    changeEmailPage.new_email_address
                  )}
                />
              </div>
              <div className="py-3">
                <input
                  className="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("confirm-email", e.target.value)
                  }
                  value={confirmEmail}
                  // placeholder={strings.formatString(content.paceholderEmail)}
                  placeholder={strings.formatString(
                    changeEmailPage.confirm_email_address
                  )}
                />
              </div>
            </div>
          }

          <div className="text-center py-4 d-flex w-100 justify-content-evenly">
            <button
              style={{ opacity: 0.5 }}
              onClick={() => navigate("/account_detail")}
              className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
            >
              {strings.formatString(buttonlist.backBtn)}
            </button>
            {emailValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn btn-custom-active my-3 py-1 col-5"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-custom-success my-3 py-1 col-5"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChangeEmailVerifyForm = ({
  setIdmsRegistrationStep,
  textChangeHandler,
  smsVerificationCode,
  nextStepHandler,
  emailCodeValid,
  emailVerificationCode,
  onShowModalHandler,
}) => {
  const [showImageCapModal, setShowImageCapModal] = useState();
  const content = translations.mobileVerifyForm;
  const buttonlist = translations.buttonlist;
  const changeEmailVerificationPage = translations.changeEmailVerificationPage; // Usage: strings.formatString(changeEmailVerificationPage.change_Email_Address)
  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  const pinRef = useRef();
  return (
    <>
      {
        <BottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          emailVerify
          label={strings.formatString(
            changeEmailVerificationPage.Have_not_received_any_verification_Email
          )}
        />
      }
      <div className="w-100 auth-form-container">
        <div style={{ padding: "0" }}>
          <div>
            <div className="my-2 mt-3 text-center">
              {/* <span>{strings.formatString(content.mainTitle)}</span> */}
              <span>
                {strings.formatString(
                  changeEmailVerificationPage.change_Email_Address
                )}
              </span>
            </div>
            <div className="divider mb-3 mt-3 w-100 mx-auto"></div>
            <div className="pb-1 pt-2 text-center">
              {/* <span>{strings.formatString(content.labelTitle)}</span> */}
              <span>
                {strings.formatString(
                  changeEmailVerificationPage.Verification_Code
                )}
              </span>
            </div>
            <div className="pb-3 min-height-40">
              <div className="py-3 pt-0 text-center">
                <PinInput
                  focus
                  length={6}
                  initialValue={emailVerificationCode}
                  secret
                  onChange={(e) => {
                    textChangeHandler("verify-email-code", e);
                  }}
                  type="password"
                  inputMode="numeric"
                  style={{ padding: "10px" }}
                  inputStyle={{
                    borderColor: "#FFF",
                    margin: "0 5px",
                    color: "#FFF",
                    width: "33px",
                    borderRadius: "10px",
                  }}
                  inputFocusStyle={{ borderColor: "#FFF" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "18px" }}
                >
                  {/* {strings.formatString(content.lableNotify)} */}
                  {strings.formatString(
                    changeEmailVerificationPage.Enter_the_verification_code_sent_to_your_new_email_address
                  )}
                </p>
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "14px" }}
                >
                  {/* {strings.formatString(content.labelNotify2)} */}
                  {strings.formatString(
                    changeEmailVerificationPage.Have_not_received_any_verification_Email
                  )}
                </p>
                <div className="text-center">
                  <span
                    className="kardust-light-font anikana-label"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowImageCapModal(true)}
                  >
                    {strings.formatString(
                      changeEmailVerificationPage.Resend_code
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="text-center py-2 d-flex w-100 justify-content-evenly">
              <button
                style={{ opacity: 0.5 }}
                className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                onClick={onShowModalHandler}
              >
                <span className="sign-in-label aniBtn">
                  {strings.formatString(buttonlist.cancelBtn)}
                </span>
              </button>
              {emailCodeValid === true ? (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              ) : (
                <button
                  disabled
                  className="btn btn-custom-success my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PasswordResetForm = ({
  emailValidated,
  phoneValid,
  onEmailVerifyHandler,
  textChangeHandler,
  phoneNumber,
  email,
}) => {
  const content = translations.passwordResetForm;
  const buttonlist = translations.buttonlist;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div>
          <h3 className="auth-form-label">
            {strings.formatString(content.mainTitle)}
          </h3>
          {
            <div className="py-3 min-height-40">
              <div className="py-3">
                <PhoneInput
                  placeholder={strings.formatString(content.placeholderPhone)}
                  inputClass="auth-form-input-absolute p-2 ps-3 w-100"
                  value={phoneNumber}
                  onChange={(value) => textChangeHandler("phone", value)}
                />
              </div>
              <div className="py-3">
                <input
                  className="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) => textChangeHandler("email", e.target.value)}
                  value={email}
                  placeholder={strings.formatString(content.paceholderEmail)}
                />
              </div>
              <div className="py-3">
                <p className="text-justify kardust-light-font">
                  {strings.formatString(content.notifyParagraph)}
                </p>
              </div>
            </div>
          }

          <div className="text-center py-4">
            {emailValidated === true && phoneValid === true ? (
              <button
                onClick={onEmailVerifyHandler}
                className="btn btn-custom-active my-3 w-100 py-1"
              >
                {strings.formatString(buttonlist.sendCodeBtn)}
              </button>
            ) : (
              <button className="btn btn-custom-success my-3 w-100 py-1">
                {strings.formatString(buttonlist.sendSmsBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeviasPasswordResetForm = ({
  emailValidated,
  phoneValid,
  onEmailVerifyHandler,
  textChangeHandler,
  phoneNumber,
  email,
  leviasLayout,
}) => {
  useEffect(() => {
    leviasLayout();
  }, []);

  const isJP = translations._language === "jp";
  const passwordResetPage = translations.passwordResetPage;
  return (
    <div className="w-100">
      <div>
        <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
          {strings.formatString(passwordResetPage.Password_Reset)}
        </h3>
        {
          <div className="py-5 min-height-40">
            <div className="py-3">
              <input
                className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                onChange={(e) => textChangeHandler("email", e.target.value)}
                value={email}
                placeholder={strings.formatString(passwordResetPage.email)}
              />
            </div>
            <div className="py-3">
              <PhoneInput
                placeholder={strings.formatString(
                  passwordResetPage.mobile_number
                )}
                inputClass="levias-auth-form-input-absolute p-2 ps-3 w-100"
                value={phoneNumber}
                onChange={(value) => textChangeHandler("phone", value)}
              />
            </div>
            <div className="py-3">
              <p className="text-justify levias-kardust-light">
                {strings.formatString(
                  passwordResetPage.Please_enter_you_registered_email_address_and_phone_number_An_SMS_will_be_sent_with_the_verification_number
                )}
              </p>
            </div>
          </div>
        }

        <div className="text-center">
          {emailValidated === true && phoneValid === true ? (
            <button
              onClick={onEmailVerifyHandler}
              className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
            >
              {strings.formatString(passwordResetPage.SEND_SMS)}
            </button>
          ) : (
            <button
              disabled
              className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
            >
              {strings.formatString(passwordResetPage.SEND_SMS)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const SetPasswordResetForm = ({
  emailValidated,
  setIdmsRegistrationStep,
  showPassword,
  showConfirmPassword,
  onShowHandler,
  passwordValid,
  nextStepHandler,
  confirmPassword,
  password,
  textChangeHandler,
  loading,
}) => {
  const changePassword = translations.changePassword;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        {loading === true ? (
          <OnLoading />
        ) : (
          <div>
            <h3 className="auth-form-label">Set Password</h3>
            <div className="py-3 min-height-40">
              <div className="py-2">
                <div className="password-box">
                  <input
                    className="auth-form-input-absolute p-2 ps-3 w-100"
                    onChange={(e) =>
                      textChangeHandler("password", e.target.value)
                    }
                    value={password}
                    placeholder={strings.formatString(
                      changePassword.labelNewPassword
                    )}
                    // onBlur={emailValidator}
                    type={showPassword === true ? "text" : "password"}
                  />
                  {showPassword === true ? (
                    <HidePasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <ShowPasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>
              </div>
              <div className="py-4">
                <div className="password-box">
                  <input
                    className="auth-form-input-absolute p-2 ps-3 w-100"
                    onChange={(e) =>
                      textChangeHandler("confirm-password", e.target.value)
                    }
                    value={confirmPassword}
                    placeholder={strings.formatString(
                      changePassword.labelConfirmPassword
                    )}
                    // onBlur={emailValidator}
                    type={showConfirmPassword === true ? "text" : "password"}
                  />
                  {showConfirmPassword === true ? (
                    <HidePasswordIcon
                      type="confirm-password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <ShowPasswordIcon
                      type="confirm-password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="text-center">
              {passwordValid === true ? (
                <button
                  // onClick={onLoginHandler}
                  // onClick={() => setEmailValidated(true)}
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 w-100 py-1"
                >
                  set password
                </button>
              ) : (
                <button
                  // onClick={onLoginHandler}
                  // onClick={() => setEmailValidated(true)}
                  onClick={nextStepHandler}
                  className="btn btn-custom-success my-3 w-100 py-1"
                >
                  set password
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const LeviasSetPasswordResetForm = ({
  emailValidated,
  setIdmsRegistrationStep,
  showPassword,
  showConfirmPassword,
  onShowHandler,
  passwordValid,
  nextStepHandler,
  confirmPassword,
  password,
  textChangeHandler,
  loading,
  leviasLayout,
}) => {
  useEffect(() => {
    leviasLayout();
  }, []);

  const isJP = translations._language === "jp";
  const passwordResetFormPage = translations.passwordResetFormPage;
  return (
    <div className="w-100 auth-form-container">
      {loading === true ? (
        <LeviasOnLoading />
      ) : (
        <div>
          <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
            {strings.formatString(passwordResetFormPage.Password_reset)}
          </h3>
          <div className="py-5 min-height-40">
            <div className="py-4">
              <div className="password-box">
                <input
                  className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("password", e.target.value)
                  }
                  value={password}
                  placeholder={strings.formatString(
                    passwordResetFormPage.new_password
                  )}
                  type={showPassword === true ? "text" : "password"}
                />
                {showPassword === true ? (
                  <LeviasHidePasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <LeviasShowPasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
            </div>
            <div className="py-4">
              <div className="password-box">
                <input
                  className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("confirm-password", e.target.value)
                  }
                  value={confirmPassword}
                  placeholder={strings.formatString(
                    passwordResetFormPage.confirm_new_password
                  )}
                  type={showConfirmPassword === true ? "text" : "password"}
                />
                {showConfirmPassword === true ? (
                  <LeviasHidePasswordIcon
                    type="confirm-password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <LeviasShowPasswordIcon
                    type="confirm-password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
            </div>

            <div className="py-4">
              <p className="text-justify levias-kardust-light">
                {strings.formatString(
                  passwordResetFormPage.Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            {passwordValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {strings.formatString(passwordResetFormPage.CONFIRM)}
              </button>
            ) : (
              <button
                disabled
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {strings.formatString(passwordResetFormPage.CONFIRM)}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const ForgotEmailForm = ({
  textChangeHandler,
  phoneNumber,
  nextStepHandler,
  phoneValid,
  password,
  showPassword,
  onShowHandler,
  passwordValid,
  loading,
}) => {
  const content = translations.mobileVerificationForm;
  const loginPage = translations.loginPage;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        {loading === true ? (
          <OnLoading />
        ) : (
          <div>
            <h3 className="auth-form-label">{content.mainTitle}</h3>

            <div className="py-3 min-height-40">
              <div className="py-3">
                <PhoneInput
                  inputClass="auth-form-input-absolute p-2 ps-3 w-100"
                  placeholder={content.placeholderPhone}
                  value={phoneNumber}
                  name="phone"
                  onChange={(e) => {
                    textChangeHandler("phone", e);
                  }}
                />
                <div className="py-3">
                  <div className="password-box">
                    <input
                      className="auth-form-input-absolute p-2 ps-3 w-100"
                      onChange={(e) =>
                        textChangeHandler("password", e.target.value)
                      }
                      value={password}
                      placeholder={strings.formatString(loginPage.password)}
                      // onBlur={emailValidator}
                      type={showPassword === true ? "text" : "password"}
                    />
                    {showPassword === true ? (
                      <HidePasswordIcon
                        type="password"
                        onShowHandler={onShowHandler}
                        className="show-hide-password-icon"
                      />
                    ) : (
                      <ShowPasswordIcon
                        type="password"
                        onShowHandler={onShowHandler}
                        className="show-hide-password-icon show-password-icon"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="py-3">
                <p className="text-justify kardust-light-font">
                  {content.notifyParagraph}
                </p>
              </div>
            </div>
            <div className="text-center">
              {phoneValid === true && passwordValid === true ? (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 mb-2 w-100 py-1"
                >
                  {content.sendSmsBtn}
                </button>
              ) : (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-success my-3 mb-2 w-100 py-1"
                >
                  {content.sendSmsBtn}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const LeviasForgotEmailForm = ({
  textChangeHandler,
  phoneNumber,
  nextStepHandler,
  phoneValid,
  password,
  showPassword,
  onShowHandler,
  passwordValid,
  loading,
  leviasLayout,
}) => {
  useEffect(() => {
    leviasLayout();
  }, []);

  const isJP = translations._language === "jp";
  const forgotEmailPage = translations.forgotEmailPage;
  return (
    <div className="w-100 auth-form-container">
      {loading === true ? (
        <LeviasOnLoading />
      ) : (
        <div>
          <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
            {strings.formatString(forgotEmailPage.Forgot_Email)}
          </h3>

          <div className="py-5 min-height-40">
            <div className="py-3">
              <PhoneInput
                inputClass="levias-auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  forgotEmailPage.mobile_number
                )}
                value={phoneNumber}
                name="phone"
                onChange={(e) => {
                  textChangeHandler("phone", e);
                }}
              />
              <div className="py-3">
                <div className="password-box">
                  <input
                    className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                    onChange={(e) =>
                      textChangeHandler("password", e.target.value)
                    }
                    value={password}
                    placeholder={strings.formatString(forgotEmailPage.password)}
                    type={showPassword === true ? "text" : "password"}
                  />
                  {showPassword === true ? (
                    <LeviasHidePasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <LeviasShowPasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="py-4">
              <p className="text-justify levias-kardust-light">
                {strings.formatString(
                  forgotEmailPage.Please_enter_you_registered_phone_number_and_password_An_SMS_will_be_sent_with_with_your_registered_email_address
                )}
              </p>
            </div>
          </div>
          <div className="text-center">
            {phoneValid === true && passwordValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {strings.formatString(forgotEmailPage.SEND_SMS)}
              </button>
            ) : (
              <button
                disabled
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {strings.formatString(forgotEmailPage.SEND_SMS)}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const AuthForm = memo(
  ({
    loginHandler,
    memberRegisterHandler,
    idmsRegistrationHandler,
    language,
  }) => {
    const loginPage = translations.loginPage;
    return (
      <div className="form-container d-flex flex-column col-12">
        <div className="col-12 bg-video-container">
          <video
            loop
            autoPlay
            muted
            mute="true"
            id="myVideo"
            className="my-video"
          >
            <source
              src="./Anicana_FirstView_noOverlay_0723.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>
        <div className="col-6 form-img-background d-none d-lg-block"></div>
        <div className="col-12 form-right-col mx-auto p-3 py-2">
          <button
            onClick={memberRegisterHandler}
            className="my-3 sign-up-button form-wrapper w-100 py-1"
          >
            <span className="text-dark aniBtn">
              {/* {language === "English" ? "Sign Up" : "IDMS でログイン"} */}
              Sign Up
            </span>
          </button>
          <button
            onClick={loginHandler}
            className="create-account-button form-wrapper w-100 py-1 mx-auto"
          >
            {/* {language === "English" ? (
              <span className="sign-in-label aniBtn">Sign in</span>
            ) : (
              "新規会員登録"
            )} */}
            <span className="sign-in-label aniBtn">
              {strings.formatString(loginPage.Sign_in_text)}
            </span>
          </button>
        </div>
      </div>
    );
  },
  []
);

export const LoginForm = ({
  onLoginHandler,
  forgetPasswordHandler,
  textChangeHandler,
  email,
  password,
  idmsRegistrationHandler,
  language,
  emailValidator,
  emailValidated,
  formValid,
  showPassword,
  nextStepHandler,
  onShowHandler,
  loading,
}) => {
  const navigate = useNavigate();
  const loginPage = translations.loginPage;

  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        {loading ? (
          <OnLoading />
        ) : (
          <div>
            <h3 className="auth-form-label">Sign in</h3>
            <div className="py-3 min-height-40">
              <div className="py-3">
                <input
                  className="auth-form-input-absolute p-2 ps-3 w-100 text-light"
                  onChange={(e) => textChangeHandler("email", e.target.value)}
                  value={email}
                  placeholder={strings.formatString(loginPage.email)}
                  onBlur={emailValidator}
                />
                <div
                  onClick={() => navigate("/change-email")}
                  className="text-end"
                >
                  <span className="mx-2 kardust-light-font">forgot email</span>
                </div>
              </div>
              <div className="py-3">
                <div className="password-box">
                  <input
                    className="auth-form-input-absolute p-2 ps-3 w-100"
                    onChange={(e) =>
                      textChangeHandler("password", e.target.value)
                    }
                    value={password}
                    placeholder={strings.formatString(loginPage.password)}
                    // onBlur={emailValidator}
                    type={showPassword === true ? "text" : "password"}
                  />
                  {showPassword === true ? (
                    <HidePasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon"
                    />
                  ) : (
                    <ShowPasswordIcon
                      type="password"
                      onShowHandler={onShowHandler}
                      className="show-hide-password-icon show-password-icon"
                    />
                  )}
                </div>
                <div
                  onClick={() => navigate("/password-reset")}
                  className="text-end"
                >
                  <span className="mx-2 kardust-light-font">
                    forgot password
                  </span>
                </div>
              </div>

              <div className="text-end col-8 col-lg-5">
                <span
                  onClick={forgetPasswordHandler}
                  className="login-form-forget-password align-self-end py-1"
                ></span>
              </div>
            </div>

            <div className="text-center py-4">
              {formValid === true ? (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 w-100 py-1 col-5"
                >
                  Sign in
                </button>
              ) : (
                <button
                  disabled
                  className="btn btn-custom-success my-3 w-100 py-1 col-5"
                >
                  Sign in
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const LeviasLoginForm = ({
  onLoginHandler,
  forgetPasswordHandler,
  textChangeHandler,
  email,
  password,
  idmsRegistrationHandler,
  emailValidator,
  emailValidated,
  formValid,
  showPassword,
  nextStepHandler,
  onShowHandler,
  memberRegisterHandler,
  loading,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, [language]);
  const navigate = useNavigate();
  const isJP = translations._language === "jp";
  const loginPage = translations.loginPage;
  console.log(isJP);
  return (
    <div className="w-100 auth-form-container">
      {loading ? (
        <LeviasOnLoading />
      ) : (
        <div>
          <h3
            className={`levias-auth-form-label m-t-lg-80 mt-3 ${
              isJP ? "fs-30" : ""
            }`}
          >
            {strings.formatString(loginPage.Sign_in_text)}
          </h3>
          <div className="py-3">
            <div className="py-4">
              <input
                className="levias-auth-form-input-absolute p-2 ps-3 w-100 text-light"
                onChange={(e) => textChangeHandler("email", e.target.value)}
                value={email}
                placeholder={strings.formatString(loginPage.email)}
                onBlur={emailValidator}
              />
              <div className="text-end">
                <span
                  onClick={() => navigate("/change-email")}
                  className="levias-kardust-light-font"
                >
                  {strings.formatString(loginPage.forgot_email)}
                </span>
              </div>
            </div>
            <div className="py-4">
              <div className="password-box">
                <input
                  className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("password", e.target.value)
                  }
                  value={password}
                  placeholder={strings.formatString(loginPage.password)}
                  type={showPassword === true ? "text" : "password"}
                />
                {showPassword === true ? (
                  <LeviasHidePasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <LeviasShowPasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
              <div className="text-end">
                <span
                  onClick={() => navigate("/password-reset")}
                  className="levias-kardust-light-font"
                >
                  {strings.formatString(loginPage.forgot_password)}
                </span>
              </div>
            </div>

            <div className="text-end col-8 col-lg-5">
              <span
                onClick={forgetPasswordHandler}
                className="login-form-forget-password align-self-end py-1"
              ></span>
            </div>
          </div>

          <div className="text-center">
            {formValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {strings.formatString(loginPage.Sign_in_btn)}
              </button>
            ) : (
              <button
                disabled
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {strings.formatString(loginPage.Sign_in_btn)}
              </button>
            )}
          </div>

          <div className="df-center mt-lg-3 mt-sm-2">
            <div className="levias-create-account">
              <span className="text-start levias-create-account-label">
                {strings.formatString(loginPage.Do_not_have_LEVIAS_ID_yet)}
              </span>
              <button
                onClick={memberRegisterHandler}
                className="levias-btn levias-create-account-btn levias-btn-custom-active w-100 py-1 col-5"
              >
                {strings.formatString(loginPage.Create_account)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const LoginTwoFactorVerifyForm = ({
  setIdmsRegistrationStep,
  textChangeHandler,
  emailCodeValid,
  nextStepHandler,
  onShowModalHandler,
  loading,
}) => {
  const [showImageCapModal, setShowImageCapModal] = useState();
  const language = useSelector((state) => state.language.language);

  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  const pinRef = useRef();
  return (
    <>
      {
        <BottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          emailVerify
          label="Haven not received verification code?"
        />
      }
      <div className="w-100 auth-form-container">
        <div style={{ padding: "25px 0" }}>
          {loading ? (
            <OnLoading />
          ) : (
            <div>
              <h3 className="auth-form-label">
                {language === "English" ? "verification code" : "検証コード"}
              </h3>

              <div className="py-3 min-height-40">
                <div className="py-3 text-center">
                  <PinInput
                    focus
                    length={6}
                    initialValue={""}
                    secret
                    onChange={(e) => {
                      textChangeHandler("verify-email-code", e);
                    }}
                    type="password"
                    inputMode="numeric"
                    style={{ padding: "10px" }}
                    inputStyle={{
                      borderColor: "#FFF",
                      margin: "0 5px",
                      color: "#FFF",
                      width: "33px",
                      borderRadius: "10px",
                    }}
                    inputFocusStyle={{ borderColor: "#FFF" }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </div>
                <div className="py-2">
                  <p
                    className="text-center kardust-light-font"
                    style={{ fontSize: "18px" }}
                  >
                    {language === "English"
                      ? `Enter the verification code sent to your registered email.`
                      : `ご登録のメールアドレスに送信された認証コードを入力してください`}
                  </p>
                </div>
                <div className="py-2">
                  <p
                    className="text-center kardust-light-font"
                    style={{ fontSize: "14px" }}
                  >
                    {language === "English"
                      ? `Have not received any verification code?`
                      : `認証コードは届いてないですか??`}
                  </p>
                  <div className="text-center">
                    <span
                      className="kardust-light-font anikana-label"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowImageCapModal(true)}
                    >
                      {language === "English"
                        ? `Resend code`
                        : `コードを再送する`}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-center py-2 d-flex w-100 justify-content-evenly">
                <button
                  style={{ opacity: 0.5 }}
                  className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                  onClick={onShowModalHandler}
                >
                  <span className="sign-in-label aniBtn">cancel</span>
                </button>
                {emailCodeValid === true ? (
                  <button
                    onClick={nextStepHandler}
                    className="btn btn-custom-active my-3 py-1 col-5"
                  >
                    confirm
                  </button>
                ) : (
                  <button
                    disabled
                    className="btn btn-custom-success my-3 py-1 col-5"
                  >
                    confirm
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const LeviasLoginTwoFactorVerifyForm = ({
  setIdmsRegistrationStep,
  textChangeHandler,
  emailCodeValid,
  nextStepHandler,
  onShowModalHandler,
  loading,
  email,
}) => {
  const isJP = translations._language === "jp";
  const [showImageCapModal, setShowImageCapModal] = useState();
  const enterVerificationCodePage = translations.enterVerificationCodePage;
  const params = {
    email,
  };
  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  return (
    <>
      {
        <LeviasBottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          emailVerify
          label={strings.formatString(
            enterVerificationCodePage.Have_not_received_any_verification_email
          )}
          params={params}
        />
      }
      <div className="w-100 auth-form-container">
        {loading ? (
          <LeviasOnLoading />
        ) : (
          <div>
            <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
              {strings.formatString(
                enterVerificationCodePage.verification_code
              )}
            </h3>

            <div className="py-3 min-height-40">
              <div className="py-3 text-center">
                <PinInput
                  focus
                  length={6}
                  initialValue={""}
                  secret
                  onChange={(e) => {
                    textChangeHandler("verify-email-code", e);
                  }}
                  type="password"
                  inputMode="numeric"
                  style={{ padding: "10px" }}
                  inputStyle={{
                    borderColor: "#FFF",
                    margin: "0 5px",
                    color: "#FFF",
                    width: "33px",
                    borderRadius: "10px",
                  }}
                  inputFocusStyle={{ borderColor: "blue" }}
                  onComplete={nextStepHandler}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="py-4">
                <p
                  className="text-justify levias-kardust-light"
                  style={{ fontSize: "18px" }}
                >
                  {strings.formatString(
                    enterVerificationCodePage.Enter_the_verification_code_sent_to_your_registered_email_address
                  )}
                </p>
              </div>
              <div className="py-4">
                <p
                  className="text-justify levias-kardust-light"
                  style={{ fontSize: "14px" }}
                >
                  {strings.formatString(
                    enterVerificationCodePage.Have_not_received_any_verification_email
                  )}
                </p>
                <div className="text-center">
                  <span
                    className="levias-kardust-light fs-18 levias-green"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowImageCapModal(true)}
                  >
                    {strings.formatString(
                      enterVerificationCodePage.Resend_code
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="text-center py-2 d-flex w-100 justify-content-evenly">
                {emailCodeValid === true ? (
                  <button
                    onClick={nextStepHandler}
                    className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
                  >
                    confirm
                  </button>
                ) : (
                  <button
                    disabled
                    className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
                  >
                    confirm
                  </button>
                )}
              </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export const MemberRegistrationStep = ({
  selectMemberHandler,
  completeWeb3Auth,
  completeIdms,
  setCompleteWeb3Auth,
  language,
  setCompleteIdms,
}) => {
  return (
    <div className="d-flex flex-column justify-content-md-evenly align-items-center pb-3 h-90">
      <div className="member-register-label mx-2 my-4">
        <span>会員登録ステップ</span>
      </div>
      <div className="d-flex flex-md-row flex-column">
        <div className="member-register-container">
          <div className="member-register-option">1</div>
          <div className="member-register-body">
            <span className="member-register-option-label">
              {language === "English" ? "Register with IDMS" : "IDMSに登録する"}
            </span>
            {completeIdms === true ? (
              <button
                className={"member-register-option-button button-default"}
              >
                {language === "English" ? "completed" : "完了"}
              </button>
            ) : (
              <button
                onClick={() => selectMemberHandler("idms")}
                className={"member-register-option-button"}
              >
                {language === "English" ? "IDMS registration" : "IDMS登録"}
              </button>
            )}
          </div>
        </div>
        <div className="member-register-container">
          <div className="member-register-option">2</div>
          <div className="member-register-body">
            <span className="member-register-option-label">
              {language === "English"
                ? "Register with MetaMask"
                : "MetaMaskに登録する"}
            </span>
            {completeWeb3Auth === true ? (
              <button
                onClick={selectMemberHandler}
                className="member-register-option-button button-default"
                disabled
              >
                {language === "English" ? "completed" : "完了"}
              </button>
            ) : (
              <W3authLoginButton
                setCompleteIdms={setCompleteIdms}
                completeIdms={completeIdms}
                setCompleteWeb3Auth={setCompleteWeb3Auth}
              />
            )}
          </div>
          <div className="meta-mask-method mx-auto">
            <span>
              {language === "English"
                ? "Metamask registration method"
                : "Metamask登録方法"}
            </span>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-3">
        <img style={{ height: "25px" }} src={ANICANA} />
      </div>
    </div>
  );
};

export const IDMSRegistrationForm = ({
  currentStep,
  completeStep,
  nextStepHandler,
  textChangeHandler,
  confirmEmail,
  email,
  emailVerificationCode,
  phoneNumber,
  smsVerificationCode,
  accountName,
  confirmedAccName,
  password,
  confirmPassword,
  language,
  setPhoneNumber,
  emailValidator,
  emailValidated,
  confirmEmailValidated,
  loading,
  checkedPolicy,
  checkedTerm,
  checkedCookie,
  onCheckHandler,
  setIdmsRegistrationStep,
  onAcceptHandler,
  onAcceptPolicy,
  showConfirmPassword,
  showPassword,
  onShowHandler,
  accountNameValid,
  onShowModalHandler,
  onRegisterEmailHandler,
  emailCodeValid,
  phoneCodeValid,
  checkedvalid,
  setImageFile,
  phoneValid,
  confirmPhoneNumber,
  passwordValid,
  leviasLayout,
  transactionId,
  tempToken,
}) => {
  const stepForm = useMemo(() => {
    if (currentStep === "signUpForm") {
      return (
        <LeviasSignUpForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          checkedPolicy={checkedPolicy}
          checkedTerm={checkedTerm}
          onCheckHandler={onCheckHandler}
          checkedvalid={checkedvalid}
          leviasLayout={leviasLayout}
        />
      );
    } else if (currentStep === "term") {
      return (
        <LeviasTermOfUse
          onAcceptHandler={onAcceptHandler}
          checkedTerm={checkedTerm}
          leviasLayout={leviasLayout}
        />
      );
    } else if (currentStep === "privacy") {
      return (
        <LeviasPolicy
          onAcceptPolicy={onAcceptPolicy}
          checkedPolicy={checkedPolicy}
          leviasLayout={leviasLayout}
        />
      );
    } else if (currentStep === "profileName") {
      return (
        <LeviasProfileNameForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          confirmedAccName={confirmedAccName}
          accountName={accountName}
          textChangeHandler={textChangeHandler}
          accountNameValid={accountNameValid}
          onShowModalHandler={onShowModalHandler}
          leviasLayout={leviasLayout}
          nextStepHandler={nextStepHandler}
          loading={loading}
        />
      );
    } else if (currentStep === "profileIcon") {
      return (
        <LeviasProfileIconForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onShowModalHandler={onShowModalHandler}
          setImageFile={setImageFile}
          leviasLayout={leviasLayout}
        />
      );
    } else if (currentStep === "setEmail") {
      return (
        <LeviasSetEmailForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          email={email}
          confirmEmail={confirmEmail}
          emailValidated={emailValidated}
          emailValidator={emailValidator}
          confirmEmailValidated={confirmEmailValidated}
          textChangeHandler={textChangeHandler}
          onShowModalHandler={onShowModalHandler}
          nextStepHandler={nextStepHandler}
          leviasLayout={leviasLayout}
          loading={loading}
        />
      );
    } else if (currentStep === "emailVerify") {
      return (
        <LeviasEmailVerifyForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onShowModalHandler={onShowModalHandler}
          nextStepHandler={nextStepHandler}
          emailCodeValid={emailCodeValid}
          textChangeHandler={textChangeHandler}
          emailVerificationCode={emailVerificationCode}
          // setIdmsRegistrationStep={setIdmsRegistrationStep}
          leviasLayout={leviasLayout}
          email={email}
        />
      );
    } else if (currentStep === "setPhone") {
      return (
        <LeviasSetTwoFactorForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onShowModalHandler={onShowModalHandler}
          nextStepHandler={nextStepHandler}
          textChangeHandler={textChangeHandler}
          phoneValid={phoneValid}
          confirmPhoneNumber={confirmPhoneNumber}
          leviasLayout={leviasLayout}
          loading={loading}
        />
      );
    } else if (currentStep === "phoneVerify") {
      return (
        <LeviasTwoFactorVerifyForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onShowModalHandler={onShowModalHandler}
          nextStepHandler={nextStepHandler}
          textChangeHandler={textChangeHandler}
          phoneCodeValid={phoneCodeValid}
          // setIdmsRegistrationStep={setIdmsRegistrationStep}
          leviasLayout={leviasLayout}
          loading
          transactionId={transactionId}
          tempToken={tempToken}
          phoneNumber={phoneNumber}
        />
      );
    } else if (currentStep === "setPassword") {
      return (
        <LeviasSetPasswordForm
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          showPassword={showPassword}
          showConfirmPassword={showConfirmPassword}
          onShowHandler={onShowHandler}
          onShowModalHandler={onShowModalHandler}
          nextStepHandler={nextStepHandler}
          passwordValid={passwordValid}
          password={password}
          confirmPassword={confirmPassword}
          textChangeHandler={textChangeHandler}
          leviasLayout={leviasLayout}
          loading={loading}
        />
      );
    } else if (currentStep === "connectWallet") {
      return (
        <ConnectWallet setIdmsRegistrationStep={setIdmsRegistrationStep} />
      );
    }
  }, [
    currentStep,
    confirmEmail,
    email,
    emailVerificationCode,
    phoneNumber,
    smsVerificationCode,
    accountName,
    confirmedAccName,
    password,
    confirmPassword,
    emailValidated,
    confirmEmailValidated,
    loading,
    onAcceptHandler,
    onAcceptPolicy,
    accountNameValid,
    checkedvalid,
    phoneValid,
    confirmPhoneNumber,
  ]);
  return <>{stepForm}</>;
};

export const SignUpForm = ({
  checkedPolicy,
  checkedTerm,
  onCheckHandler,
  setIdmsRegistrationStep,
  checkedvalid,
}) => {
  const language = useSelector((state) => state.language.language);
  const content = translations.registration[0];
  const contentsignupform = translations.signupForm;
  const button = translations.buttonlist;

  const navigate = useNavigate();
  return (
    <>
      <div className="pb-5">
        <h3 className="auth-form-label mt-3">
          {strings.formatString(content.title)}
        </h3>
        <div className="py-3 min-height-40">
          <div className="py-1 text-center">
            <span>{strings.formatString(contentsignupform.mainTitle)}</span>
            <div className="divider mb-5 mt-3 w-75 mx-auto"></div>
          </div>
          <p className="kardust-light-font text-center">
            {strings.formatString(contentsignupform.notifyParagraph)}
          </p>

          <div className="py-3 mx-4">
            <div className="d-flex align-items-center my-2">
              {checkedTerm === true ? (
                <CheckboxOnclickIcon
                  type="term"
                  className="mx-2"
                  onCheckHandler={onCheckHandler}
                />
              ) : (
                <CheckboxIcon
                  type="term"
                  onCheckHandler={onCheckHandler}
                  className="mx-2"
                />
              )}
              <span
                onClick={() => setIdmsRegistrationStep("term")}
                className="kardust-light-font term-policy"
              >
                {strings.formatString(contentsignupform.checkBoxTermofus)}
              </span>
            </div>
            <div className="d-flex align-items-center my-2">
              {checkedPolicy === true ? (
                <CheckboxOnclickIcon
                  type="policy"
                  onCheckHandler={onCheckHandler}
                  className="mx-2"
                />
              ) : (
                <CheckboxIcon
                  type="policy"
                  onCheckHandler={onCheckHandler}
                  className="mx-2"
                />
              )}
              <span
                onClick={() => setIdmsRegistrationStep("privacy")}
                className="kardust-light-font term-policy"
              >
                {strings.formatString(contentsignupform.checkBoxPrivacyPolicy)}
              </span>
            </div>
          </div>
        </div>

        <div className="text-center py-4">
          {checkedvalid === true ? (
            <button
              onClick={() => setIdmsRegistrationStep("profileName")}
              className="btn btn-custom-active my-3 py-1 w-100"
            >
              {/* {language==='English'?'next':'次'} */}
              {strings.formatString(button.nextBtn)}
            </button>
          ) : (
            <button disabled className="btn btn-custom-success my-3 py-1 w-100">
              {/* {language==='English'?'next':'次'} */}
              {strings.formatString(button.nextBtn)}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export const LeviasSignUpForm = ({
  checkedPolicy,
  checkedTerm,
  onCheckHandler,
  setIdmsRegistrationStep,
  checkedvalid,
  leviasLayout,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    leviasLayout();
    forceUpdate();
  }, [language]);

  const button = translations.buttonlist;
  const idIssuePage = translations.idIssuePage;
  const isJP = translations._language === "jp";
  return (
    <div className="w-100 auth-form-container">
      <h3 className={`levias-auth-form-label mt-3 ${isJP ? "fs-30" : ""}`}>
        {strings.formatString(idIssuePage.Create_your_LEVIAS_ID)}
      </h3>
      <div className="py-5 min-height-40">
        <p className="levias-kardust-light text-justify">
          {strings.formatString(
            idIssuePage.LEVIAS_ID_is_needed_for_using_the_services_of_ANICANA_θupa_Please_check_and_agree_to_the_Terms_of_Use_and_Privacy_Policy_of_LEVIAS_below
          )}
        </p>

        <div className="py-4 mx-4">
          <div className="d-flex align-items-center my-2">
            <div className="w-40px">
              {checkedTerm === true ? (
                <LeviasCheckboxOnclickIcon
                  type="term"
                  className="mx-2"
                  onCheckHandler={onCheckHandler}
                />
              ) : (
                <LeviasCheckboxIcon
                  type="term"
                  onCheckHandler={onCheckHandler}
                  className="mx-2"
                />
              )}
            </div>
            <Link
              target={"_blank"}
              to={`/LEVIAS-Account-Terms-of-Use.pdf`}
              className="kardust-light-font term-policy"
              style={{ color: "unset" }}
            >
              {strings.formatString(idIssuePage.Term_of_Use)}
            </Link>
          </div>
          <div className="d-flex align-items-center my-2">
            <div className="w-40px">
              {checkedPolicy === true ? (
                <LeviasCheckboxOnclickIcon
                  type="policy"
                  onCheckHandler={onCheckHandler}
                  className="mx-2"
                />
              ) : (
                <LeviasCheckboxIcon
                  type="policy"
                  onCheckHandler={onCheckHandler}
                  className="mx-2"
                />
              )}
            </div>
            <Link
              target={"_blank"}
              to={`/Levias-ID-Privacy-Policy.pdf`}
              className="kardust-light-font term-policy"
              style={{ color: "unset" }}
            >
              {strings.formatString(idIssuePage.Privacy_Policy)}
            </Link>
          </div>
        </div>
      </div>

      <div className="text-center">
        {checkedvalid === true ? (
          <button
            onClick={() => setIdmsRegistrationStep("profileName")}
            className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
          >
            {strings.formatString(button.leviasNextBtn)}
          </button>
        ) : (
          <button
            disabled
            className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
          >
            {strings.formatString(button.leviasNextBtn)}
          </button>
        )}
      </div>
    </div>
  );
};

export const TermOfUse = ({ onAcceptHandler, checkedTerm, disabledBtn }) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();
  const content = translations.termOfUse;
  const buttonlist = translations.buttonlist;
  function createMarkup(val) {
    return { __html: translations.formatString(val) };
  }
  useEffect(() => {
    forceUpdate();
  }, [language]);

  return (
    <div className="pt-3">
      <h3
        className="auth-form-label"
        id="term-title"
        dangerouslySetInnerHTML={createMarkup(
          translations.formatString(content.mainTitle)
        )}
      ></h3>
      <div className="pt-3 min-height-40">
        <div
          className="text-justify mb-0"
          id="termofuse"
          dangerouslySetInnerHTML={createMarkup(
            translations.formatString(content.notifyParagraph)
          )}
        />
      </div>
      <div className="text-center">
        {!disabledBtn &&
          (checkedTerm === true ? (
            <button
              onClick={onAcceptHandler}
              className="btn btn-custom-active my-3 py-1 w-100"
            >
              {/* {language==='English'?'accept':'受け入れる'} */}
              {translations.formatString(buttonlist.acceptBtn)}
            </button>
          ) : (
            <button
              onClick={onAcceptHandler}
              className="btn btn-see-details my-3 py-1 w-100"
            >
              {/* {language==='English'?'accept':'受け入れる'} */}
              {translations.formatString(buttonlist.acceptBtn)}
            </button>
          ))}
      </div>
    </div>
  );
};

export const CookiesPolicy = ({
  onAcceptHandler,
  checkedCookie,
  disabledBtn,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();
  const content = translations.cookiePolicy;
  const buttonlist = translations.buttonlist;
  function createMarkup(val) {
    return { __html: translations.formatString(val) };
  }
  useEffect(() => {
    forceUpdate();
  }, [language]);

  return (
    <>
      <div className="pb-5 pt-3">
        <h3
          className="auth-form-label"
          id="term-title"
          dangerouslySetInnerHTML={createMarkup(
            translations.formatString(content.mainTitle)
          )}
        ></h3>
        <div className="pt-3 min-height-40">
          <Linkify>
            <div
              className="text-justify mb-0"
              id="termofuse"
              dangerouslySetInnerHTML={{
                __html: translations.formatString(
                  content.notifyParagraph,
                  isMobile ? content.policyTableSP : content.policyTablePC
                ),
              }}
            />
          </Linkify>
        </div>
      </div>
    </>
  );
};

export const LeviasTermOfUse = ({
  onAcceptHandler,
  checkedTerm,
  disabledBtn,
  leviasLayout,
}) => {
  useEffect(() => {
    leviasLayout(true);
  }, []);

  const content = translations.termOfUse;
  const buttonlist = translations.buttonlist;
  function createMarkup() {
    return { __html: translations.formatString(content.notifyParagraph) };
  }
  return (
    <>
      <div className="w-100">
        <h3 className="levias-auth-form-label fs-25">
          {translations.formatString(content.mainTitle)}
        </h3>
        <div className="levias-pc-pt-1">
          <div
            className="levias-kardust-light text-justify fs-16 mb-0"
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>
        <div className="text-center">
          {!disabledBtn &&
            (checkedTerm === true ? (
              <button
                onClick={onAcceptHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {translations.formatString(buttonlist.acceptBtn)}
              </button>
            ) : (
              <button
                onClick={onAcceptHandler}
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {translations.formatString(buttonlist.acceptBtn)}
              </button>
            ))}
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: "1vh" }}></div>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export const Policy = ({ onAcceptPolicy, checkedPolicy, disabledBtn }) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();
  const content = translations.privacy;
  const buttonlist = translations.buttonlist;
  function createMarkup(val) {
    return { __html: val };
  }
  useEffect(() => {
    forceUpdate();
  }, [language]);

  return (
    <div className="pt-3">
      <h3
        className="auth-form-label"
        id="privacy-title"
        dangerouslySetInnerHTML={createMarkup(
          translations.formatString(content.mainTitle)
        )}
      ></h3>
      <div className="pt-3 min-height-40">
        <div
          className={`kardust-light-font text-justify mb-0  ${translations.formatString(
            content.langClass
          )}`}
          id="privacy"
          dangerouslySetInnerHTML={createMarkup(
            translations.formatString(content.notifyParagraph)
          )}
        />
      </div>
      <div className="text-center">
        {!disabledBtn &&
          (checkedPolicy === true ? (
            <button
              onClick={onAcceptPolicy}
              className="btn btn-custom-active my-3 py-1 w-100"
            >
              {/* {language==='English'?'accept':'受け入れる'} */}
              {translations.formatString(buttonlist.acceptBtn)}
            </button>
          ) : (
            <button
              onClick={onAcceptPolicy}
              className="btn btn-see-details my-3 py-1 w-100"
            >
              {/* {language==='English'?'accept':'受け入れる'} */}
              {translations.formatString(buttonlist.acceptBtn)}
            </button>
          ))}
      </div>
    </div>
  );
};

export const LeviasPolicy = ({
  onAcceptPolicy,
  checkedPolicy,
  disabledBtn,
  leviasLayout,
}) => {
  useEffect(() => {
    leviasLayout(true);
  }, []);

  const content = translations.privacy;
  const buttonlist = translations.buttonlist;
  function createMarkup() {
    return { __html: translations.formatString(content.notifyParagraph) };
  }
  return (
    <>
      <div className="w-100">
        <h3 className="levias-auth-form-label fs-25">
          {translations.formatString(content.mainTitle)}
        </h3>
        <div className="levias-pc-pt-1">
          <div
            className="levias-kardust-light text-justify fs-16 mb-0"
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>
        <div className="text-center">
          {!disabledBtn &&
            (checkedPolicy === true ? (
              <button
                onClick={onAcceptPolicy}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {translations.formatString(buttonlist.acceptBtn)}
              </button>
            ) : (
              <button
                onClick={onAcceptPolicy}
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {translations.formatString(buttonlist.acceptBtn)}
              </button>
            ))}
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: "1vh" }}></div>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export const MobileVerificationForm = () => {
  const language = useSelector((state) => state.language.language);
  const content = translations.mobileVerificationForm;
  const buttonlist = translations.buttonlist;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div className="pb-5">
          <h3 className="auth-form-label">
            {translations.formatString(content.mainTitle)}
          </h3>

          <div className="py-3 min-height-40">
            <div className="py-3">
              <input
                className="auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={translations.formatString(
                  content.placeholderPhone
                )}
              />
            </div>
            <div className="py-3">
              <p className="text-center kardust-light-font">
                {translations.formatString(content.notifyParagraph)}
              </p>
            </div>
          </div>

          <div className="text-center py-4">
            <button
              // onClick={onLoginHandler}
              className="auth-form-button my-3 w-100 py-1"
            >
              <span className="auth-form-button-label aniBtn">
                {translations.formatString(buttonlist.sendSmsBtn)}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileNameForm = ({
  setIdmsRegistrationStep,
  accountName,
  textChangeHandler,
  accountNameValid,
  onShowModalHandler,
}) => {
  const language = useSelector((state) => state.language.language);
  const content = translations.registration[1];
  const contentProfileNameForm = translations.profileNameForm;
  const buttonlist = translations.buttonlist;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div className="pb-5">
          <h3 className="auth-form-label">{content.title}</h3>

          <div className="py-3 min-height-40">
            <div className="py-3">
              <input
                className="auth-form-input-absolute border-box p-2 ps-3 w-100"
                placeholder={translations.formatString(
                  contentProfileNameForm.paceholderProfileName
                )}
                required
                value={accountName}
                onChange={(e) =>
                  textChangeHandler("account-name", e.target.value)
                }
              />
            </div>
            <div className="py-4">
              <p
                className="text-center kardust-light-font"
                style={{ whiteSpace: "pre-line" }}
              >
                {translations.formatString(
                  contentProfileNameForm.notifyParagraph
                )}
              </p>
            </div>
          </div>

          <div className="text-center py-4 d-flex w-100 justify-content-evenly">
            <button
              style={{ opacity: 0.5 }}
              onClick={onShowModalHandler}
              className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
            >
              {/* {language==='English'?'cancel':'キャンセル'} */}
              {translations.formatString(buttonlist.cancelBtn)}
            </button>
            {accountNameValid === true ? (
              <button
                onClick={() => setIdmsRegistrationStep("profileIcon")}
                className="btn btn-custom-active my-3 py-1 col-5"
              >
                {/* {language==='English'?'next':'次'} */}
                {translations.formatString(buttonlist.nextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-custom-success my-3 py-1 col-5"
              >
                {/* {language==='English'?'next':'次'} */}
                {translations.formatString(buttonlist.nextBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeviasProfileNameForm = ({
  setIdmsRegistrationStep,
  nextStepHandler,
  accountName,
  textChangeHandler,
  accountNameValid,
  onShowModalHandler,
  loading,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, [language]);

  const isJP = translations._language === "jp";
  const contentProfileNameForm = translations.profileNameForm;
  const buttonlist = translations.buttonlist;
  const userNamingPage = translations.userNamingPage;
  return (
    <div className="w-100 auth-form-container">
      {loading === true ? (
        <LeviasOnLoading />
      ) : (
        <div>
          <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
            {strings.formatString(userNamingPage.Profile_Name)}
          </h3>
          <div className="py-5 min-height-40">
            <div className="py-4">
              <input
                className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={translations.formatString(
                  contentProfileNameForm.paceholderProfileName
                )}
                required
                value={accountName}
                onChange={(e) =>
                  textChangeHandler("account-name", e.target.value)
                }
              />
            </div>
            <div className="py-4">
              <p
                className="text-justify levias-kardust-light"
                style={{ whiteSpace: "pre-line" }}
              >
                {strings.formatString(
                  userNamingPage.Set_your_profile_name_which_will_be_shown_to_the_public
                )}
                <br />
                {strings.formatString(
                  userNamingPage.Please_be_careful_when_entering_a_real_name_or_a_name_that_can_identify_a_specific_individual
                )}
                <br />
                {strings.formatString(
                  userNamingPage.You_can_edit_it_in_the_Profile_menu_later
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            {accountNameValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {translations.formatString(buttonlist.leviasNextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {translations.formatString(buttonlist.leviasNextBtn)}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const ProfileIconForm = ({
  setIdmsRegistrationStep,
  setImageFile,
  onShowModalHandler,
}) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const [takePhoto, setTakePhoto] = useState(false);

  const [showImageCapModal, setShowImageCapModal] = useState();

  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };

  const dispatch = useDispatch();

  const filePickerRef = useRef();
  const fileCaptureRef = useRef();
  const [dataUri, setDataUri] = useState("");

  function handleTakePhotoAnimationDone(dataUri) {
    setDataUri(dataUri);
    setImageFile(dataUri);
    setTakePhoto(false);
    setPreviewUrl(dataUri);
  }

  const isFullscreen = false;

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
      setImageFile(fileReader.result);
      dispatch(profileAction.add(fileReader.result));
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
  };

  const takePhotoHandler = () => {
    fileCaptureRef.current.click();
    // setTakePhoto(true)
    setShowImageCapModal(false);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
    setShowImageCapModal(false);
  };

  const language = useSelector((state) => state.language.language);
  const content = translations.registration[2];
  const contentProfileIcon = translations.profileIcon;
  const buttonlist = translations.buttonlist;
  return (
    <>
      {
        <BottomModal
          takePhotoHandler={takePhotoHandler}
          pickImageHandler={pickImageHandler}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          profileCapture
          label="Choose an image from your gallery or take a photo."
        />
      }
      {takePhoto ? (
        <div className="mt-4">
          <Camera
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            isFullscreen={isFullscreen}
          />
        </div>
      ) : (
        <div className="w-100 auth-form-container">
          <div style={{ padding: "25px 0" }}>
            <div className="pb-5">
              <h3 className="auth-form-label">{content.title}</h3>

              <div className="py-3 min-height-40">
                <div
                  className="text-center position-relative mx-auto"
                  style={{ width: "138px", height: "138px" }}
                >
                  <input
                    id="image"
                    ref={filePickerRef}
                    style={{ display: "none" }}
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={pickedHandler}
                  />
                  <input
                    id="take-image"
                    ref={fileCaptureRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={pickedHandler}
                  />
                  {previewUrl ? (
                    <>
                      <div
                        style={{
                          width: "138px",
                          height: "138px",
                          overflow: "hidden",
                        }}
                        className="rounded-circle d-flex justify-content-center"
                      >
                        <img
                          src={previewUrl}
                          style={{ height: "138px" }}
                          alt="Preview"
                        />
                      </div>
                      <CameraIcon
                        className="position-absolute"
                        pickImageHandler={() => setShowImageCapModal(true)}
                        idealResolution={{ width: 300, height: 300 }}
                      />
                    </>
                  ) : (
                    <>
                      <ProfileIcon />
                      <CameraIcon
                        className="position-absolute"
                        pickImageHandler={() => setShowImageCapModal(true)}
                      />
                    </>
                  )}
                </div>
                <div className="py-4">
                  <p
                    className="text-center kardust-light-font text-center"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {translations.formatString(
                      contentProfileIcon.notifyParagraph
                    )}
                  </p>
                </div>
              </div>

              <div className="text-center py-4 d-flex w-100 justify-content-evenly">
                <button
                  style={{ opacity: 0.5 }}
                  onClick={onShowModalHandler}
                  className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                >
                  {translations.formatString(buttonlist.cancelBtn)}
                </button>
                <button
                  onClick={() => setIdmsRegistrationStep("setEmail")}
                  className="btn btn-custom-active my-3 py-1 col-5"
                >
                  {translations.formatString(buttonlist.nextBtn)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const LeviasProfileIconForm = ({
  setIdmsRegistrationStep,
  setImageFile,
  onShowModalHandler,
  leviasLayout,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    leviasLayout();
    forceUpdate();
  }, [language]);

  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const [takePhoto, setTakePhoto] = useState(false);

  const [showImageCapModal, setShowImageCapModal] = useState();

  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };

  const dispatch = useDispatch();

  const filePickerRef = useRef();
  const fileCaptureRef = useRef();
  const [dataUri, setDataUri] = useState("");

  function handleTakePhotoAnimationDone(dataUri) {
    setDataUri(dataUri);
    setImageFile(dataUri);
    setTakePhoto(false);
    setPreviewUrl(dataUri);
  }

  const isFullscreen = false;

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
      setImageFile(fileReader.result);
      dispatch(profileAction.add(fileReader.result));
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
  };

  const takePhotoHandler = () => {
    fileCaptureRef.current.click();
    // setTakePhoto(true)
    setShowImageCapModal(false);
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
    setShowImageCapModal(false);
  };

  const isJP = translations._language === "jp";
  const buttonlist = translations.buttonlist;
  const setProfileIconPage = translations.setProfileIconPage;
  const leviasBottomModal = translations.leviasBottomModal;
  const isPh = useMediaQuery({ maxWidth: 576 });

  const swalShowImageCapModal = () => {
    LeviasSwalProfileIcon.fire({
      text: strings.formatString(setProfileIconPage.chooseImgModalText),
      confirmButtonText: strings.formatString(leviasBottomModal.GALLERY),
      // denyButtonText: strings.formatString(leviasBottomModal.TAKE_PHOTO),
    }).then((result) => {
      if (result.isConfirmed) {
        pickImageHandler();
      } /* else if (result.isDenied) {
        takePhotoHandler()
      } */
    });
  };

  const pickImageModalHandler = () => {
    isPh ? setShowImageCapModal(true) : swalShowImageCapModal();
  };

  return (
    <>
      {
        <LeviasBottomModal
          inputClass=""
          takePhotoHandler={takePhotoHandler}
          pickImageHandler={pickImageHandler}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          profileCapture
          label={strings.formatString(setProfileIconPage.chooseImgModalText)}
        />
      }
      {takePhoto ? (
        <div className="mt-4">
          <Camera
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            isFullscreen={isFullscreen}
          />
        </div>
      ) : (
        <div className="w-100 auth-form-container">
          <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
            {strings.formatString(setProfileIconPage.Profile_Icon)}
          </h3>

          <div className="py-5 min-height-40">
            <div
              className="text-center position-relative mx-auto"
              style={{ width: "138px", height: "138px" }}
            >
              <input
                id="image"
                ref={filePickerRef}
                style={{ display: "none" }}
                type="file"
                accept=".jpg,.png,.jpeg"
                onChange={pickedHandler}
              />
              <input
                id="take-image"
                ref={fileCaptureRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                capture="camera"
                onChange={pickedHandler}
              />
              {previewUrl ? (
                <>
                  <div
                    style={{
                      width: "138px",
                      height: "138px",
                      overflow: "hidden",
                    }}
                    className="rounded-circle d-flex justify-content-center"
                  >
                    <img
                      src={previewUrl}
                      style={{ height: "138px" }}
                      alt="Preview"
                    />
                  </div>
                  <LeviasCameraIcon
                    className="position-absolute"
                    pickImageHandler={pickImageModalHandler}
                    idealResolution={{ width: 300, height: 300 }}
                  />
                </>
              ) : (
                <>
                  <LeviasProfileIcon />
                  <LeviasCameraIcon
                    className="position-absolute"
                    pickImageHandler={pickImageModalHandler}
                  />
                </>
              )}
            </div>
            <div className="py-4">
              <p
                className="text-justify levias-kardust-light"
                style={{ whiteSpace: "pre-line" }}
              >
                {strings.formatString(
                  setProfileIconPage.Set_a_profile_image_If_you_do_not_want_to_set_it_now_you_can_do_it_later_in_the_Profile_menu
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            <button
              onClick={() => setIdmsRegistrationStep("setEmail")}
              className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
            >
              {translations.formatString(buttonlist.leviasNextBtn)}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export const SetEmailForm = ({
  setIdmsRegistrationStep,
  emailValidated,
  email,
  emailValidator,
  textChangeHandler,
  nextStepHandler,
  confirmEmail,
  confirmEmailValidated,
  onRegisterEmailHandler,
  onShowModalHandler,
}) => {
  const language = useSelector((state) => state.language.language);
  const emailAddressSettingsPage = translations.emailAddressSettingsPage;
  const buttonlist = translations.buttonlist;
  const changeEmailPage = translations.changeEmailPage;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div className="pb-5">
          <h3 className="auth-form-label">
            {strings.formatString(emailAddressSettingsPage.Set_Email_Address)}
          </h3>

          <div className="py-3 min-height-40">
            <div className="py-3">
              <input
                className="auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  changeEmailPage.new_email_address
                )}
                value={email}
                onChange={(e) => textChangeHandler("email", e.target.value)}
                // onBlur={() => emailValidator("email")}
              />
            </div>
            <div className="py-3">
              <input
                className="auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  changeEmailPage.confirm_email_address
                )}
                value={confirmEmail}
                onChange={(e) => {
                  textChangeHandler("confirm-email", e.target.value);
                }}
                // onBlur={() => emailValidator("con-email")}
              />
            </div>
            <div className="py-3">
              <p className="text-center kardust-light-font">
                {strings.formatString(
                  emailAddressSettingsPage.Enter_an_email_address_for_your_account_It_will_be_used_as_your_contact_address
                )}
              </p>
            </div>
          </div>

          <div className="text-center py-4 d-flex w-100 justify-content-evenly">
            <button
              style={{ opacity: 0.5 }}
              className="btn-custom-cancel col-5 my-3 mx-0 py-1"
              onClick={onShowModalHandler}
            >
              cancel
            </button>
            {emailValidated === true && confirmEmailValidated === true ? (
              <button
                onClick={nextStepHandler}
                className="btn btn-custom-active my-3 py-1 col-5"
              >
                {strings.formatString(
                  emailAddressSettingsPage.Enter_an_email_address_for_your_account_It_will_be_used_as_your_contact_address
                )}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-custom-success my-3 py-1 col-5"
              >
                {translations.formatString(buttonlist.nextBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeviasSetEmailForm = ({
  setIdmsRegistrationStep,
  emailValidated,
  email,
  emailValidator,
  textChangeHandler,
  nextStepHandler,
  confirmEmail,
  confirmEmailValidated,
  onRegisterEmailHandler,
  onShowModalHandler,
  leviasLayout,
  loading,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    leviasLayout();
    forceUpdate();
  }, [language]);

  const isJP = translations._language === "jp";
  const emailAddressSettingsPage = translations.emailAddressSettingsPage;
  const changeEmailPage = translations.changeEmailPage;
  const buttonlist = translations.buttonlist;

  return (
    <div className="w-100 auth-form-container">
      <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
        {strings.formatString(emailAddressSettingsPage.Set_Email_Address)}
      </h3>
      {loading === true ? (
        <LeviasOnLoading />
      ) : (
        <>
          <div className="py-5 min-height-40">
            <div className="py-4">
              <input
                className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  changeEmailPage.new_email_address
                )}
                value={email}
                onChange={(e) => textChangeHandler("email", e.target.value)}
              />
            </div>
            <div className="py-4">
              <input
                className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  changeEmailPage.confirm_email_address
                )}
                value={confirmEmail}
                onChange={(e) => {
                  textChangeHandler("confirm-email", e.target.value);
                }}
                // onBlur={() => emailValidator("con-email")}
              />
            </div>
            <div className="py-4">
              <p className="text-justify levias-kardust-light">
                {strings.formatString(
                  emailAddressSettingsPage.Enter_an_email_address_for_your_account_It_will_be_used_as_your_contact_address
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            {emailValidated === true && confirmEmailValidated === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {strings.formatString(buttonlist.leviasNextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {strings.formatString(buttonlist.leviasNextBtn)}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const EmailVerifyForm = ({
  setIdmsRegistrationStep,
  nextStepHandler,
  emailVerificationCode,
  textChangeHandler,
  emailCodeValid,
  onShowModalHandler,
}) => {
  const [showImageCapModal, setShowImageCapModal] = useState();

  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  const pinRef = useRef();
  const language = useSelector((state) => state.language.language);
  const enterVerificationCodePage = translations.enterVerificationCodePage;
  const buttonlist = translations.buttonlist;
  return (
    <>
      {
        <BottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          emailVerify
          label={strings.formatString(
            enterVerificationCodePage.Have_not_received_any_verification_email
          )}
        />
      }
      <div className="w-100 auth-form-container">
        <div style={{ padding: "25px 0" }}>
          <div className="pb-5">
            <h3 className="auth-form-label">
              {strings.formatString(
                enterVerificationCodePage.verification_code
              )}
            </h3>
            <div className="py-3 min-height-40">
              <div className="py-3 text-center">
                <PinInput
                  focus
                  length={6}
                  initialValue={emailVerificationCode}
                  secret
                  onChange={(e) => {
                    textChangeHandler("verify-email-code", e);
                  }}
                  type="password"
                  inputMode="numeric"
                  style={{ padding: "10px" }}
                  className="pin-input"
                  inputStyle={{
                    padding: "0px",
                    margin: "0px 5px",
                    textAlign: "center",
                    background: "transparent",
                    width: "35px",
                    height: "50px",
                    color: "rgb(255, 255, 255)",
                    border: "1px solid rgba(217, 217, 217, 0.5)",
                    borderRadius: "10px",
                  }}
                  inputFocusStyle={
                    {
                      // border: "1px solid",
                      // borderImageSource:
                      //   "linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%)",
                    }
                  }
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "18px" }}
                >
                  {strings.formatString(
                    enterVerificationCodePage.Enter_the_verification_code_sent_to_your_registered_email_address
                  )}
                </p>
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "14px" }}
                >
                  {strings.formatString(
                    enterVerificationCodePage.Have_not_received_any_verification_email
                  )}
                </p>
                <div className="text-center">
                  <span
                    className="kardust-light-font anikana-label"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowImageCapModal(true)}
                  >
                    {strings.formatString(
                      enterVerificationCodePage.Resend_code
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="text-center py-2 d-flex w-100 justify-content-evenly">
              <button
                style={{ opacity: 0.5 }}
                className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                onClick={onShowModalHandler}
              >
                cancel
              </button>
              {emailCodeValid === true ? (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              ) : (
                <button
                  disabled
                  className="btn btn-custom-success my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const LeviasEmailVerifyForm = ({
  setIdmsRegistrationStep,
  nextStepHandler,
  emailVerificationCode,
  textChangeHandler,
  emailCodeValid,
  onShowModalHandler,
  leviasLayout,
  email,
}) => {
  const { language } = useContext(appContext);
  const forceUpdate = useForceUpdate();
  const params = { email };
  useEffect(() => {
    leviasLayout();
    forceUpdate();
  }, [language]);

  const [showImageCapModal, setShowImageCapModal] = useState();

  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };

  const resendEmail = () => {
    var response = fetchData(
      `register/email`,
      {
        email: localStorage.getItem("email"),
        email_confirmation: localStorage.getItem("email"),
      },
      "post",
      true
    );
    if (response.ok) {
      setIdmsRegistrationStep("emailVerify");
    }
  };

  const isJP = translations._language === "jp";
  const enterVerificationCodePage = translations.enterVerificationCodePage;
  const buttonlist = translations.buttonlist;
  return (
    <>
      {
        <LeviasBottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          emailVerify
          isRegister
          label={strings.formatString(
            enterVerificationCodePage.Have_not_received_any_verification_email
          )}
          params={params}
        />
      }
      <div className="w-100 auth-form-container">
        <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
          {strings.formatString(enterVerificationCodePage.verification_code)}
        </h3>
        <div className="py-4 min-height-40">
          <div className="py-4 text-center">
            <PinInput
              focus
              length={6}
              initialValue={emailVerificationCode}
              secret
              onChange={(e) => {
                textChangeHandler("verify-email-code", e);
              }}
              type="password"
              inputMode="numeric"
              style={{ padding: "10px" }}
              className="pin-input"
              inputStyle={{
                padding: "0px",
                margin: "0px 5px",
                textAlign: "center",
                background: "transparent",
                width: "35px",
                height: "50px",
                color: "rgb(255, 255, 255)",
                border: "1px solid rgba(217, 217, 217, 0.5)",
                borderRadius: "10px",
              }}
              inputFocusStyle={
                {
                  // border: "1px solid",
                  // borderImageSource:
                  //   "linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%)",
                }
              }
              onComplete={nextStepHandler}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className="py-4">
            <p
              className="text-justify levias-kardust-light"
              style={{ fontSize: "18px" }}
            >
              {strings.formatString(
                enterVerificationCodePage.Enter_the_verification_code_sent_to_your_registered_email_address
              )}
            </p>
          </div>
          <div className="py-4">
            <p
              className="text-center levias-kardust-light fs-16"
              style={{ fontSize: "14px" }}
            >
              {strings.formatString(
                enterVerificationCodePage.Have_not_received_any_verification_email
              )}
            </p>
            <div className="text-center">
              <span
                className="levias-kardust-light fs-18 levias-green"
                style={{ cursor: "pointer" }}
                onClick={() => setShowImageCapModal(true)}
              >
                {strings.formatString(enterVerificationCodePage.Resend_code)}
              </span>
            </div>
          </div>
        </div>

        {/*<div className="text-center py-2 d-flex w-100 justify-content-evenly">
          {emailCodeValid === true ? (
            <button
              onClick={nextStepHandler}
              className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
            >
              NEXT
            </button>
          ) : (
            <button
              disabled
              className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
            >
              NEXT
            </button>
          )}
          </div>*/}
      </div>
    </>
  );
};

export const SetTwoFactorForm = ({
  setIdmsRegistrationStep,
  setPhoneValid,
  textChangeHandler,
  smsVerificationCode,
  phoneNumber,
  nextStepHandler,
  phoneValid,
  confirmPhoneNumber,
  onShowModalHandler,
}) => {
  const language = useSelector((state) => state.language.language);
  const twoFactorVerificationPage = translations.twoFactorVerificationPage;
  const buttonlist = translations.buttonlist;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div>
          <h3 className="auth-form-label">
            {strings.formatString(
              twoFactorVerificationPage.two_factor_authentication
            )}
          </h3>
          <div className="py-3 min-height-40">
            <div className="py-3">
              <PhoneInput
                inputClass="auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  twoFactorVerificationPage.new_mobile_number
                )}
                value={phoneNumber}
                onChange={(e) => {
                  textChangeHandler("phone", e);
                }}
              />
            </div>
            <div className="py-3">
              <PhoneInput
                inputClass="auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  twoFactorVerificationPage.confirm_mobile_number
                )}
                value={confirmPhoneNumber}
                onChange={(e) => {
                  textChangeHandler("confirm-phone", e);
                }}
              />
            </div>
            <div className="py-3">
              <p className="text-center kardust-light-font">
                {strings.formatString(
                  twoFactorVerificationPage.Register_your_phone_number_for_two_factor_authentication
                )}
              </p>
            </div>
          </div>

          <div className="text-center py-2 d-flex w-100 justify-content-evenly">
            <button
              style={{ opacity: 0.5 }}
              className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
              onClick={onShowModalHandler}
            >
              cancel
            </button>
            {phoneValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn btn-custom-active my-3 py-1 col-5"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-custom-success my-3 py-1 col-5"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeviasSetTwoFactorForm = ({
  setIdmsRegistrationStep,
  setPhoneValid,
  textChangeHandler,
  smsVerificationCode,
  phoneNumber,
  nextStepHandler,
  phoneValid,
  confirmPhoneNumber,
  onShowModalHandler,
  leviasLayout,
  loading,
}) => {
  useEffect(() => {
    leviasLayout();
  }, []);

  const isJP = translations._language === "jp";
  const twoFactorVerificationPage = translations.twoFactorVerificationPage;
  const buttonlist = translations.buttonlist;
  return (
    <div className="w-100 auth-form-container">
      <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
        {strings.formatString(
          twoFactorVerificationPage.two_factor_authentication
        )}
      </h3>
      {loading === true ? (
        <LeviasOnLoading />
      ) : (
        <>
          <div className="py-5 min-height-40">
            <div className="py-4">
              <PhoneInput
                inputClass="levias-auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  twoFactorVerificationPage.new_mobile_number
                )}
                value={phoneNumber}
                onChange={(e) => {
                  textChangeHandler("phone", e);
                }}
              />
            </div>
            <div className="py-4">
              <PhoneInput
                inputClass="levias-auth-form-input-absolute p-2 ps-3 w-100"
                placeholder={strings.formatString(
                  twoFactorVerificationPage.confirm_mobile_number
                )}
                value={confirmPhoneNumber}
                onChange={(e) => {
                  textChangeHandler("confirm-phone", e);
                }}
              />
            </div>
            <div className="py-4">
              <p className="text-justify levias-kardust-light">
                {strings.formatString(
                  twoFactorVerificationPage.Register_your_phone_number_for_two_factor_authentication
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            {phoneValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {strings.formatString(buttonlist.leviasNextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {strings.formatString(buttonlist.leviasNextBtn)}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const TwoFactorVerifyForm = ({
  setIdmsRegistrationStep,
  textChangeHandler,
  phoneCodeValid,
  smsVerificationCode,
  nextStepHandler,
  onShowModalHandler,
}) => {
  const [showImageCapModal, setShowImageCapModal] = useState();
  const language = useSelector((state) => state.language.language);
  const mobileVerificationCodePage = translations.mobileVerificationCodePage;
  const buttonlist = translations.buttonlist;
  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };
  const pinRef = useRef();
  return (
    <>
      {
        <BottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          phoneVerify
          label={strings.formatString(
            mobileVerificationCodePage.Have_not_received_any_verification_SMS
          )}
        />
      }
      <div className="w-100 auth-form-container">
        <div style={{ padding: "25px 0" }}>
          <div>
            <h3 className="auth-form-label">
              {strings.formatString(
                mobileVerificationCodePage.verification_code
              )}
            </h3>

            <div className="py-3 min-height-40">
              <div className="py-3 text-center">
                <PinInput
                  focus
                  length={6}
                  initialValue={smsVerificationCode}
                  secret
                  onChange={(e) => {
                    textChangeHandler("verify-phone-code", e);
                  }}
                  type="password"
                  inputMode="numeric"
                  style={{ padding: "10px" }}
                  inputStyle={{
                    borderColor: "#FFF",
                    margin: "0 5px",
                    color: "#FFF",
                    width: "33px",
                    borderRadius: "10px",
                  }}
                  inputFocusStyle={{ borderColor: "#FFF" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "18px" }}
                >
                  {strings.formatString(
                    mobileVerificationCodePage.Enter_the_verification_code_sent_to_your_registered_mobile_number
                  )}
                </p>
              </div>
              <div className="py-2">
                <p
                  className="text-center kardust-light-font"
                  style={{ fontSize: "14px" }}
                >
                  {strings.formatString(
                    mobileVerificationCodePage.Have_not_received_any_verification_SMS
                  )}
                </p>
                <div className="text-center">
                  <span
                    className="kardust-light-font anikana-label"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowImageCapModal(true)}
                  >
                    {strings.formatString(
                      mobileVerificationCodePage.Resend_code
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="text-center py-2 d-flex w-100 justify-content-evenly">
              <button
                style={{ opacity: 0.5 }}
                className="btn btn-custom-cancel col-5 my-3 mx-0 py-1"
                onClick={onShowModalHandler}
              >
                <span className="sign-in-label aniBtn">cancel</span>
              </button>
              {phoneCodeValid === true ? (
                <button
                  onClick={nextStepHandler}
                  className="btn btn-custom-active my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              ) : (
                <button
                  disabled
                  className="btn btn-custom-success my-3 py-1 col-5"
                >
                  {strings.formatString(buttonlist.nextBtn)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const LeviasTwoFactorVerifyForm = ({
  setIdmsRegistrationStep,
  setTransactionId,
  textChangeHandler,
  phoneCodeValid,
  smsVerificationCode,
  nextStepHandler,
  onShowModalHandler,
  leviasLayout,
  email,
  phoneNumber,
  transactionId,
  tempToken,
}) => {
  useEffect(() => {
    leviasLayout();
  }, []);

  const isJP = translations._language === "jp";
  const [showImageCapModal, setShowImageCapModal] = useState();
  const mobileVerificationCodePage = translations.mobileVerificationCodePage;
  const params = {
    transactionId,
    phoneNumber,
    tempToken,
  };
  const onCloseImageCapModal = () => {
    setShowImageCapModal(false);
  };

  return (
    <>
      {
        <LeviasBottomModal
          setIdmsRegistrationStep={setIdmsRegistrationStep}
          setTransactionId={setTransactionId}
          onCloseHandler={onCloseImageCapModal}
          onShow={showImageCapModal}
          phoneVerify
          label={strings.formatString(
            mobileVerificationCodePage.Have_not_received_any_verification_SMS
          )}
          params={params}
          isRegister
        />
      }
      <div className="w-100 auth-form-container">
        <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
          {strings.formatString(mobileVerificationCodePage.verification_code)}
        </h3>

        <div className="py-4 min-height-40">
          <div className="py-4 text-center">
            <PinInput
              focus={true}
              length={6}
              initialValue={smsVerificationCode}
              secret={true}
              onChange={(e) => {
                textChangeHandler("verify-phone-code", e);
              }}
              type="password"
              inputMode="numeric"
              style={{ padding: "10px" }}
              inputStyle={{
                borderColor: "#FFF",
                margin: "0 5px",
                color: "#FFF",
                width: "33px",
                borderRadius: "10px",
              }}
              inputFocusStyle={{ borderColor: "#FFF" }}
              onComplete={nextStepHandler}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className="py-4">
            <p
              className="text-justify levias-kardust-light"
              style={{ fontSize: "18px" }}
            >
              {strings.formatString(
                mobileVerificationCodePage.Enter_the_verification_code_sent_to_your_registered_mobile_number
              )}
            </p>
          </div>
          <div className="py-4">
            <p
              className="text-center levias-kardust-light fs-16"
              style={{ fontSize: "14px" }}
            >
              {strings.formatString(
                mobileVerificationCodePage.Have_not_received_any_verification_SMS
              )}
            </p>
            <div className="text-center">
              <span
                className="levias-kardust-light fs-18 levias-green"
                style={{ cursor: "pointer" }}
                onClick={() => setShowImageCapModal(true)}
              >
                {strings.formatString(mobileVerificationCodePage.Resend_code)}
              </span>
            </div>
          </div>
        </div>

        {/*<div className="text-center py-2 d-flex w-100 justify-content-evenly">
          {phoneCodeValid === true ? (
            <button
              onClick={nextStepHandler}
              className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
            >
              NEXT
            </button>
          ) : (
            <button
              disabled
              className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
            >
              NEXT
            </button>
          )}
          </div>*/}
      </div>
    </>
  );
};

export const SetPasswordForm = ({
  emailValidated,
  setIdmsRegistrationStep,
  showPassword,
  showConfirmPassword,
  onShowHandler,
  passwordValid,
  nextStepHandler,
  confirmPassword,
  password,
  textChangeHandler,
}) => {
  const language = useSelector((state) => state.language.language);
  const passwordSetPage = translations.passwordSetPage;
  const buttonlist = translations.buttonlist;
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div>
          <h3 className="auth-form-label">
            {strings.formatString(passwordSetPage.set_password)}
          </h3>
          <div className="py-3 min-height-40">
            <div className="py-2">
              <div className="password-box">
                <input
                  className="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("password", e.target.value)
                  }
                  value={password}
                  placeholder={strings.formatString(
                    passwordSetPage.new_password
                  )}
                  // onBlur={emailValidator}
                  type={showPassword === true ? "text" : "password"}
                />
                {showPassword === true ? (
                  <HidePasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <ShowPasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
            </div>
            <div className="py-4 pt-3">
              <div className="password-box">
                <input
                  className="auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("confirm-password", e.target.value)
                  }
                  value={confirmPassword}
                  placeholder={strings.formatString(
                    passwordSetPage.confirm_password
                  )}
                  // onBlur={emailValidator}
                  type={showConfirmPassword === true ? "text" : "password"}
                />
                {showConfirmPassword === true ? (
                  <HidePasswordIcon
                    type="confirm-password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <ShowPasswordIcon
                    type="confirm-password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
            </div>

            <div className="">
              <p className="text-justify kardust-light-font">
                {strings.formatString(
                  passwordSetPage.Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters_at_least_one_digit_and_one_special_character
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            {/* <button
              onClick={() => setIdmsRegistrationStep("connectWallet")}
              className="auth-form-button my-3 w-100 py-1 mx-auto"
            >
              <span className="auth-form-button-label aniBtn">
                Set password
              </span>
              
            </button> */}
            {passwordValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn btn-custom-active my-2 w-100 py-1 mx-auto"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            ) : (
              <button
                disabled
                className="btn btn-custom-success my-2 w-100 py-1 mx-auto"
              >
                {strings.formatString(buttonlist.nextBtn)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LeviasSetPasswordForm = ({
  emailValidated,
  setIdmsRegistrationStep,
  showPassword,
  showConfirmPassword,
  onShowHandler,
  passwordValid,
  nextStepHandler,
  confirmPassword,
  password,
  textChangeHandler,
  leviasLayout,
  loading,
}) => {
  useEffect(() => {
    leviasLayout();
  }, []);

  const isJP = translations._language === "jp";
  const passwordSetPage = translations.passwordSetPage;
  return (
    <div className="w-100 auth-form-container">
      <h3 className={`levias-auth-form-label ${isJP ? "fs-30" : ""}`}>
        {strings.formatString(passwordSetPage.set_password)}
      </h3>
      {loading === true ? (
        <LeviasOnLoading />
      ) : (
        <>
          <div className="py-5 min-height-40">
            <div className="py-4">
              <div className="password-box">
                <input
                  className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("password", e.target.value)
                  }
                  value={password}
                  placeholder={strings.formatString(
                    passwordSetPage.new_password
                  )}
                  type={showPassword === true ? "text" : "password"}
                />
                {showPassword === true ? (
                  <LeviasHidePasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <LeviasShowPasswordIcon
                    type="password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
            </div>
            <div className="py-4 pt-3">
              <div className="password-box">
                <input
                  className="levias-auth-form-input-absolute p-2 ps-3 w-100"
                  onChange={(e) =>
                    textChangeHandler("confirm-password", e.target.value)
                  }
                  value={confirmPassword}
                  placeholder={strings.formatString(
                    passwordSetPage.confirm_password
                  )}
                  type={showConfirmPassword === true ? "text" : "password"}
                />
                {showConfirmPassword === true ? (
                  <LeviasHidePasswordIcon
                    type="confirm-password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon"
                  />
                ) : (
                  <LeviasShowPasswordIcon
                    type="confirm-password"
                    onShowHandler={onShowHandler}
                    className="show-hide-password-icon show-password-icon"
                  />
                )}
              </div>
            </div>

            <div className="">
              <p className="text-justify levias-kardust-light">
                {strings.formatString(
                  passwordSetPage.Your_password_must_be_at_least_8_characters_long_including_upper_and_lower_case_letters_at_least_one_digit_and_one_special_character
                )}
              </p>
            </div>
          </div>

          <div className="text-center">
            {passwordValid === true ? (
              <button
                onClick={nextStepHandler}
                className="btn levias-btn levias-btn-custom-active my-3 w-100 py-1 col-5"
              >
                {strings.formatString(passwordSetPage.CONFIRM)}
              </button>
            ) : (
              <button
                disabled
                className="btn levias-btn levias-btn-custom-success my-3 w-100 py-1 col-5"
              >
                {strings.formatString(passwordSetPage.CONFIRM)}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const ConnectWallet = ({ setIdmsRegistrationStep }) => {
  return (
    <div className="w-100 auth-form-container">
      <div style={{ padding: "25px 0" }}>
        <div className="py-5">
          <h3 className="auth-form-label">connect wallet</h3>
          <div className="py-3 min-height-40">
            <p className="text-justify kardust-light-font">
              For using and get access to various services of this portal you
              have to connect your ANICANA Wallet.
            </p>
            <div className="py-2">
              <p
                className="text-center kardust-light-font"
                style={{ fontSize: "14px" }}
              >
                Do not have ANICANA Wallet yet?
              </p>
              <div className="text-center">
                <span
                  className="kardust-light-font anikana-label"
                  style={{ cursor: "pointer" }}
                >
                  Get one!
                </span>
              </div>
            </div>
          </div>

          <div className="text-center py-4">
            <button
              style={{ opacity: 0.5 }}
              onClick={() => setIdmsRegistrationStep("connect-wallet")}
              className="create-account-button my-3 w-50 py-1 mx-auto"
            >
              <span className="sign-in-label aniBtn">Connect</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
