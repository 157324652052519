import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { appContext } from "../../context/appContext";

const ScrollToTop = (props) => {
  const location = useLocation();
  const { setHasInput } = useContext(appContext);

  const wrapper = document.getElementById('wrapper');
  const mainbody = document.getElementById('mainbody');

  useEffect(() => {
    if (location && (wrapper || mainbody)) {
      if (wrapper) {
        wrapper.scrollTo(-1, 0);
        setHasInput(wrapper.getElementsByTagName('input').length > 0);
      } else if (mainbody) {
        mainbody.scrollTo(-1, 0);
        setHasInput(mainbody.getElementsByTagName('input').length > 0);
      }
    }
  }, [location, wrapper, mainbody])

  // useEffect(() => {
  //   if (document.getElementById('wrapper')) {
  //     document.getElementById('wrapper').scrollTo(0, 0);
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;