import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './store/index'
import 'react-phone-number-input/style.css'
import './style/app/react-pdf.scss';
import './style/app/app.scss';
import posthog from 'posthog-js'; // new
import ReactGA from 'react-ga4';
import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from './config';

posthog.init( // new
  'phc_oPag6SvIe0aubz6StCkpeMau7AAoU1M4gGCeHb9tsYu', { api_host: 'https://app.posthog.com' }
)
// Initialize Google Analytics 4 with your Measurement ID
ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);
