import { ADAPTER_EVENTS } from "@web3auth/base";
import React, { createContext, useEffect, useMemo, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SwalCorrect } from "../components/common/Swal";
import RPC, { initWeb3, openloginAdapter, web3authModalParams, getAccounts } from "../components/common/web3RPC";
import { getWalletAddress, setWalletAddress } from "../services/storage";
import { TokenType } from '../components/common/enum';
import { strings, translations } from "../services/localization";
import { fetchData, getData, postData } from "../services/fetch";

export const Web3authContext = createContext(); // could have a default value

export const Web3authProvider = ({ children }) => {
    const navigate = useNavigate();
    const [wallet_address, setWallet_address] = useState('');
    const [web3auth, setWeb3auth] = useState(null);
    const [provider, setProvider] = useState(null);
    const sendAnimaConfirmPage = translations.sendAnimaConfirmPage;

    const [callbackUrl, setCallbackUrl] = useState(null);
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    useEffect(() => {
        if (provider && callbackUrl) {
            window.location.href = callbackUrl;
            setCallbackUrl(null);
        }
    }, [provider, callbackUrl])

    useEffect(() => {
        if (provider) {
            const rpc = new RPC(provider);
            rpc.getAccounts().then(address => {
                console.log(address)
                setWalletAddress(address)
                setWallet_address(address)
            });
        } else {
            if (getWalletAddress()) {
                setWallet_address(getWalletAddress())
            }
        }
    }, [provider]);

    const checkWalletAddress = (wallet_address) => {
        return (wallet_address && wallet_address != null && wallet_address != undefined && wallet_address != "null" && wallet_address != "undefined") ? wallet_address : '';
    }

    const setWalletProvider = useCallback(
        (web3authProvider) => {
            if (web3authProvider) {
                setProvider(web3authProvider);
            };
        }, []
    );

    const loginIfNotLogin = async (id_token) => {
        if (provider) {
            const address = await getAccounts()
            setWalletAddress(address)
        } else {
            loginWeb3(id_token);
        }
    }

    const loginWeb3 = async (id_token, skipWeb3Login = false) => {
        console.log('loginWeb3');
        const web3authInstance = initWeb3();
        if (web3authInstance.connected) {
            setWalletProvider(web3authInstance.provider)
            return;
        }
        if (!id_token) {
            return false;
        }
        setWeb3auth(web3authInstance);

        const adapter = openloginAdapter();
        await web3authInstance.configureAdapter(adapter);

        var res = await web3authInstance.init();
        console.log('w3error', res);

        if (web3authInstance.connected) {
            setWalletProvider(web3authInstance.provider);
            return;
        };

        if (!web3authInstance) {
            console.log("web3auth not initialized yet");
            return;
        }

        if (skipWeb3Login) {
            return;
        }

        const loginParams = {
            loginProvider: "jwt",
            mfaLevel: 'none',
            extraLoginOptions: {
                //verifierIdField: "sub",
                id_token: id_token,
                domain: window.location.protocol + "//" + window.location.host,
                verifierIdField: "sub",
                response_type: "token",
                scope: "email profile openid",
            }
        }
        console.log(loginParams)
        console.log(adapter.name)


        console.log("CONNECT TO")
        const web3authProvider = await web3authInstance.connectTo(adapter.name, loginParams);
        console.log("CONNECT TO END", web3authProvider)

        setWalletProvider(web3authProvider);

    };


    const init = async (id_token = null) => {
        try {
            const web3auth = initWeb3();
            setWeb3auth(web3auth);

            const adapter = openloginAdapter();
            await web3auth.configureAdapter(adapter);

            await web3auth.init();

            if (web3auth.connected) {
                setProvider(web3auth.provider);
                return;
            };

            if (!web3auth) {
                console.log("web3auth not initialized yet");
                return;
            }


            let web3authProvider = web3auth.connected
            if (!web3auth.connected) {
                //const id_token = await getWeb3authToken()
                if (!id_token) {
                    const response = await postData(`user/get-web3auth-token`);
                    id_token = response.data.web3auth_token
                }
                console.log(adapter.name)
                const loginParams = {
                    loginProvider: "jwt",
                    mfaLevel: 'none',
                    extraLoginOptions: {
                        //verifierIdField: "sub",
                        id_token: id_token,
                        domain: window.location.protocol + "//" + window.location.host,
                        verifierIdField: "sub",
                        response_type: "token",
                        scope: "email profile openid",
                    }
                }
                web3authProvider = await web3auth.connectTo(adapter.name, loginParams);
            }

            setProvider(web3authProvider);
            // console.log('prob')
            // console.log(web3authProvider)
            // // const rpc = new RPC(web3authProvider);
            // const address = await rpc.getAccounts();
            // if(address){
            //     console.log(address)
            //     setWallet_address(address)
            //     setWalletAddress(address)
            // }
            return web3authProvider
        } catch (e) {
            console.error(e)
        }
        return null
    };

    const approveContract = async (token_type_id, token_id, isCancelled = false) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        var hash = await rpc.approveContract(token_type_id, token_id, isCancelled);
        return hash;
    };

    const absorbContract = async (predetor, prey) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        return await rpc.absorbContract(predetor, prey);
    };

    const drawContract = async (drawChainId, personaId) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        return await rpc.drawContract(drawChainId, personaId);
    };

    const squareContract = async (drawChainId, personaId, address = null) => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        return await rpc.squareContract(drawChainId, personaId, address);
    };

    const transferContract = async (token_type_id, to, token_id, contract_address = null, showError = false, fnc = undefined, isAnimaCharge = false) => {
        console.log('transferContract', { token_type_id, to, token_id, contract_address, showError, fnc, isAnimaCharge })
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        var hash = await rpc.transferContract(token_type_id, to, token_id, contract_address, showError);
        if (hash) {
            if (fnc) {
                fnc(hash);
            } else if (Number(token_type_id) === Number(TokenType.Anima)) {
                SwalCorrect.fire({
                    title: strings.formatString(sendAnimaConfirmPage.complete_Modal_Text),
                    showCancelButton: false,
                    customClass: {
                        cancelButton: '',
                    }
                }).then(() => {
                    if (isAnimaCharge) {
                        navigate('/wallet/charge/success');
                    } else {
                        navigate('/wallet');
                    }
                });
            } else if (Number(token_type_id) === Number(TokenType.Token)) {
                SwalCorrect.fire({
                    title: strings.formatString(sendAnimaConfirmPage.complete_Modal_Text),
                    showCancelButton: false,
                    customClass: {
                        cancelButton: '',
                    }
                }).then(() => {
                    navigate('/wallet/token');
                });
            } else {
                SwalCorrect.fire({
                    title: strings.formatString(sendAnimaConfirmPage.nft_successful_sent),
                    showCancelButton: false,
                    customClass: {
                        cancelButton: '',
                    }
                }).then(() => {
                    navigate(`/wallet/${TokenType.getRouteName(token_type_id)}`);
                });
            }
        }
    };

    const getAccounts = async () => {
        if (!provider) {
            console.log("provider not initialized yet");
            return;
        }
        const rpc = new RPC(provider);
        const address = await rpc.getAccounts();
        return address;
    };

    const isValidWalletAddress = async () => {
        const address = await getAccounts();
        return String(address) === String(getWalletAddress());
    }

    const logout = async () => {
        if (!web3auth) {
            console.log("web3auth not initialized yet");
            return;
        }
        if (provider) {
            await web3auth.logout();
        }
        setProvider(null);
        setWallet_address('');
    };

    const value = useMemo(() => ({
        wallet_address, setWallet_address, web3auth, setWeb3auth, provider, setProvider, callbackUrl, setCallbackUrl,
        init, getAccounts, approveContract, transferContract, absorbContract, drawContract, squareContract, isValidWalletAddress, checkWalletAddress, logout, loginWeb3, loginIfNotLogin
    }), [wallet_address, web3auth, provider, callbackUrl]);

    return (
        <Web3authContext.Provider value={value}>
            {children}
        </Web3authContext.Provider>
    )
}
